export interface BasicLearningObjective {
    active: boolean;
    id: number;
    title: string;
    description: string;
    position: number | null;
    year: Year;
    year_id: number;
    category: Category;
    topic: Topic | null;
    slides_counter: number;
    ppt_processing: boolean;
    created_at: string;
    updated_at: string;
    deleted: boolean;
    type: LearningObjectiveType;
    strand: Strand;
}

export interface StandardLearningObjective extends BasicLearningObjective {
    sub_strand: SubStrand;
    content_domain?: ContentDomain;
    concept: string;
    practice: string;
    application: string;
    type: LearningObjectiveType.STANDARD;
}

export interface SATsLearningObjective extends BasicLearningObjective {
    position: number;
    content_domains: ContentDomain[];
    revision_order: number;
    impact: ImpactType;
    type: LearningObjectiveType.SATS;
    learning_contents: LearningContents[];
}

export interface GCSELearningObjective extends BasicLearningObjective {
    position: number;
    revision_order: number;
    impact: ImpactType;
    type: LearningObjectiveType.GCSE;
    learning_contents: LearningContents[];
}

export type LearningObjective =
    | StandardLearningObjective
    | SATsLearningObjective
    | GCSELearningObjective;

export enum LearningObjectiveType {
    STANDARD = 'standard',
    SATS = 'sats',
    GCSE = 'gcse',
}

export enum ImpactType {
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low',
}

export enum Phase {
    SECONDARY = 'secondary',
    PRIMARY = 'primary',
}

export interface LearningContents {
    title: string;
    id: number;
    active: boolean;
}

export interface Strand {
    id: number;
    title: string;
    position: number;
    sub_strands_counter?: number;
    phase: Phase;
}

export interface SubStrand {
    id: number;
    title: string;
    strand: Strand;
    position: number;
}

export interface ContentDomain {
    id: number;
    statement: string;
    year: Year;
    strand: Strand;
    reference: string;
    sub_strand: SubStrand;
    position: number;
}

export interface Year {
    id: number;
    name: string;
    basic_year: boolean;
}

export interface Category {
    id: number;
    name: string;
    type: string; //TODO add actual list
    proportion: string; // TODO add actual list
    topics_counter: number;
}

export interface Topic {
    id: number;
    name: string;
}

export interface Slide {
    id: number;
    image_path: string;
    tutor_notes: string;
    position: number;
    slide_type?: SlideType | null;
    active: boolean;
    learning_content?: LearningContents;
}

export enum SlideType {
    CONCEPT = 'concept',
    PRACTICE = 'practice',
    APPLICATION = 'application',
}

export interface ContentMap {
    id: number;
    year: Year;
    strand: Strand;
    key_learning_objective: LearningObjective;
    nodes: ContentMapNode[];
}

export interface ContentMapNode {
    id: number;
    learning_objective: LearningObjective;
    position: number;
    supports?: Array<ContentMapNode>;
}

export type FilterOption = {
    filterId: string;
    multiple?: boolean;
};
