const basicNoteTemplate = `
    <p>
        <strong style="color: rgb(255, 153, 0)">If stuck</strong>
    </p>
    <ul><li><strong>Bold</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong style="color: rgb(0, 102, 204);">Questions</strong>
    </p>
    <ul><li><strong>Bold</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong style="color: rgb(230, 0, 0);">Watch out for</strong>
        <br />
    </p>
    <ul><li><strong>Bold</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong style="color: rgb(102, 185, 102)">Answers</strong>
        <br />
    </p>
    <p>
        <br />
        <br />
    </p>
`;

export interface Template {
    name: string;
    content: string;
}

export const templates: Array<Template> = [
    { name: 'None', content: '' },
    {
        name: 'Cover Slide',
        content: `
            <p>
                <strong style="color: rgb(255, 153, 0)">If stuck</strong>
            </p>
            <ul><li><strong>Bold</strong></li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(102, 185, 102)">Answers</strong>
                <br />
                <br />
            </p>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(0, 0, 0);">TUTOR REFERENCE ONLY</strong>
                <br />
            </p>
            <p>
                <br />
                <strong style="color: rgb(0, 0, 0);">Key mathematical ideas:</strong>
            </p>
            <ul><li>Point</li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(0, 0, 0);">Terminology:</strong>
            </p>
            <ul><li>Point</li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(0, 0, 0);">Common misconceptions:</strong>
            </p>
            <ul><li>Point</li></ul>
        `,
    },
    {
        name: 'Pre-Assess',
        content: `
    <p>
        <strong style="color: rgb(102, 185, 102)">Answers</strong>
        <br />
        <br />
    </p>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong>TUTOR REFERENCE ONLY</strong>
        <br />
        <br />
    </p>

    <p>
        <strong>Key mathematical ideas:</strong>
    </p>
    <ul><li><strong>Point</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong>Classroom Tools:</strong>
        <br />
    </p>
    <ul><li><strong>Point</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
    <strong>Terminology:</strong>
    <br />
    </p>
    <ul><li><strong>Point</strong></li></ul>
    <p>
    <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
    <strong>Common misconceptions:</strong>
    <br />
    </p>
    <ul><li><strong>Point</strong></li></ul>
    <p>
        <br />
        <br />
    </p>
`,
    },
    {
        name: 'Prior Learning',
        content: `

    <p>
        <strong style="color: rgb(0, 102, 204);">Questions</strong>
    </p>
    <ul><li><strong>Bold</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong style="color: rgb(230, 0, 0);">Watch out for</strong>
        <br />
    </p>
    <ul><li><strong>Bold</strong></li></ul>
    <p>
        <span style="color: rgb(0, 0, 0);">_____________</span>
    </p>
    <p>
        <strong style="color: rgb(102, 185, 102)">Answers</strong>
        <br />
    </p>
    <p>
        <br />
        <br />
    </p>
`,
    },
    {
        name: `Let's Learn`,
        content: basicNoteTemplate,
    },
    {
        name: 'Follow Me / Your Turn',
        content:
            `
            <p>
                <strong style="color: rgb(0, 0, 0);">Follow me</strong>
                <br />
            </p>
            <p>
                <br />
                <strong style="color: rgb(153, 51, 255);">Modelling prompts</strong>
            </p>
            <ul><li><strong>Bold</strong></li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(102, 185, 102)">Answers</strong>
                <br />
                <br />
            </p>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(0, 0, 0);">Your turn</strong>
                <br />
                <br />
            </p>
        ` + basicNoteTemplate,
    },
    { name: 'You Do', content: basicNoteTemplate },
    { name: 'Go Further', content: basicNoteTemplate },
    {
        name: 'Support',
        content: `
            <p>
                <strong style="color: rgb(0, 0, 0);">Support for</strong>
                <br />
            </p>
            <p>
                <br />
                <strong style="color: rgb(255, 153, 0)">If stuck</strong>
            </p>
            <ul><li><strong>Bold</strong></li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <br />
                <strong style="color: rgb(0, 102, 204);">Questions</strong>
            </p>
            <ul><li><strong>Bold</strong></li></ul>
            <p>
                <span style="color: rgb(0, 0, 0);">_____________</span>
            </p>
            <p>
                <strong style="color: rgb(102, 185, 102)">Answers</strong>
                <br />
            </p>
            <p>
                <br />
                <br />
            </p>
        `,
    },
    { name: 'Classroom Tools', content: '' },
];
