/* eslint-disable no-case-declarations */
import { createSelector } from 'reselect';
import {
    GET_YEARS,
    GET_YEARS_SUCCESS,
    GET_YEARS_FAILURE,
    ADD_YEAR_SUCCESS,
    EDIT_YEAR_SUCCESS,
    YearsState,
    YearsActionTypes,
} from './types';
import { RootState } from '../types';

export const yearsInitialState: YearsState = {
    years: [],
    fetching: false,
    error: null,
};

const years = (
    state = yearsInitialState,
    action: YearsActionTypes
): YearsState => {
    switch (action.type) {
        case GET_YEARS:
            return {
                ...state,
                fetching: true,
            };
        case GET_YEARS_SUCCESS:
            return {
                ...state,
                years: action.payload,
                fetching: false,
            };
        case GET_YEARS_FAILURE:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                years: [],
            };
        case ADD_YEAR_SUCCESS:
            const currentYears = state.years || [];
            return {
                ...state,
                years: [...currentYears, action.payload],
            };
        case EDIT_YEAR_SUCCESS:
            const removedEditYear =
                state.years?.filter((year) => year.id !== action.payload.id) ||
                [];
            return {
                ...state,
                years: [...removedEditYear, action.payload],
            };
        default:
            return state;
    }
};

const yearsSelector = (state: RootState) => state.years;

export const selectYears = createSelector(
    yearsSelector,
    (years: YearsState) => years.years
);

export const selectYearsAsOptions = createSelector(
    yearsSelector,
    (years: YearsState) =>
        years.years.map((year) => ({
            value: year.id,
            label: year.name,
        }))
);

export const selectYearsAsFilters = createSelector(
    yearsSelector,
    (years: YearsState) =>
        years.years.map((year) => ({
            id: year.id,
            name: year.name,
        }))
);

export const selectYearsFetching = createSelector(
    yearsSelector,
    (years: YearsState) => years.fetching
);

export const selectYearsError = createSelector(
    yearsSelector,
    (years: YearsState) => years.error
);

export default years;
