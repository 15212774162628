import { createSelector } from 'reselect';

import { Slide, LearningObjective } from '../../../types';
import { RootState } from '../../types';
import { selectAllLearningObjectives } from '../reducer';

import {
    LearningContentState,
    LearningContentActionTypes,
    EDIT_LO_CONTENT,
    GET_SLIDES_SUCCESS,
    ADD_SLIDE_SUCCESS,
    EDIT_SLIDE_SUCCESS,
    ADD_SLIDE_FAILURE,
    EDIT_SLIDE_FAILURE,
    ADD_PPTX_FAILURE,
    PPTX_PROCESSED,
    ADD_PPTX,
    ADD_PPTX_SUCCESS,
    DELETE_SLIDE_SUCCESS,
    DELETE_SLIDE_FAILURE,
} from './types';

const positionSort = (a: Slide, b: Slide) => a.position - b.position;

export const learningContentInitialState: LearningContentState = {
    learningObjectiveId: null,
    slides: {},
    createEditSlideError: null,
    pptxError: null,
    lOProcessingPptxId: null,
    uploadingPptx: false,
};

const addSlide = (
    currentSides: {
        [learningObjectiveId: number]: Slide[];
    },
    newSlide: {
        learningObjectiveId: number;
        slide: Slide;
    }
): Slide[] => {
    const currentSlides = currentSides[newSlide.learningObjectiveId] || [];
    const slides = [...currentSlides, newSlide.slide].sort(positionSort);

    return slides;
};

const editSlide = (
    currentSides: {
        [learningObjectiveId: number]: Slide[];
    },
    newSlide: {
        learningObjectiveId: number;
        slide: Slide;
    }
): Slide[] => {
    const otherSlides = currentSides[newSlide.learningObjectiveId].filter(
        (slide) => slide.id !== newSlide.slide.id
    );
    const newSlides = [...otherSlides, newSlide.slide].sort(positionSort);

    return newSlides;
};

const deleteSlide = (
    currentSides: {
        [learningObjectiveId: number]: Slide[];
    },
    payload: {
        learningObjectiveId: number;
        slideId: number;
    }
): Slide[] => {
    const allSlides = currentSides[payload.learningObjectiveId] || [];
    const newSlides = allSlides.filter((slide) => slide.id !== payload.slideId);

    return newSlides;
};

const learningContent = (
    state = learningContentInitialState,
    action: LearningContentActionTypes
): LearningContentState => {
    switch (action.type) {
        case EDIT_LO_CONTENT:
            return {
                ...state,
                learningObjectiveId: action.payload,
            };
        case GET_SLIDES_SUCCESS:
            return {
                ...state,
                slides: {
                    ...state.slides,
                    [action.payload.learningObjectiveId]: action.payload.slides,
                },
            };
        case ADD_SLIDE_SUCCESS:
            return {
                ...state,
                slides: {
                    ...state.slides,
                    [action.payload.learningObjectiveId]: addSlide(
                        state.slides,
                        action.payload
                    ),
                },
            };
        case EDIT_SLIDE_SUCCESS:
            return {
                ...state,
                slides: {
                    ...state.slides,
                    [action.payload.learningObjectiveId]: editSlide(
                        state.slides,
                        action.payload
                    ),
                },
            };
        case DELETE_SLIDE_SUCCESS:
            return {
                ...state,
                slides: {
                    ...state.slides,
                    [action.payload.learningObjectiveId]: deleteSlide(
                        state.slides,
                        action.payload
                    ),
                },
            };
        case ADD_SLIDE_FAILURE:
        case EDIT_SLIDE_FAILURE:
        case DELETE_SLIDE_FAILURE:
            return {
                ...state,
                createEditSlideError: action.payload,
            };
        case ADD_PPTX:
            return {
                ...state,
                uploadingPptx: true,
            };
        case ADD_PPTX_SUCCESS:
            return {
                ...state,
                lOProcessingPptxId: action.payload,
                uploadingPptx: false,
            };
        case ADD_PPTX_FAILURE:
            return {
                ...state,
                pptxError: action.payload,
                lOProcessingPptxId: null,
            };
        case PPTX_PROCESSED:
            return {
                ...state,
                lOProcessingPptxId: null,
            };
        default:
            return state;
    }
};

const learningContentSelector = (state: RootState) => state.learningContent;

export const selectLearningObjectiveId = createSelector(
    learningContentSelector,
    (learningContent: LearningContentState) =>
        learningContent.learningObjectiveId
);

export const selectAllSlides = createSelector(
    learningContentSelector,
    (learningContent: LearningContentState) => learningContent.slides
);

export const selectLearningObjectiveById = createSelector(
    learningContentSelector,
    selectAllLearningObjectives,
    (
        learningContent: LearningContentState,
        allLos: { [title: string]: LearningObjective }
    ) =>
        Object.values(allLos).find(
            (lo) => lo.id === learningContent.learningObjectiveId
        )
);

export const selectSlidesBySetLoId = createSelector(
    selectLearningObjectiveId,
    selectAllSlides,
    (loId: number | null, allSlides) => {
        if (!allSlides || !loId) {
            return null;
        }
        return allSlides[loId];
    }
);

export const selectSlidesByLoId = (state: RootState, loId: number) =>
    selectAllSlides(state)[loId];

export const selectCreateEditSlideError = createSelector(
    learningContentSelector,
    (learningContent: LearningContentState) =>
        learningContent.createEditSlideError
);

export const selectPptxError = createSelector(
    learningContentSelector,
    (learningContent: LearningContentState) => learningContent.pptxError
);

export const selectLOProcessingPptxId = createSelector(
    learningContentSelector,
    (learningContent: LearningContentState) =>
        learningContent.lOProcessingPptxId
);

export const selectPptxProcessing = createSelector(
    selectLearningObjectiveId,
    learningContentSelector,
    (loId: number | null, learningContent: LearningContentState) =>
        learningContent.uploadingPptx ||
        learningContent.lOProcessingPptxId === loId
);

export default learningContent;
