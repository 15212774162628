import { Action } from 'redux';
import {
    GET_YEARS,
    GET_YEARS_SUCCESS,
    GET_YEARS_FAILURE,
    YearsState,
    ADD_YEAR,
    ADD_YEAR_SUCCESS,
    ADD_YEAR_FAILURE,
    EDIT_YEAR,
    EDIT_YEAR_SUCCESS,
    EDIT_YEAR_FAILURE,
} from './types';

import { getYears as getYearsAPI, YearsParams } from '../../api/years';
import { Year, AppThunkResult } from '../../types';

export const getYears = (
    params: YearsParams
): AppThunkResult<Promise<void | YearsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(startGetYears());
        return getYearsAPI(params).then((res) => {
            if ('error' in res) {
                dispatch(getYearsFailure(res.error));
            } else {
                dispatch(getYearsSuccess(res as Year[]));
            }
        });
    };
};

export const getYearsSuccess = (Years: Year[]) => ({
    type: GET_YEARS_SUCCESS,
    payload: Years,
});

export const getYearsFailure = (error: string) => ({
    type: GET_YEARS_FAILURE,
    payload: error,
});

export const startGetYears = () => ({
    type: GET_YEARS,
});

export const addYears = () => ({
    type: ADD_YEAR,
});
export const addYearsSuccess = (Years: Year[]) => ({
    type: ADD_YEAR_SUCCESS,
    payload: Years,
});
export const addYearsFailure = (error: string) => ({
    type: ADD_YEAR_FAILURE,
    payload: error,
});
export const editYears = () => ({
    type: EDIT_YEAR,
});
export const editYearsSuccess = (Years: Year[]) => ({
    type: EDIT_YEAR_SUCCESS,
    payload: Years,
});
export const editYearsFailure = (error: string) => ({
    type: EDIT_YEAR_FAILURE,
    payload: error,
});
