export const environment = {
    production: false,
    env: 'development',

    platformCookieName: '_tsl_platform_staging',

    curriculumCmsApiUrl:
        'https://beta-curriculum-cms-api.thirdspacelearning.com/api',
    usersApiUrl: 'https://staging-accounts.thirdspacelearning.com/v1/users',

    mainAppBaseUrl: 'https://beta-app.thirdspacelearning.com',

    launchDarklyKey: '65c3877b9de2620f07deee88',
};
