import { FC } from 'react';

import { Tag } from '@thirdspacelearning/library';

interface ActiveTagProps {
    active: boolean;
    deleted: boolean;
}

const ActiveTag: FC<ActiveTagProps> = ({ active, deleted }) => {
    const type = deleted ? 'deleted' : active ? 'active' : 'draft';
    const label = deleted ? 'Deleted' : active ? 'Active' : 'Draft';
    return <Tag label={label} type={type} />;
};

export default ActiveTag;
