import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Header } from '@thirdspacelearning/library';

import { User } from '../../types';

import { routesInfo } from '../Routes';

import LogoutButton from './LogoutButton';

interface Props {
    user: User | null;
    logout: () => void;
}

const NavigationBar: FC<Props> = ({ user, logout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const routes = Object.keys(routesInfo);

    const determinePageIndex = (path: string) => {
        if (routes.includes(path)) {
            return routes.indexOf(path);
        }

        // Get the first/main route path and check if the path is a subroute
        const mainPath = path.match(/^\/[^/]*/)?.[0] || '';
        if (routes.includes(mainPath)) {
            return routes.indexOf(mainPath);
        }

        let matchedIndex = 0;
        routes.forEach((route, index) => {
            const routeObj = routesInfo[route];
            const subRoutes = routeObj.menuItems?.map(
                (subRoute) => subRoute.url
            );

            if (subRoutes?.includes(path)) {
                matchedIndex = index;
            }

            if (routeObj.altPath && path.includes(routeObj.altPath)) {
                matchedIndex = index;
            }
        });
        return matchedIndex;
    };

    const onRouteChange = (
        _selectedIndex: number,
        target: {
            url: string;
            label: string;
        }
    ) => {
        navigate(target.url);
    };

    return (
        <Header
            pageTitle={'Curriculum'}
            routes={routes}
            routeInfo={routesInfo}
            onChange={onRouteChange}
            selectedIndex={determinePageIndex(location.pathname)}
            actions={<LogoutButton user={user} logout={logout} />}
            responsive
        />
    );
};

export default NavigationBar;
