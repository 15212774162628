import { Strand, SubStrand } from '../../types';

export const GET_STRANDS = 'GET_STRANDS';
export const GET_STRANDS_SUCCESS = 'GET_STRANDS_SUCCESS';
export const GET_STRANDS_FAILURE = 'GET_STRANDS_FAILURE';
export const GET_SUBSTRANDS = 'GET_SUBSTRANDS';
export const GET_SUBSTRANDS_SUCCESS = 'GET_SUBSTRANDS_SUCCESS';
export const GET_SUBSTRANDS_FAILURE = 'GET_SUBSTRANDS_FAILURE';
export const ADD_STRAND = 'ADD_STRAND';
export const ADD_STRAND_SUCCESS = 'ADD_STRAND_SUCCESS';
export const ADD_STRAND_FAILURE = 'ADD_STRAND_FAILURE';
export const EDIT_STRAND = 'EDIT_STRAND';
export const EDIT_STRAND_SUCCESS = 'EDIT_STRAND_SUCCESS';
export const EDIT_STRAND_FAILURE = 'EDIT_STRAND_FAILURE';

export interface GetStrandsAction {
    type: typeof GET_STRANDS;
    payload: undefined;
}

export interface GetStrandsSuccessAction {
    type: typeof GET_STRANDS_SUCCESS;
    payload: Strand[];
}

export interface GetStrandsFailureAction {
    type: typeof GET_STRANDS_FAILURE;
    payload: string;
}

export interface GetSubStrandsAction {
    type: typeof GET_SUBSTRANDS;
    payload: undefined;
}

export interface GetSubStrandsSuccessAction {
    type: typeof GET_SUBSTRANDS_SUCCESS;
    payload: SubStrand[];
}

export interface GetSubStrandsFailureAction {
    type: typeof GET_SUBSTRANDS_FAILURE;
    payload: string;
}

export interface AddStrandAction {
    type: typeof ADD_STRAND;
    payload: undefined;
}

export interface AddStrandSuccessAction {
    type: typeof ADD_STRAND_SUCCESS;
    payload: Strand;
}

export interface AddStrandFailureAction {
    type: typeof ADD_STRAND_FAILURE;
    payload: string;
}

export interface EditStrandAction {
    type: typeof EDIT_STRAND;
    payload: undefined;
}

export interface EditStrandSuccessAction {
    type: typeof EDIT_STRAND_SUCCESS;
    payload: Strand;
}

export interface EditStrandFailureAction {
    type: typeof EDIT_STRAND_FAILURE;
    payload: string;
}

export type StrandsActionTypes =
    | GetStrandsAction
    | GetStrandsSuccessAction
    | GetStrandsFailureAction
    | GetSubStrandsAction
    | GetSubStrandsSuccessAction
    | GetSubStrandsFailureAction
    | AddStrandAction
    | AddStrandSuccessAction
    | AddStrandFailureAction
    | EditStrandAction
    | EditStrandSuccessAction
    | EditStrandFailureAction;

export interface StrandsState {
    strands: Strand[];
    subStrands: SubStrand[];
    error: string | null;
    fetching: boolean;
}
