import i18next from 'i18next';
import { useTranslation as useTranslationI18Next } from 'react-i18next';

/**
 * Use any function returning from this hook to add language translation support
 */
export const useTranslation = () => {
    const { t } = useTranslationI18Next();
    const tPlur = (key: string, count: number = 2) => t(key, { count: count }); // make label pluralized
    const tUpper = (key: string) => i18next.format(t(key), 'uppercase'); // make label uppercase
    const tLower = (key: string) => i18next.format(t(key), 'lowercase'); // make label lowercase
    const tCap = (key: string) => i18next.format(t(key), 'capitalize'); // make label capitalize

    return {
        t,
        tPlur,
        tUpper,
        tLower,
        tCap,
    };
};
