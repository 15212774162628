import { FC, useEffect, useState, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import {
    Theme,
    Box,
    Dialog,
    Typography,
    Button,
} from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../../../state/store';
import {
    createLearningObjective,
    editLearningObjective,
    selectSubmittingInformation,
    resetSubmit,
} from '../../../../state/learning-objectives';
import { LearningObjectiveValues } from '../../../../api/learning-objectives';
import { ContentDomain, LearningObjective } from '../../../../types';

import AddEditFormContent from './AddEditFormContent';

const sx = {
    titleWrapper: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 3),
    }),
    dialogTitleButtons: {
        '> .MuiButtonBase-root': {
            marginLeft: 1,
        },
    },
};

interface Props {
    open: boolean;
    handleClose: (
        event?: unknown,
        reason?: 'backdropClick' | 'escapeKeyDown',
        refetch?: boolean
    ) => void;
    learningObjective: LearningObjective | { type: string };
}

const AddEditForm: FC<Props> = ({ open, handleClose, learningObjective }) => {
    const dispatch = useAppDispatch();
    const { success } = useAppSelector(selectSubmittingInformation);

    const [contentDomains, setContentDomains] = useState<
        null | ContentDomain[]
    >([]);

    const editing = 'id' in learningObjective;

    const onClose = useCallback(
        (
            event?: unknown,
            reason?: 'backdropClick' | 'escapeKeyDown',
            refetch?: boolean
        ) => {
            setContentDomains(null);
            dispatch(resetSubmit());

            handleClose(event, reason, refetch);
        },
        [handleClose, dispatch]
    );

    useEffect(() => {
        if (success) {
            onClose(undefined, undefined, true);
        }
    }, [success, onClose, dispatch]);

    const onSubmit = async (values: LearningObjectiveValues) => {
        if ('id' in learningObjective) {
            return dispatch(
                editLearningObjective(
                    learningObjective.id,
                    values,
                    values.type,
                    contentDomains
                )
            );
        } else {
            return dispatch(
                createLearningObjective(values, values.type, contentDomains)
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="addEditTitle"
            maxWidth="lg"
            fullWidth
        >
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                initialValues={learningObjective}
                render={({
                    handleSubmit,
                    submitting,
                    dirtySinceLastSubmit,
                    form: {
                        mutators: { push },
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={sx.titleWrapper}>
                            <Typography id="addEditTitle" variant="h5">
                                {`${
                                    editing ? 'Edit' : 'Add'
                                } TSL Learning objective`}
                            </Typography>
                            <Box sx={sx.dialogTitleButtons}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={submitting}
                                >
                                    Save and submit
                                </Button>
                            </Box>
                        </Box>

                        <AddEditFormContent
                            push={push}
                            onClose={onClose}
                            learningObjective={learningObjective}
                            dirtySinceLastSubmit={dirtySinceLastSubmit}
                            editing={editing}
                            contentDomains={contentDomains}
                            setContentDomains={setContentDomains}
                        />
                    </form>
                )}
            />
        </Dialog>
    );
};

export default AddEditForm;
