import { Slide } from '../../../types';

export const EDIT_LO_CONTENT = 'EDIT_LO_CONTENT';
export const GET_SLIDES = 'GET_SLIDES';
export const GET_SLIDES_SUCCESS = 'GET_SLIDES_SUCCESS';
export const GET_SLIDES_FAILURE = 'GET_SLIDES_FAILURE';
export const ADD_SLIDE = 'ADD_SLIDE';
export const ADD_SLIDE_SUCCESS = 'ADD_SLIDE_SUCCESS';
export const ADD_SLIDE_FAILURE = 'ADD_SLIDE_FAILURE';
export const EDIT_SLIDE = 'EDIT_SLIDE';
export const EDIT_SLIDE_SUCCESS = 'EDIT_SLIDE_SUCCESS';
export const EDIT_SLIDE_FAILURE = 'EDIT_SLIDE_FAILURE';
export const DELETE_SLIDE = 'DELETE_SLIDE';
export const DELETE_SLIDE_SUCCESS = 'DELETE_SLIDE_SUCCESS';
export const DELETE_SLIDE_FAILURE = 'DELETE_SLIDE_FAILURE';
export const ADD_PPTX = 'ADD_PPTX';
export const ADD_PPTX_SUCCESS = 'ADD_PPTX_SUCCESS';
export const ADD_PPTX_FAILURE = 'ADD_PPTX_FAILURE';
export const PPTX_PROCESSED = 'PPTX_PROCESSED';

export interface EditLOContent {
    type: typeof EDIT_LO_CONTENT;
    payload: number | null;
}

export interface GetSlidesAction {
    type: typeof GET_SLIDES;
    payload: any;
}

export interface GetSlidesSuccessAction {
    type: typeof GET_SLIDES_SUCCESS;
    payload: { learningObjectiveId: number; slides: Slide[] };
}

export interface GetSlidesFailureAction {
    type: typeof GET_SLIDES_FAILURE;
    payload: string;
}

export interface AddSlideAction {
    type: typeof ADD_SLIDE;
    payload: undefined;
}

export interface AddSlideSuccessAction {
    type: typeof ADD_SLIDE_SUCCESS;
    payload: { learningObjectiveId: number; slide: Slide };
}

export interface AddSlideFailureAction {
    type: typeof ADD_SLIDE_FAILURE;
    payload: string;
}

export interface EditSlideAction {
    type: typeof EDIT_SLIDE;
    payload: undefined;
}

export interface EditSlideSuccessAction {
    type: typeof EDIT_SLIDE_SUCCESS;
    payload: { learningObjectiveId: number; slide: Slide };
}

export interface EditSlideFailureAction {
    type: typeof EDIT_SLIDE_FAILURE;
    payload: string;
}

export interface DeleteSlideAction {
    type: typeof DELETE_SLIDE;
    payload: undefined;
}

export interface DeleteSlideSuccessAction {
    type: typeof DELETE_SLIDE_SUCCESS;
    payload: { learningObjectiveId: number; slideId: number };
}

export interface DeleteSlideFailureAction {
    type: typeof DELETE_SLIDE_FAILURE;
    payload: string;
}

export interface AddPptxAction {
    type: typeof ADD_PPTX;
    payload: number;
}

export interface AddPptxSuccessAction {
    type: typeof ADD_PPTX_SUCCESS;
    payload: number;
}

export interface AddPptxFailureAction {
    type: typeof ADD_PPTX_FAILURE;
    payload: string;
}

export interface PptxProcessedAction {
    type: typeof PPTX_PROCESSED;
    payload: undefined;
}

export type LearningContentActionTypes =
    | EditLOContent
    | GetSlidesAction
    | GetSlidesSuccessAction
    | GetSlidesFailureAction
    | AddSlideAction
    | AddSlideSuccessAction
    | AddSlideFailureAction
    | EditSlideAction
    | EditSlideSuccessAction
    | EditSlideFailureAction
    | DeleteSlideAction
    | DeleteSlideSuccessAction
    | DeleteSlideFailureAction
    | AddPptxAction
    | AddPptxSuccessAction
    | AddPptxFailureAction
    | PptxProcessedAction;

export interface LearningContentState {
    learningObjectiveId: number | null;
    slides: { [learningObjectiveId: number]: Slide[] };
    createEditSlideError: string | null;
    pptxError: string | null;
    lOProcessingPptxId: number | null;
    uploadingPptx: boolean;
}
