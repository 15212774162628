import { FC } from 'react';
import {
    Grid,
    IconButton,
    Typography,
    Icons,
} from '@thirdspacelearning/library';

const sx = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '> .MuiGrid-item': {
            padding: 0,
            backgroundColor: 'green',
        },
    },
};

interface Props {
    currentPosition: number;
    onChangePosition: (position: number) => void;
    hideCurrentPosition?: boolean;
}

const ChangePosition: FC<Props> = ({
    currentPosition,
    onChangePosition,
    hideCurrentPosition,
}) => {
    const onClickPrevious = () => {
        onChangePosition(currentPosition - 1);
    };

    const onClickNext = () => {
        onChangePosition(currentPosition + 1);
    };

    return (
        <Grid item xs={1} sx={sx.wrapper}>
            <IconButton
                onClick={onClickPrevious}
                color="primary"
                disabled={currentPosition === 1}
            >
                <Icons.ArrowUpward />
            </IconButton>
            {!hideCurrentPosition && (
                <Typography align="center" variant="body2">
                    {currentPosition}
                </Typography>
            )}
            <IconButton onClick={onClickNext} color="primary">
                <Icons.ArrowDownward />
            </IconButton>
        </Grid>
    );
};

export default ChangePosition;
