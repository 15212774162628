import React, { FC, MutableRefObject } from 'react';

import {
    Box,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    Portal,
} from '@thirdspacelearning/library';

const sx = {
    toolbarWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 2,
        columnGap: 1,
    },
    quickFilter: {
        width: '200px',
    },
    filterButton: {
        textTransform: 'none',
    },
};

type Props = {
    filterPanelEl: MutableRefObject<any>;
    setFilterButtonEl?: React.Dispatch<
        React.SetStateAction<HTMLButtonElement | null>
    >;
};

const DataGridToolbar: FC<Props> = ({ setFilterButtonEl, filterPanelEl }) => {
    return (
        <Portal container={filterPanelEl.current}>
            <Box sx={sx.toolbarWrapper}>
                <GridToolbarQuickFilter
                    variant="outlined"
                    size="small"
                    sx={sx.quickFilter}
                    debounceMs={500}
                />
                <GridToolbarFilterButton
                    ref={setFilterButtonEl}
                    sx={sx.filterButton}
                />
            </Box>
        </Portal>
    );
};

export default DataGridToolbar;
