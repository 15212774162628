import { FC } from 'react';

import { Tag } from '@thirdspacelearning/library';

interface Props {
    year: number;
    isInDifferentYearGroup: boolean;
}

const YearGroupTag: FC<Props> = ({ year, isInDifferentYearGroup }) => {
    const label = `Y ${year}`;
    const tagType = isInDifferentYearGroup ? 'error' : 'info';
    return <Tag label={label} type={tagType} />;
};

export default YearGroupTag;
