import { FC, useEffect } from 'react';
import { Field } from 'react-final-form';

import { Autocomplete } from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../../../../state/store';

import { LearningObjective } from '../../../../../types';

import {
    getSubStrands,
    selectStrandsAsOptions,
    selectSubStrandsAsOptions,
} from '../../../../../state/strands';

interface Props {
    learningObjective: LearningObjective | { type: string };
}

const StrandSubstrandSection: FC<Props> = ({ learningObjective }) => {
    const dispatch = useAppDispatch();

    const strandOptions = useAppSelector(selectStrandsAsOptions);
    const subStrandOptions = useAppSelector(selectSubStrandsAsOptions);

    const fetchSubStrands = (strandId: number) => {
        dispatch(getSubStrands(strandId));
    };

    useEffect(() => {
        if (learningObjective && 'strand' in learningObjective) {
            const { strand } = learningObjective;
            dispatch(getSubStrands(strand.id));
        }
    }, []);

    if (!subStrandOptions.length && 'strand' in learningObjective) {
        return (
            <></>
        ); /* the form doesn't initialise properly if we get sub strands async, we need to wait for substrands to be filled in */
    }

    return (
        <>
            <Field
                name="strand.id"
                component={Autocomplete}
                options={strandOptions}
                variant="outlined"
                label="Strand"
                color="primary"
                className="noMargin"
                onChangeCallback={fetchSubStrands}
                required
            />
            <Field
                name="sub_strand.id"
                component={Autocomplete}
                options={subStrandOptions}
                variant="outlined"
                label="Sub-Strand"
                color="primary"
                className="noMargin"
                required
            />
        </>
    );
};

export default StrandSubstrandSection;
