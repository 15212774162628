import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void | undefined>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback && savedCallback.current) {
                savedCallback.current();
            }
        }

        let id: NodeJS.Timeout | undefined;
        if (delay !== null) {
            id = setInterval(tick, delay);
        }

        return () => {
            if (id) {
                clearInterval(id);
            }
        };
    }, [delay]);
};
