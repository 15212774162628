import { FC, memo } from 'react';
import { Field } from 'react-final-form';

import { Box, TextFinalField } from '@thirdspacelearning/library';

const sx = {
    wrapper: {
        '> *': {
            marginTop: 2,
        },
    },
};

interface Props {}

const ConceptPracticeApplication: FC<Props> = memo(() => (
    <Box sx={sx.wrapper}>
        <Field
            name="concept"
            label="Concept"
            variant="outlined"
            color="primary"
            component={TextFinalField}
            fullWidth
        />
        <Field
            name="practice"
            label="Practice"
            variant="outlined"
            color="primary"
            component={TextFinalField}
            fullWidth
        />
        <Field
            name="application"
            label="Application"
            variant="outlined"
            color="primary"
            component={TextFinalField}
            fullWidth
        />
    </Box>
));

export default ConceptPracticeApplication;
