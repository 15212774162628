import { FC } from 'react';
import { Grid, Typography } from '@thirdspacelearning/library';

import { JourneyMap } from './components';

const sx = {
    gridWrapper: {
        justifyContent: 'center',
    },
};

interface Props {}

const HomePage: FC<Props> = () => {
    return (
        <Grid container spacing={3} id="homePageGrid" sx={sx.gridWrapper}>
            <Grid item xs={5}>
                <JourneyMap />
            </Grid>
            <Grid item xs={5}>
                <Typography variant="h2">Latest updates</Typography>
                <Typography>V0.8.1 Fix up slide preview</Typography>
                <Typography>
                    V0.8.0 Delete confirmation for slides and LOs
                </Typography>
                <Typography>V0.7.0 Complex view for learning maps</Typography>
                <Typography>
                    V0.6.1 Add extra functionality to LO table
                </Typography>
                <Typography>V0.6 Learning maps</Typography>
                <Typography>V0.5 Add/edit slide</Typography>
                <Typography>V0.4 Learning content page</Typography>
                <Typography>V0.3 Sort and filter LO table</Typography>
                <Typography>V0.2 Ability to add and edit a basic LO</Typography>
                <Typography>V0.1 Basic app setup and navigation</Typography>
            </Grid>
        </Grid>
    );
};

export default HomePage;
