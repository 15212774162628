import { FC, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Button,
    TSLLink,
    Loading,
    Icons,
} from '@thirdspacelearning/library';
import { useAppDispatch, useAppSelector } from '../../state/store';
import {
    getContentMaps,
    selectCurrentContentMap,
    selectContentMapsError,
    selectContentMapsSearching,
    selectContentMapsSearchParams,
    createContentMap,
    setSearchParams,
} from '../../state/content-maps';
import { SearchParams, LearningObjective } from '../../types';
import { ContentMapValues } from '../../api/content-maps';
import { environment } from '../../environments/environment';
import { CurriculumFilters, PageLayout } from '../shared';
import {
    ContentMapGrid,
    LinkObjectiveFormDialog,
    ButtonCard,
    ViewSwitcher,
} from './components';

const sx = {
    error: {
        paddingTop: 3,
        paddingBotto: 3,
    },
    gridWrapper: {
        marginTop: 3,
        marginBottom: 3,
    },
    gridContainer: {
        justifyContent: 'flex-end',
        marginTop: 3,
        marginBottom: 3,
    },
};

const getPhase = (year: number) => (year <= 6 ? 'primary' : 'secondary');

interface Props {}

const LearningMapsPage: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const contentMap = useAppSelector(selectCurrentContentMap);
    const error = useAppSelector(selectContentMapsError);
    const searching = useAppSelector(selectContentMapsSearching);
    const searchParams = useAppSelector(selectContentMapsSearchParams);

    const [selectKeyObjective, setSelectKeyObjective] =
        useState<boolean>(false);
    const [currentView, setCurrentView] = useState<'simple' | 'complex'>(
        'complex'
    );

    useEffect(() => {
        const { year_id, strand_id } = searchParams;

        if (year_id && strand_id) {
            dispatch(getContentMaps(searchParams));
        }
    }, [dispatch, searchParams]);

    const updateSearchParams = (
        params: SearchParams,
        _clearFilters?: boolean
    ) => {
        const newSearchParams = { ...searchParams, ...params };
        const phaseChanged =
            getPhase(+params.year_id) === getPhase(+searchParams.year_id);

        // set strand_id to empty if the phase changed from primary to secondary
        if (!phaseChanged) {
            newSearchParams.strand_id = '';
        }
        dispatch(setSearchParams(newSearchParams));
    };

    const linkLearningObjective = (learningObjective: LearningObjective) => {
        if (contentMap) {
            // edit key learning objective
        } else {
            const newContentMap: ContentMapValues = {
                learningObjective,
                strand_id: searchParams.strand_id as string,
                year_id: searchParams.year_id as string,
            };
            dispatch(createContentMap(newContentMap));
        }
        setSelectKeyObjective(false);
    };

    const showLOSelection = () => {
        setSelectKeyObjective(true);
    };

    const onCloseLinkForm = () => {
        setSelectKeyObjective(false);
    };

    const { year_id, strand_id } = searchParams;
    const showLearningMapContent =
        !error && !searching && Boolean(year_id) && Boolean(strand_id);

    return (
        <PageLayout
            title="Programme type: Diagnostic"
            buttons={[
                <Box display="flex" flexDirection="column">
                    <TSLLink
                        key="content-map-download"
                        download
                        href={`${environment.curriculumCmsApiUrl}/content_maps.csv?year_id=${searchParams.year_id}&strand_id=${searchParams.strand_id}`}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Icons.CloudDownload />}
                        >
                            Download map
                        </Button>
                    </TSLLink>
                    <ViewSwitcher
                        currentView={currentView}
                        onChange={setCurrentView}
                        key="viewSwitcher"
                    />
                </Box>,
            ]}
        >
            <CurriculumFilters
                updateSearchParams={updateSearchParams}
                selectLearningAreas={true}
                searchParams={searchParams}
                filterOptions={[
                    { filterId: 'year_id', multiple: false },
                    { filterId: 'strand_id', multiple: false },
                ]}
                filterBarOptions={{ searchbar: false, clear: false }}
            />

            {error && (
                <Typography sx={sx.error} color="error">
                    {error}
                </Typography>
            )}
            {searching && <Loading />}
            {showLearningMapContent && (
                <Grid container sx={sx.gridContainer}>
                    {!contentMap ? (
                        <Grid item xs={4}>
                            <ButtonCard
                                label="Create learning map"
                                onClick={showLOSelection}
                                variant="contained"
                            />
                        </Grid>
                    ) : (
                        <ContentMapGrid
                            contentMap={contentMap}
                            currentView={currentView}
                        />
                    )}
                </Grid>
            )}
            {selectKeyObjective && (
                <LinkObjectiveFormDialog
                    linkLearningObjective={linkLearningObjective}
                    onClose={onCloseLinkForm}
                    open={selectKeyObjective}
                    initialValues={{ ...contentMap?.key_learning_objective }}
                />
            )}
        </PageLayout>
    );
};

export default LearningMapsPage;
