import { Errors } from './app';
import {
    LearningObjectiveType,
    LearningObjective,
    StandardLearningObjective,
    SATsLearningObjective,
    GCSELearningObjective,
} from './curriculum';

export function determineIfErrors(
    toBeDetermined: any
): toBeDetermined is Errors {
    if (toBeDetermined && (toBeDetermined as Errors).error) {
        return true;
    }
    return false;
}

export const isStandardType = (
    learningObjective: LearningObjective
): learningObjective is StandardLearningObjective => {
    return learningObjective.type === LearningObjectiveType.STANDARD;
};

export const isSatsType = (
    learningObjective: LearningObjective
): learningObjective is SATsLearningObjective => {
    return learningObjective.type === LearningObjectiveType.SATS;
};

export const isGcseType = (
    learningObjective: LearningObjective
): learningObjective is GCSELearningObjective => {
    return learningObjective.type === LearningObjectiveType.GCSE;
};
