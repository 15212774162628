import { RefObject, useLayoutEffect, useState } from 'react';
interface IDocument extends Document {
    exitFullscreen: () => Promise<void>;
    mozCancelFullScreen: () => Promise<void>;
    webkitExitFullscreen: () => Promise<void>;
    fullscreenElement: HTMLDivElement;
    mozFullScreenElement: () => HTMLDivElement;
    msFullscreenElement: () => HTMLDivElement;
    webkitFullscreenElement: () => HTMLDivElement;
}

export const useFullscreenStatus = (elRef?: RefObject<HTMLDivElement>) => {
    const [isFullscreen, setIsFullscreen] = useState(
        getBrowserFullscreenElementProp() !== null &&
            (document as IDocument)[
                getBrowserFullscreenElementProp() as FullscreenElementProp
            ] != null
    );

    const setFullscreen = () => {
        if (elRef && elRef.current !== null) {
            if (isFullscreen) {
                (document as IDocument).exitFullscreen();
            } else {
                elRef.current.requestFullscreen().catch(() => {
                    setIsFullscreen(false);
                });
            }
        }
    };

    useLayoutEffect(() => {
        (document as IDocument).onfullscreenchange = () =>
            setIsFullscreen(
                getBrowserFullscreenElementProp() !== null &&
                    (document as IDocument)[
                        getBrowserFullscreenElementProp() as FullscreenElementProp
                    ] != null
            );

        return () => {
            (document as IDocument).onfullscreenchange = null;
        };
    });

    return { isFullscreen, setFullscreen };
};

type FullscreenElementProp =
    | 'fullscreenElement'
    | 'mozFullScreenElement'
    | 'msFullscreenElement'
    | 'webkitFullscreenElement';

export function getBrowserFullscreenElementProp(): FullscreenElementProp | null {
    if (typeof (document as IDocument).fullscreenElement !== 'undefined') {
        return 'fullscreenElement';
    } else if (
        typeof (document as IDocument).mozFullScreenElement !== 'undefined'
    ) {
        return 'mozFullScreenElement';
    } else if (
        typeof (document as IDocument).msFullscreenElement !== 'undefined'
    ) {
        return 'msFullscreenElement';
    } else if (
        typeof (document as IDocument).webkitFullscreenElement !== 'undefined'
    ) {
        return 'webkitFullscreenElement';
    }

    return null;
}
