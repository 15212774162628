import { createSelector } from 'reselect';
import {
    GET_CONTENT_MAPS,
    GET_CONTENT_MAPS_SUCCESS,
    GET_CONTENT_MAPS_FAILURE,
    ADD_CONTENT_MAP,
    ADD_CONTENT_MAP_SUCCESS,
    ADD_CONTENT_MAP_FAILURE,
    ADD_CONTENT_MAP_NODE_SUCCESS,
    ADD_CONTENT_MAP_NODE_FAILURE,
    EDIT_CONTENT_MAP_NODE_SUCCESS,
    DELETE_CONTENT_MAP_NODE_SUCCESS,
    ContentMapsState,
    ContentMapsActionTypes,
    SET_SEARCH_PARAMS,
} from './types';
import { RootState } from '../types';
import { ContentMapNode, ContentMap } from '../../types';

export const contentMapsInitialState: ContentMapsState = {
    currentContentMap: null,
    searching: false,
    searchParams: { year_id: '1', strand_id: '' }, //TODO sort this properly
    error: null,
};

const positionSort = (a: ContentMapNode, b: ContentMapNode) =>
    a.position - b.position;

const getContentMapNodes = (
    currentContentMap: ContentMap,
    node: ContentMapNode
): ContentMapNode[] => {
    const parentNode = findParentNode(currentContentMap, node.id);

    const supports =
        parentNode && editNode(currentContentMap, node, parentNode);

    const nodes = parentNode
        ? editNode(currentContentMap, {
              ...parentNode,
              supports,
          })
        : editNode(currentContentMap, node);

    return nodes;
};

const getNodesToDelete = (
    currentContentMap: ContentMap,
    nodeId: number
): ContentMapNode[] => {
    const parentNodeDelete = findParentNode(currentContentMap, nodeId);

    const supportsDelete =
        parentNodeDelete &&
        removeNode(currentContentMap, nodeId, parentNodeDelete);

    const nodesDelete = parentNodeDelete
        ? editNode(currentContentMap, {
              ...parentNodeDelete,
              supports: supportsDelete,
          })
        : removeNode(currentContentMap, nodeId);

    return nodesDelete;
};

const contentMaps = (
    state = contentMapsInitialState,
    action: ContentMapsActionTypes
): ContentMapsState => {
    switch (action.type) {
        case GET_CONTENT_MAPS:
            return {
                ...state,
                searching: true,
                error: null,
            };
        case ADD_CONTENT_MAP:
            return {
                ...state,
                error: null,
                searching: true,
            };
        case GET_CONTENT_MAPS_SUCCESS:
            return {
                ...state,
                currentContentMap: action.payload[0],
                searching: false,
            };
        case SET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: action.payload,
            };
        case ADD_CONTENT_MAP_SUCCESS:
        case ADD_CONTENT_MAP_NODE_SUCCESS:
            return {
                ...state,
                currentContentMap: action.payload,
                searching: false,
            };
        case EDIT_CONTENT_MAP_NODE_SUCCESS:
            if (!state.currentContentMap) {
                return state;
            }

            return {
                ...state,
                currentContentMap: {
                    ...state.currentContentMap,
                    nodes: getContentMapNodes(
                        state.currentContentMap,
                        action.payload
                    ),
                },
                searching: false,
            };
        case DELETE_CONTENT_MAP_NODE_SUCCESS:
            if (!state.currentContentMap) {
                return state;
            }

            return {
                ...state,
                currentContentMap: {
                    ...state.currentContentMap,
                    nodes: getNodesToDelete(
                        state.currentContentMap,
                        action.payload
                    ),
                },
                searching: false,
            };
        case GET_CONTENT_MAPS_FAILURE:
        case ADD_CONTENT_MAP_FAILURE:
        case ADD_CONTENT_MAP_NODE_FAILURE:
            return {
                ...state,
                error: action.payload,
                currentContentMap: null,
                searching: false,
            };
        default:
            return state;
    }
};

const removeNode = (
    contentMap: ContentMap,
    nodeId: number,
    parentNode?: ContentMapNode
) => {
    const oldNodes =
        parentNode && parentNode.supports
            ? parentNode.supports
            : contentMap.nodes;
    const filteredNodes = oldNodes.filter((node) => node.id !== nodeId);

    return filteredNodes;
};

const editNode = (
    contentMap: ContentMap,
    newNode: ContentMapNode,
    parentNode?: ContentMapNode
) => {
    const oldNodes =
        parentNode && parentNode.supports
            ? parentNode.supports
            : contentMap.nodes;
    const filteredNodes = oldNodes.filter((node) => node.id !== newNode.id);
    filteredNodes.push(newNode);
    filteredNodes.sort(positionSort);

    return filteredNodes;
};

const findParentNode = (contentMap: ContentMap, nodeId: number) => {
    let supportForNode: ContentMapNode | undefined;
    contentMap.nodes.forEach((node) => {
        if (node.id !== nodeId) {
            const supportNode =
                node.supports &&
                node.supports.find((supportNode) => supportNode.id === nodeId);
            if (supportNode) {
                supportForNode = node;
            }
        }
    });

    return supportForNode;
};

const contentMapsSelector = (state: RootState) => state.contentMaps;

export const selectCurrentContentMap = createSelector(
    contentMapsSelector,
    (contentMaps: ContentMapsState) => contentMaps.currentContentMap
);

export const selectContentMapsSearchParams = createSelector(
    contentMapsSelector,
    (contentMaps: ContentMapsState) => contentMaps.searchParams
);

export const selectContentMapsSearching = createSelector(
    contentMapsSelector,
    (contentMaps: ContentMapsState) => contentMaps.searching
);

export const selectContentMapsError = createSelector(
    contentMapsSelector,
    (contentMaps: ContentMapsState) => contentMaps.error
);

export default contentMaps;
