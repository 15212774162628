import axios from 'axios';
import qs from 'qs';
import {
    LearningObjective,
    ContentDomain,
    Errors,
    LearningObjectiveType,
    ImpactType,
    Strand,
    SubStrand,
} from '../types';

import { environment } from '../environments/environment';

export interface LearningObjectiveParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
    type?: LearningObjectiveType;
    sort?: string;
    legacy?: LearningObjectiveType;
}

export const getLearningObjectives = async (
    params: LearningObjectiveParams = {}
): Promise<
    { learningObjectives: LearningObjective[]; totalCount: number } | Errors
> => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/learning_objectives`, {
            params,
            /**
             * passing array values will format as follows:
             * INPUT: { "year_id": [3, 4] }  OUTPUT: &year_id[]=3&year_id[]=4
             *
             * This works for integer but cannot consume strings:
             * INPUT: { "impact": ["low", "high"]}  OUTPUT: &impact[]=low&impact[]=high
             *
             * the below function formats all arrays as CVS
             * INPUT: { "impact": ["low", "high"]}  OUTPUT: &impact=low%2Chigh
             * which can be consumed successfully by the API
             */
            paramsSerializer: (params) => {
                const formattedParams = Object.keys(params).reduce(
                    (acc: { [key: string]: string }, key: string) => {
                        acc[key] = Array.isArray(params[key])
                            ? params[key].join(',')
                            : params[key];
                        return acc;
                    },
                    {}
                );
                return qs.stringify(formattedParams);
            },
            withCredentials: true,
        })
        .then(function (response) {
            return {
                learningObjectives: response.data,
                totalCount: Number(response.headers.total),
            };
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export const getSingleLearningObjective = async (
    learningObjectiveId: number
): Promise<LearningObjective | Errors> => {
    return axios
        .get(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}`,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
export interface LearningObjectiveValues {
    type: LearningObjectiveType;
    title?: string;
    year?: { id: number };
    concept?: string;
    practice?: string;
    application?: string;
    active?: boolean;
    learning_contents?: { title: string; id: number }[];
    impact?: ImpactType;
    strand?: Strand;
    sub_strand?: SubStrand;
    revision_order?: number;
}

export const createLearningObjectives = async (
    values: LearningObjectiveValues,
    type: LearningObjectiveType,
    contentDomain: null | ContentDomain[]
): Promise<LearningObjective | Errors> => {
    const cdIds = contentDomain && contentDomain.map((cd) => cd.id);

    interface Values {
        content_domain_id?: number | null;
        content_domain_ids?: number[] | null;
        strand_id?: number | null;
        sub_strand_id?: number | null;
        year_id?: number;
    }
    const typeValueMap: Record<LearningObjectiveType, Values> = {
        [LearningObjectiveType.STANDARD]: {
            sub_strand_id: values.sub_strand?.id,
        },
        [LearningObjectiveType.SATS]: {
            content_domain_ids: cdIds,
            year_id: 6,
        },
        [LearningObjectiveType.GCSE]: {
            year_id: 10,
        },
    };
    const data = {
        learning_objective: {
            type: values.type,
            title: values.title,
            year_id: values.year && values.year.id,
            concept: values.concept,
            practice: values.practice,
            application: values.application,
            learning_contents_attributes:
                values.learning_contents &&
                values.learning_contents.map((lc) => ({
                    title: lc.title,
                })),
            impact: values.impact,
            strand_id: values.strand && values.strand.id,
            ...typeValueMap[type],
        },
    };
    return axios
        .post(`${environment.curriculumCmsApiUrl}/learning_objectives`, data, {
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            let errors: Errors = { error: errorMessage };

            if (error.response && error.response.status === 422) {
                errors = { ...errors, ...error.response.data };
                if (errors.title) {
                    errors.title =
                        'A learning objective already exists with this title';
                }
            }

            return errors;
        });
};

export const editLearningObjectives = async (
    id: number,
    values: LearningObjectiveValues,
    type: LearningObjectiveType,
    contentDomain?: null | ContentDomain[]
): Promise<LearningObjective | Errors> => {
    const cdIds = contentDomain && contentDomain.map((cd) => cd.id);

    interface ContentDomainInfo {
        content_domain_id?: number | null;
        content_domain_ids?: number[] | null;
    }
    const contentDomainInfoMap: Record<
        LearningObjectiveType,
        ContentDomainInfo
    > = {
        [LearningObjectiveType.STANDARD]: {
            content_domain_id: cdIds && cdIds[0],
        },
        [LearningObjectiveType.SATS]: {
            content_domain_ids: cdIds,
        },
        [LearningObjectiveType.GCSE]: {},
    };

    const data = {
        learning_objective: {
            type: values.type,
            active: values.active,
            title: values.title,
            year_id: values.year && values.year.id,
            concept: values.concept,
            practice: values.practice,
            application: values.application,
            learning_contents_attributes:
                values.learning_contents &&
                values.learning_contents.map((lc) => ({
                    title: lc.title,
                })),
            impact: values.impact,
            strand_id: values.strand && values.strand.id,
            revision_order: values.revision_order,
            ...contentDomainInfoMap[type],
        },
    };
    return axios
        .put(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${id}`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            let errors: Errors = { error: errorMessage };

            if (error.response && error.response.status === 422) {
                errors = { ...errors, ...error.response.data };
                if (errors.title) {
                    errors.title =
                        'A learning objective already exists with this title';
                }
            }

            return errors;
        });
};

export const deactivateLearningObjectives = async (
    id: number
): Promise<LearningObjective | Errors> => {
    return axios
        .delete(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${id}`,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
