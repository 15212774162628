import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { store } from '../state/store';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkResult<T> = ThunkAction<T, RootState, undefined, Action>;

export * from './content-maps/types';
export * from './feedback/types';
export * from './learning-objectives/types';
export * from './learning-objectives/learning-content/types';
export * from './strands/types';
export * from './years/types';
