import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types';

export interface AuthState {
    user: User | null;
    unauthorized: boolean;
}

export const authInitialState: AuthState = {
    user: null,
    unauthorized: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        loginUser: (state, { payload }: PayloadAction<{ user: User }>) => {
            state.user = payload.user;
            state.unauthorized = false;
        },
        logoutUser: (state) => {
            state.user = null;
            state.unauthorized = true;
        },
    },
});

export const { loginUser, logoutUser } = authSlice.actions;

export type AuthActionTypes = typeof loginUser | typeof logoutUser;

export const authReducer = authSlice.reducer;

export const authSelector = (state: { auth: AuthState }) => state.auth;
