import { FC } from 'react';

import {
    Theme,
    Button,
    IconButton,
    Paper,
    Typography,
    Icons,
} from '@thirdspacelearning/library';

const sx = {
    previewControls: (theme: Theme) => ({
        borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
        boxShadow: 'none',
        width: '400px',
        height: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
};

interface Props {
    currentIndex: number;
    onClose: () => void;
    onFullScreen: (fullScreen: boolean) => void;
    onChangeIndex: (newIndex: number) => void;
    totalViews: number;
    fullScreen: boolean;
}

const SlideControl: FC<Props> = ({
    currentIndex,
    onClose,
    onFullScreen,
    onChangeIndex,
    totalViews,
    fullScreen,
}) => {
    const onClickFirst = () => {
        onChangeIndex(0);
    };

    const onClickPrevious = () => {
        onChangeIndex(currentIndex - 1);
    };

    const onClickNext = () => {
        onChangeIndex(currentIndex + 1);
    };

    const onClickFullscreen = () => {
        onFullScreen(!fullScreen);
    };

    return (
        <Paper sx={sx.previewControls}>
            <IconButton
                color="primary"
                onClick={onClickFirst}
                disabled={currentIndex === 0 || totalViews <= 0}
            >
                <Icons.FirstPage />
            </IconButton>
            <IconButton
                color="primary"
                onClick={onClickPrevious}
                disabled={currentIndex === 0 || totalViews <= 0}
            >
                <Icons.NavigateBefore />
            </IconButton>
            <Typography>
                {`${currentIndex + 1}/${totalViews || '??'}`}
            </Typography>
            <IconButton
                color="primary"
                onClick={onClickNext}
                disabled={currentIndex === totalViews - 1 || totalViews <= 0}
            >
                <Icons.NavigateNext />
            </IconButton>
            <IconButton color="primary" onClick={onClickFullscreen}>
                {fullScreen ? <Icons.FullscreenExit /> : <Icons.Fullscreen />}
            </IconButton>
            <Button
                variant="text"
                endIcon={<Icons.Close />}
                onClick={onClose}
                color="primary"
            >
                Close
            </Button>
        </Paper>
    );
};

export default SlideControl;
