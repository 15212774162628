export const SHOW_SNACKBOX = 'SHOW_SNACKBOX';
export const HIDE_SNACKBOX = 'HIDE_SNACKBOX';

export interface ShowSnackboxAction {
    type: typeof SHOW_SNACKBOX;
    payload: { message: string; errorType: boolean };
}

export interface HideSnackboxAction {
    type: typeof HIDE_SNACKBOX;
    payload: undefined;
}

export type FeedbackActionTypes = ShowSnackboxAction | HideSnackboxAction;

export interface FeedbackState {
    snackboxMessage: string | null;
    snackboxError: boolean;
}
