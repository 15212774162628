import { FC } from 'react';

import { Tag } from '@thirdspacelearning/library';

interface Props {
    id: number;
    isInDifferentStrand: boolean;
}

const LOIDTag: FC<Props> = ({ id, isInDifferentStrand }) => {
    const label = `ID ${id}`;
    const tagType = isInDifferentStrand ? 'error' : 'info';
    return <Tag label={label} type={tagType} />;
};

export default LOIDTag;
