import { SHOW_SNACKBOX, HIDE_SNACKBOX } from './types';

export const showSnackbox = (message: string, errorType?: boolean) => ({
    type: SHOW_SNACKBOX,
    payload: { message, errorType },
});

export const hideSnackbox = () => ({
    type: HIDE_SNACKBOX,
});
