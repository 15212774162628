import { FC } from 'react';

import {
    Box,
    Button,
    CardContent,
    CardMedia,
    Divider,
    RadioGroup,
    Typography,
    RadioField,
    Select,
    Icons,
} from '@thirdspacelearning/library';

import { stepsToSuccess } from '../../../../utils';

import {
    Slide,
    SlideType,
    LearningObjective,
    isStandardType,
} from '../../../../types';

import SlideContentLayout from './SlideContentLayout';

const sx = {
    slideMedia: {
        width: '100%',
        height: '500px',
        backgroundSize: 'contain',
        backgroundPosition: 'left top',
    },
    tutorNotes: {
        overflow: 'auto',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    selectWidth: {
        width: '100%',
    },
};

const selectSxProps = { formControl: sx.selectWidth };

interface Props {
    onEdit?: () => void;
    currentSlide: Slide;
    editable: boolean;
    learningObjective: LearningObjective;
    fullScreen: boolean;
}

export const SlideView: FC<Props> = ({
    onEdit,
    currentSlide,
    editable,
    learningObjective,
    fullScreen,
}) => {
    const slideTypeOptions = [];
    if (currentSlide.learning_content) {
        slideTypeOptions.push({
            id: currentSlide.learning_content.id,
            name: currentSlide.learning_content.title,
        });
    }

    const slideTypeField = (
        <>
            <Divider />
            <Box my={1}>
                <Typography component="div">
                    {isStandardType(learningObjective) ? 'Slide Type' : 'Steps'}
                </Typography>
                {isStandardType(learningObjective) ? (
                    <RadioGroup value={currentSlide.slide_type}>
                        <RadioField
                            size="small"
                            value="concept"
                            label={stepsToSuccess(
                                SlideType.CONCEPT,
                                learningObjective
                            )}
                            checked={currentSlide.slide_type === 'concept'}
                            disabled
                        />
                        <RadioField
                            size="small"
                            value="practice"
                            label={stepsToSuccess(
                                SlideType.PRACTICE,
                                learningObjective
                            )}
                            checked={currentSlide.slide_type === 'practice'}
                            disabled
                        />
                        <RadioField
                            size="small"
                            value="application"
                            label={stepsToSuccess(
                                SlideType.APPLICATION,
                                learningObjective
                            )}
                            checked={currentSlide.slide_type === 'application'}
                            disabled
                        />
                    </RadioGroup>
                ) : (
                    <Select
                        disabled
                        name="slideType"
                        value={
                            currentSlide.learning_content
                                ? String(currentSlide.learning_content.id)
                                : ''
                        }
                        options={slideTypeOptions}
                        sx={selectSxProps}
                    />
                )}
            </Box>
        </>
    );

    return (
        <Box display="flex" height="100%" position="relative" flexGrow={1}>
            <SlideContentLayout
                left={
                    <Box pl={1}>
                        <CardMedia
                            image={currentSlide.image_path}
                            title="preview slide image"
                            sx={sx.slideMedia}
                        />
                        {!!fullScreen && slideTypeField}
                    </Box>
                }
                right={
                    <CardContent sx={sx.tutorNotes}>
                        {onEdit && editable && (
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    size="small"
                                    variant="text"
                                    color="primary"
                                    startIcon={<Icons.Edit />}
                                    onClick={onEdit}
                                >
                                    Edit
                                </Button>
                            </Box>
                        )}
                        <Typography component="div">Tutor Notes</Typography>
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                                __html: currentSlide.tutor_notes,
                            }}
                            variant="body2"
                        />
                        {!fullScreen && slideTypeField}
                    </CardContent>
                }
            />
        </Box>
    );
};

export default SlideView;
