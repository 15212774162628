/* eslint-disable @typescript-eslint/no-explicit-any */
interface BootOptions {
    app_id: string;
    user_id: number;
    name: string;
    email: string;
    company: {
        id: number;
        name: string;
    };
}

interface EventMetadata {
    [key: string]: any;
}

declare global {
    interface Window {
        [key: string]: any;
    }
}

class IntercomService {
    static boot(options: BootOptions): void {
        if (window.Intercom) {
            window.Intercom('boot', options);
        }
    }

    static shutdown(): void {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    static update(options: Partial<BootOptions>): void {
        if (window.Intercom) {
            window.Intercom('update', options);
        }
    }

    static trackEvent(eventName: string, metadata?: EventMetadata): void {
        if (window) {
            window.Intercom('trackEvent', eventName, metadata);
        }
    }

    static settings(
        alignment: string,
        action_color: string,
        background_color: string
    ): void {
        if (window.Intercom) {
            window.intercomSettings = {
                alignment,
                action_color,
                background_color,
            };
        }
    }
}

export { IntercomService };
