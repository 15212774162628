import { FC } from 'react';

import { ActionPanel, Box } from '@thirdspacelearning/library';

import errorSvg from '../../../assets/error.svg';

const sx = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
};

type Props = {
    error?: string | undefined;
    message?: string | JSX.Element | undefined;
};

const ErrorActionPanel: FC<Props> = ({ error, message }) => {
    const onRefresh = () => {
        window.location.reload();
    };

    return (
        <Box sx={sx.actionPanel}>
            <ActionPanel
                buttonAction={onRefresh}
                buttonLabel="Refresh page"
                decoration={<img src={errorSvg} alt="An error occurred" />}
                title={error || 'Something went wrong loading the page'}
                textContent={message || 'Please refresh and try again'}
            />
        </Box>
    );
};

export default ErrorActionPanel;
