import { FC, useEffect, memo } from 'react';
import { Field } from 'react-final-form';

import { Autocomplete } from '@thirdspacelearning/library';

import { useAppSelector } from '../../../../../state/store';

import { selectStrandsAsOptions } from '../../../../../state/strands';

import { ContentDomain, Phase } from '../../../../../types';

interface Props {
    setContentDomains: (cd: ContentDomain[] | null) => void;
}

const SingleStrandSection: FC<Props> = memo(({ setContentDomains }) => {
    const strandOptions = useAppSelector(selectStrandsAsOptions);

    const filteredStrands = strandOptions.filter(
        (strand) => strand.phase === Phase.SECONDARY
    );

    useEffect(() => {
        setContentDomains([]);
    }, [setContentDomains]);

    return (
        <Field
            name="strand.id"
            component={Autocomplete}
            options={filteredStrands}
            variant="outlined"
            label="Strand"
            color="primary"
            className="noMargin"
            required
        />
    );
});

export default SingleStrandSection;
