import { ContentMap, ContentMapNode, SearchParams } from '../../types';

export const GET_CONTENT_MAPS = 'GET_CONTENT_MAPS';
export const GET_CONTENT_MAPS_SUCCESS = 'GET_CONTENT_MAPS_SUCCESS';
export const GET_CONTENT_MAPS_FAILURE = 'GET_CONTENT_MAPS_FAILURE';
export const ADD_CONTENT_MAP = 'ADD_CONTENT_MAP';
export const ADD_CONTENT_MAP_SUCCESS = 'ADD_CONTENT_MAP_SUCCESS';
export const ADD_CONTENT_MAP_FAILURE = 'ADD_CONTENT_MAP_FAILURE';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

export const ADD_CONTENT_MAP_NODE = 'ADD_CONTENT_MAP_NODE';
export const ADD_CONTENT_MAP_NODE_SUCCESS = 'ADD_CONTENT_MAP_NODE_SUCCESS';
export const ADD_CONTENT_MAP_NODE_FAILURE = 'ADD_CONTENT_MAP_NODE_FAILURE';
export const EDIT_CONTENT_MAP_NODE = 'EDIT_CONTENT_MAP_NODE';
export const EDIT_CONTENT_MAP_NODE_SUCCESS = 'EDIT_CONTENT_MAP_NODE_SUCCESS';
export const EDIT_CONTENT_MAP_NODE_FAILURE = 'EDIT_CONTENT_MAP_NODE_FAILURE';
export const DELETE_CONTENT_MAP_NODE = 'DELETE_CONTENT_MAP_NODE';
export const DELETE_CONTENT_MAP_NODE_SUCCESS =
    'DELETE_CONTENT_MAP_NODE_SUCCESS';
export const DELETE_CONTENT_MAP_NODE_FAILURE =
    'DELETE_CONTENT_MAP_NODE_FAILURE';

export interface GetContentMapsAction {
    type: typeof GET_CONTENT_MAPS;
    payload: any;
}

export interface GetContentMapsSuccessAction {
    type: typeof GET_CONTENT_MAPS_SUCCESS;
    payload: ContentMap[];
}

export interface GetContentMapsFailureAction {
    type: typeof GET_CONTENT_MAPS_FAILURE;
    payload: string;
}

export interface SetSearchParamsAction {
    type: typeof SET_SEARCH_PARAMS;
    payload: any;
}

export interface AddContentMapAction {
    type: typeof ADD_CONTENT_MAP;
    payload: undefined;
}

export interface AddContentMapSuccessAction {
    type: typeof ADD_CONTENT_MAP_SUCCESS;
    payload: ContentMap;
}

export interface AddContentMapFailureAction {
    type: typeof ADD_CONTENT_MAP_FAILURE;
    payload: string;
}

export interface AddContentMapNodeAction {
    type: typeof ADD_CONTENT_MAP_NODE;
    payload: undefined;
}

export interface AddContentMapNodeSuccessAction {
    type: typeof ADD_CONTENT_MAP_NODE_SUCCESS;
    payload: ContentMap;
}

export interface AddContentMapNodeFailureAction {
    type: typeof ADD_CONTENT_MAP_NODE_FAILURE;
    payload: string;
}

export interface EditContentMapNodeAction {
    type: typeof EDIT_CONTENT_MAP_NODE;
    payload: undefined;
}

export interface EditContentMapNodeSuccessAction {
    type: typeof EDIT_CONTENT_MAP_NODE_SUCCESS;
    payload: ContentMapNode;
}

export interface EditContentMapNodeFailureAction {
    type: typeof EDIT_CONTENT_MAP_NODE_FAILURE;
    payload: string;
}

export interface DeleteContentMapNodeAction {
    type: typeof DELETE_CONTENT_MAP_NODE;
    payload: undefined;
}

export interface DeleteContentMapNodeSuccessAction {
    type: typeof DELETE_CONTENT_MAP_NODE_SUCCESS;
    payload: number;
}

export interface DeleteContentMapNodeFailureAction {
    type: typeof DELETE_CONTENT_MAP_NODE_FAILURE;
    payload: string;
}

export type ContentMapsActionTypes =
    | GetContentMapsAction
    | GetContentMapsSuccessAction
    | GetContentMapsFailureAction
    | SetSearchParamsAction
    | AddContentMapAction
    | AddContentMapSuccessAction
    | AddContentMapFailureAction
    | AddContentMapNodeAction
    | AddContentMapNodeSuccessAction
    | AddContentMapNodeFailureAction
    | EditContentMapNodeAction
    | EditContentMapNodeSuccessAction
    | EditContentMapNodeFailureAction
    | DeleteContentMapNodeAction
    | DeleteContentMapNodeSuccessAction
    | DeleteContentMapNodeFailureAction;

export interface ContentMapsState {
    currentContentMap: ContentMap | null;
    searching: boolean;
    searchParams: SearchParams;
    error: string | null;
}
