import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ArrowBackRounded } from '@mui/icons-material';

import {
    Box,
    Button,
    Chip,
    Loading,
    Typography,
} from '@thirdspacelearning/library';

import ProgrammeTable from './ProgrammeTable';

import { ChipMenu } from '../../shared';
import { ProgrammeStatus, ProgrammeType } from '../../types';
import { statusMap } from '../../utils';

import {
    ErrorActionPanel,
    PlaceholderActionPanel,
    PageLayout,
} from '../../../shared';
import { capitalise } from '../../../../utils';

import { CurriculumQueryKeys } from '../../../../api/services/curriculum-query-keys';
import { getProgramme } from '../../../../api/programmes';
import { QUERY_STATUS } from '../../../../api/services/query-client';

const sx = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
    },
};

const ProgrammePage = () => {
    const { programmeId } = useParams();
    const { showProgrammesOnCurriculumCms } = useFlags();

    const [selectedStatus, setSelectedStatus] =
        useState<ProgrammeStatus | null>(null);

    const { status, data: programme } = useQuery<ProgrammeType>(
        [`${CurriculumQueryKeys.PROGRAMME}-${programmeId}`],
        () => getProgramme(programmeId),
        { enabled: !!programmeId, retry: 1, refetchOnWindowFocus: false }
    );

    const handleProgrammeStatusChange = (status: ProgrammeStatus) => {
        setSelectedStatus(status);

        // TODO: send request to apply on the BE
    };

    useEffect(() => {
        if (programme && programme.status && !selectedStatus) {
            setSelectedStatus(programme.status);
        }
    }, [programme]);

    if (showProgrammesOnCurriculumCms === false) {
        return <PlaceholderActionPanel />;
    }

    return (
        <>
            {status === QUERY_STATUS.loading && (
                <Loading loadingMessage="Loading programme..." />
            )}

            {status === QUERY_STATUS.error && (
                <ErrorActionPanel
                    error="There was an error loading the programme"
                    message="Please refresh the page and try again. If the problem persists, please let us know."
                />
            )}

            {status === QUERY_STATUS.success && programme && (
                <Box>
                    <Button
                        component={Link}
                        to="/programmes"
                        variant="text"
                        color="primary"
                        startIcon={<ArrowBackRounded />}
                        sx={{ mx: 2 }}
                    >
                        Return to all programmes
                    </Button>
                    <PageLayout
                        title={programme.title}
                        buttons={[
                            <ChipMenu
                                items={Object.values(ProgrammeStatus)}
                                onChange={handleProgrammeStatusChange}
                                label={
                                    capitalise(selectedStatus) || 'No status'
                                }
                                color={
                                    selectedStatus
                                        ? statusMap[selectedStatus]
                                        : 'default'
                                }
                            />,
                            <Chip
                                label={`Key stage ${programme.key_stage}`}
                                variant="outlined"
                            />,
                            <Chip
                                label={`Year ${programme.year_id}`}
                                variant="outlined"
                            />,
                            <Button
                                key="edit-programme"
                                variant="outlined"
                                color="primary"
                            >
                                Edit Details
                            </Button>,
                        ]}
                    >
                        <Box sx={sx.content}>
                            <Typography
                                variant="body1"
                                maxWidth="900px"
                                color="text.secondary"
                            >
                                {programme.description}
                            </Typography>
                            <Box sx={sx.actions}>
                                <Typography fontWeight="bold">
                                    {`${programme.learning_objectives.length} objectives`}
                                </Typography>
                                <Button variant="outlined" color="primary">
                                    Add learning objective
                                </Button>
                                <Button variant="outlined" color="primary">
                                    Reorder objectives
                                </Button>
                            </Box>
                            <ProgrammeTable
                                los={programme.learning_objectives}
                            />
                        </Box>
                    </PageLayout>
                </Box>
            )}
        </>
    );
};

export default ProgrammePage;
