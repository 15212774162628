import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form';
import {
    Box,
    Button,
    CardMedia,
    Definition,
    Typography,
    Icons,
} from '@thirdspacelearning/library';

const sx = {
    slideMedia: {
        width: '100%',
        height: '500px',
        backgroundSize: 'contain',
        backgroundPosition: 'left top',

        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
};

function Dropzone({
    input,
    meta,
    buttonLabel,
    showPreview,
    showDetails,
}: FieldRenderProps<string>) {
    const { onChange, value } = input;
    const [preview, setPreview] = useState<string>(value);
    const [details, setDetails] = useState<File | null>(null);

    useEffect(() => {
        try {
            if (typeof value === 'object') {
                setPreview(URL.createObjectURL(value));
            } else {
                setPreview(value);
            }
        } catch (e) {
            /**
             * ignore
             * this is here to handle a strange on load case
             * where SlideEdit would not load the intial
             * preview correctly until a slide shift
             */
        }
    }, [value]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop: (acceptedFiles) => {
            setPreview(URL.createObjectURL(acceptedFiles[0]));
            if (onChange) {
                const reader = new FileReader();

                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                    setDetails(acceptedFiles[0]);

                    const base64 =
                        reader.result && String(reader.result).split(',')[1];
                    onChange({ base64, name: acceptedFiles[0].name });
                };
                reader.readAsDataURL(acceptedFiles[0]);

                onChange(acceptedFiles);
            }
        },
    });

    const uploadButton = (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<Icons.CloudUpload />}
        >
            {buttonLabel}
        </Button>
    );

    const previewContent = (
        <Box display="flex" flexDirection="column" mr={2} mb={1}>
            {uploadButton}
            {meta.error && meta.touched && !meta.dirtySinceLastSubmit && (
                <Typography color="error" align="center">
                    {meta.error}
                </Typography>
            )}
        </Box>
    );

    return (
        // @ts-ignore
        <Box {...getRootProps()} flexGrow={1}>
            <input
                {...getInputProps()}
                style={{
                    ...getInputProps().style,
                    appearance: 'none',
                    columnFill: 'balance',
                    MozColumnFill: 'balance',
                    WebkitColumnFill: 'balance',
                }}
            ></input>
            {showPreview ? (
                preview !== '' ? (
                    <CardMedia
                        image={preview}
                        title="preview slide image"
                        sx={sx.slideMedia}
                    >
                        {previewContent}
                    </CardMedia>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        {previewContent}
                    </Box>
                )
            ) : (
                <Box mt={3}>{uploadButton}</Box>
            )}
            {showDetails && details && (
                <>
                    {meta.error && meta.touched && (
                        <Typography color="error">{meta.error}</Typography>
                    )}
                    {details.name && (
                        <Definition label="File name" value={details?.name} />
                    )}
                    {details.size && (
                        <Definition
                            label="File size"
                            value={`${details?.size / 1000}KB`}
                        />
                    )}
                </>
            )}
        </Box>
    );
}

export default Dropzone;
