import { FC } from 'react';

import { Snackbar } from '@thirdspacelearning/library';
import { Alert } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../state/store';
import {
    selectSnackboxMessage,
    selectSnackboxErrorType,
    hideSnackbox,
} from '../../state/feedback';

interface Props {}

const Snackbox: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(selectSnackboxMessage);
    const errorType = useAppSelector(selectSnackboxErrorType);

    const handleClose = () => {
        dispatch(hideSnackbox());
    };

    const severity = errorType ? 'error' : 'success';

    return (
        <Snackbar
            open={!!message}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={severity} color={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Snackbox;
