import { Observable, Subject, Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export class Timer {
    get stop$() {
        return this._stop$.asObservable();
    }

    get isPaused(): boolean {
        return !this.subscription;
    }

    public isStarted = false;

    private readonly _observable$: Observable<unknown>;
    private readonly _stop$ = new Subject<void>();

    private subscription: Subscription | null = null;

    /**
     *
     * @param action action to run after set time has elapsed
     * @param time time delay for the set action. default is 1 second.
     * @param repeat repeat action after the set time until user explicitly stops or repeat just once. default is true.
     * @param interval interval between first and subsequent emissions. default is interval = time.
     */
    constructor(
        action: (val: number) => void,
        time = 1000,
        repeat = true,
        interval = time
    ) {
        this._observable$ = timer(
            Math.min(
                Math.pow(2, 31) - 1,
                time
            ) /* Math.pow(2, 31) - 1 is the max timeout value */,
            Math.min(Math.pow(2, 31) - 1, interval)
        ).pipe(
            map((val) => {
                action(val);

                if (!repeat) {
                    this.stop();
                }
            }),
            takeUntil(this._stop$)
        );
    }

    start() {
        if (this.subscription) {
            return;
        }

        this.isStarted = true;
        this.subscription = this._observable$.subscribe();
    }

    pause() {
        if (!this.subscription) {
            return;
        }

        this.subscription.unsubscribe();
        this.subscription = null;
    }

    restart() {
        this.pause();
        this.start();
    }

    stop() {
        this._stop$.next();
        this._stop$.complete();

        this.isStarted = false;
    }
}
