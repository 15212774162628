import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../state/store';

import {
    getLearningObjectives,
    selectSearchParams,
} from '../../state/learning-objectives';

import { LearningObjective, LearningObjectiveType } from '../../types';
import { PageLayout } from '../shared';

import { AddEditForm } from './components';
import LearningObjectivesContent from './LearningObjectivesContent';

interface Props {}

const LearningObjectivesPage: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const searchParams = useAppSelector(selectSearchParams);

    const defaultInitialValues = {
        type: searchParams.type as LearningObjectiveType,
        learning_contents: [{ key: uuidv4(), title: '' }],
        content_domains: [{ reference: '' }],
    };

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [editingLearningObjective, setEditingLearningObjective] = useState<
        LearningObjective | { type: LearningObjectiveType }
    >(defaultInitialValues);

    const onAddObjective = () => {
        setShowDialog(true);
    };

    const onEditObjective = (learningObjective: LearningObjective) => {
        setEditingLearningObjective(learningObjective);
        setShowDialog(true);
    };

    const handleDialogClose = (
        _event?: unknown,
        _reason?: 'backdropClick' | 'escapeKeyDown',
        refetch?: boolean
    ) => {
        setShowDialog(false);
        if (refetch) {
            dispatch(getLearningObjectives(searchParams));
        }
        if ('id' in editingLearningObjective) {
            setEditingLearningObjective(defaultInitialValues);
        }
    };

    return (
        <PageLayout
            title="TSL Learning Objectives"
            buttons={[
                <Button
                    onClick={onAddObjective}
                    variant="contained"
                    color="secondary"
                    key="addLearningObjecive"
                >
                    Add a learning objective
                </Button>,
            ]}
        >
            <LearningObjectivesContent onEditObjective={onEditObjective} />
            {showDialog && (
                <AddEditForm
                    learningObjective={editingLearningObjective}
                    open={showDialog}
                    handleClose={handleDialogClose}
                />
            )}
        </PageLayout>
    );
};

export default LearningObjectivesPage;
