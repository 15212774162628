import { FC } from 'react';

import { Button } from '@thirdspacelearning/library';

import { User } from '../../types';
import { environment } from '../../environments/environment';

interface Props {
    user: User | null;
    logout: () => void;
}

const LogoutButton: FC<Props> = ({ user, logout }) => {
    if (user) {
        return (
            <Button color="primary" variant="outlined" onClick={logout}>
                Logout
            </Button>
        );
    }

    return (
        <Button
            color="primary"
            variant="outlined"
            href={`${
                environment.usersApiUrl
            }/login?redirect_to=${encodeURIComponent(window.location.href)}`}
        >
            Login
        </Button>
    );
};

export default LogoutButton;
