import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { authInitialState, authReducer } from '@tsl-frontend/tsl-auth';

import contentMaps, { contentMapsInitialState } from './content-maps/reducer';
import feedback, { feedbackInitialState } from './feedback/reducer';
import learningObjectives, {
    loInitialState,
} from './learning-objectives/reducer';
import learningContent, {
    learningContentInitialState,
} from './learning-objectives/learning-content/reducer';
import strands, { strandsInitialState } from './strands/reducer';
import years, { yearsInitialState } from './years/reducer';

import { environment } from '../environments/environment';

const rootReducers = combineReducers({
    contentMaps,
    feedback,
    learningContent,
    learningObjectives,
    strands,
    auth: authReducer,
    years,
});

export const appInitialState = {
    contentMaps: contentMapsInitialState,
    feedback: feedbackInitialState,
    learningContent: learningContentInitialState,
    learningObjectives: loInitialState,
    strands: strandsInitialState,
    auth: authInitialState,
    years: yearsInitialState,
};

export const store = configureStore({
    reducer: rootReducers,
    preloadedState: appInitialState,
    devTools: !environment.production,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunkMiddleware),
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
