import {
    GET_SLIDES,
    GET_SLIDES_SUCCESS,
    GET_SLIDES_FAILURE,
    ADD_SLIDE,
    ADD_SLIDE_SUCCESS,
    ADD_SLIDE_FAILURE,
    EDIT_SLIDE,
    EDIT_SLIDE_SUCCESS,
    EDIT_SLIDE_FAILURE,
    DELETE_SLIDE,
    DELETE_SLIDE_SUCCESS,
    DELETE_SLIDE_FAILURE,
    EDIT_LO_CONTENT,
    ADD_PPTX,
    ADD_PPTX_SUCCESS,
    ADD_PPTX_FAILURE,
    PPTX_PROCESSED,
} from './types';
import {
    getSlides as getSlidesAPI,
    createSlide as createSlideAPI,
    editSlide as editSlideAPI,
    createPptx as createPptxAPI,
    deleteSlide as deleteSlideAPI,
    SlidesParams,
    SlideValues,
    PptxValues,
} from '../../../api/learning-content';
import { showSnackbox } from '../../feedback';
import {
    Slide,
    AppDispatch,
    AppThunkResult,
    Errors,
    determineIfErrors,
} from '../../../types';

export const editLearningObjectiveContent = (
    learningObjectiveId: number | null
) => ({
    type: EDIT_LO_CONTENT,
    payload: learningObjectiveId,
});

// TODO move type somwhere sensible to be reused
export const getSlides = (
    learningObjectiveId: number,
    params?: SlidesParams
): AppThunkResult<Promise<void | Slide[] | Errors>> => {
    return (dispatch): Promise<Errors | void> => {
        dispatch(startGetSlides(params));

        return getSlidesAPI(learningObjectiveId, params).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(getSlidesFailure(res.error));
            } else {
                dispatch(getSlidesSuccess(learningObjectiveId, res as Slide[]));
            }
        });
    };
};

export const createSlide = (
    learningObjectiveId: number,
    values: SlideValues
): AppThunkResult<Promise<void | Slide | Errors>> => {
    return async (dispatch): Promise<Errors | void> => {
        dispatch(addSlide());
        const res = await createSlideAPI(learningObjectiveId, values);
        if (determineIfErrors(res)) {
            dispatch(addSlideFailure(res.error));
            return res;
        } else {
            dispatch(addSlideSuccess(learningObjectiveId, res as Slide));
            dispatch(showSnackbox('Slide successfully added'));
        }
    };
};

export const editSlide = (
    id: number,
    learningObjectiveId: number,
    values: SlideValues
): AppThunkResult<Promise<void | Slide | Errors>> => {
    return (dispatch: AppDispatch): Promise<Errors | void> => {
        dispatch(startEditSlide());

        return editSlideAPI(id, learningObjectiveId, values).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(editSlideFailure(res.error));
            } else {
                dispatch(editSlideSuccess(learningObjectiveId, res as Slide));
                dispatch(showSnackbox('Slide successfully updated'));
            }
        });
    };
};

export const removeSlide = (
    id: number,
    learningObjectiveId: number
): AppThunkResult<Promise<void | Errors>> => {
    return (dispatch): Promise<Errors | void> => {
        dispatch(deleteSlide());

        return deleteSlideAPI(id, learningObjectiveId).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(deleteSlideFailure(res.error));
                dispatch(showSnackbox('Slide deletion failed', true));
            } else {
                dispatch(deleteSlideSuccess(learningObjectiveId, id));
                dispatch(showSnackbox('Slide successfully removed'));
            }
        });
    };
};

export const createPptx = (
    learningObjectiveId: number,
    values: PptxValues
): AppThunkResult<Promise<void | Errors>> => {
    return (dispatch): Promise<Errors | void> => {
        dispatch(addPptx(learningObjectiveId));

        return createPptxAPI(learningObjectiveId, values).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(addPptxFailure(res.error));
            } else {
                dispatch(addPptxSuccess(learningObjectiveId));
                dispatch(showSnackbox('Pptx successfully uploaded'));
            }
        });
    };
};

export const getSlidesSuccess = (
    learningObjectiveId: number,
    slides: Slide[]
) => ({
    type: GET_SLIDES_SUCCESS,
    payload: { learningObjectiveId, slides },
});

export const getSlidesFailure = (error: string) => ({
    type: GET_SLIDES_FAILURE,
    payload: error,
});

export const startGetSlides = (params: any) => ({
    type: GET_SLIDES,
    payload: params,
});

export const addSlide = () => ({
    type: ADD_SLIDE,
});
export const addSlideSuccess = (learningObjectiveId: number, slide: Slide) => ({
    type: ADD_SLIDE_SUCCESS,
    payload: { learningObjectiveId, slide },
});
export const addSlideFailure = (error: string) => ({
    type: ADD_SLIDE_FAILURE,
    payload: error,
});
export const startEditSlide = () => ({
    type: EDIT_SLIDE,
});
export const editSlideSuccess = (
    learningObjectiveId: number,
    slide: Slide
) => ({
    type: EDIT_SLIDE_SUCCESS,
    payload: { learningObjectiveId, slide },
});
export const editSlideFailure = (error: string) => ({
    type: EDIT_SLIDE_FAILURE,
    payload: error,
});

export const deleteSlide = () => ({
    type: DELETE_SLIDE,
});
export const deleteSlideSuccess = (
    learningObjectiveId: number,
    slideId: number
) => ({
    type: DELETE_SLIDE_SUCCESS,
    payload: { learningObjectiveId, slideId },
});
export const deleteSlideFailure = (error: string) => ({
    type: DELETE_SLIDE_FAILURE,
    payload: error,
});

export const addPptx = (learningObjectiveId: number) => ({
    type: ADD_PPTX,
    payload: learningObjectiveId,
});
export const addPptxSuccess = (learningObjectiveId: number) => ({
    type: ADD_PPTX_SUCCESS,
    payload: learningObjectiveId,
});
export const addPptxFailure = (error: string) => ({
    type: ADD_PPTX_FAILURE,
    payload: error,
});
export const pptxProcessed = () => ({
    type: PPTX_PROCESSED,
});
