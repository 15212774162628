import { FC, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
    Box,
    Button,
    ConfirmationDialog,
    Container,
    Typography,
    Divider,
    Loading,
} from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../state/store';
import { getSingleLearningObjective } from '../../state/learning-objectives';
import {
    createPptx,
    editLearningObjectiveContent,
    selectLearningObjectiveById,
    selectPptxError,
    selectPptxProcessing,
} from '../../state/learning-objectives/learning-content';

import { PptxValues } from '../../api/learning-content';

import { PageLayout } from '../shared';
import { Dropzone, Processing } from './components';

interface Props {}

const AddLearningContentPage: FC<Props> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const learningObjective = useAppSelector(selectLearningObjectiveById);
    const pptxError = useAppSelector(selectPptxError);
    const pptxProcessing = useAppSelector(selectPptxProcessing);

    const { learningObjectiveId } = useParams<'learningObjectiveId'>();

    const [confirmationValues, setConfirmationValues] =
        useState<PptxValues | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);

    useEffect(() => {
        dispatch(editLearningObjectiveContent(Number(learningObjectiveId)));
    }, [learningObjectiveId, dispatch]);

    useEffect(() => {
        if (!learningObjective) {
            dispatch(getSingleLearningObjective(Number(learningObjectiveId)));
        }
    }, [learningObjective, dispatch, learningObjectiveId]);

    const onSubmit = (values: PptxValues) => {
        if (learningObjective) {
            if (learningObjective.slides_counter) {
                setConfirmationValues(values);
            } else {
                startProcessing(values);
            }
        }
    };

    const startProcessing = (values: PptxValues) => {
        dispatch(createPptx(Number(learningObjectiveId), values));
        setProcessing(true);
    };

    const onClickSaveSubmit = (event: MouseEvent<HTMLElement>) => {
        submit(event);
    };

    const onClickAddManual = () => {
        navigate(`/learning-content/${learningObjectiveId}`);
    };

    const onCancel = () => {
        navigate('/learning-objectives');
    };

    let submit: (
        event?: Partial<
            Pick<SyntheticEvent, 'preventDefault' | 'stopPropagation'>
        >
    ) => Promise<any | undefined> | undefined;

    return (
        <PageLayout
            title="Add Learning Content"
            buttons={[
                <Button
                    color="secondary"
                    key="manualAdd"
                    onClick={onClickAddManual}
                >
                    Add slides manually
                </Button>,
                <Button
                    variant="outlined"
                    color="primary"
                    key="cancel"
                    onClick={onCancel}
                >
                    Cancel
                </Button>,
                <Button
                    onClick={onClickSaveSubmit}
                    color="primary"
                    key="submit"
                    disabled={processing}
                >
                    Save and submit
                </Button>,
            ]}
        >
            {!learningObjective ? (
                <Loading />
            ) : (
                <Form
                    onSubmit={onSubmit}
                    initialValues={{}}
                    validate={(values) => {
                        const errors: { [key: string]: string | null } = {};
                        if (!values.pptx) {
                            errors.pptx = 'Please upload a file';
                        }
                        return errors;
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values,
                    }) => {
                        submit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Container maxWidth="sm">
                                    <Box pt={4}>
                                        <Box mb={2}>
                                            <Typography variant="body2">
                                                Adding content for learning
                                                objective:
                                            </Typography>
                                            <Typography variant="h6">
                                                {learningObjective.title}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        {processing ? (
                                            <Processing
                                                learningObjective={
                                                    learningObjective
                                                }
                                                processing={pptxProcessing}
                                                getLearningObjective={() => {
                                                    dispatch(
                                                        getSingleLearningObjective(
                                                            Number(
                                                                learningObjectiveId
                                                            )
                                                        )
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Field
                                                    name="pptx"
                                                    buttonLabel="Upload file (.pptx)"
                                                    showDetails
                                                >
                                                    {(props) => (
                                                        <Dropzone {...props} />
                                                    )}
                                                </Field>
                                                {pptxError && (
                                                    <Typography color="error">
                                                        {pptxError}
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                    {confirmationValues && (
                                        <ConfirmationDialog
                                            open={confirmationValues !== null}
                                            title="Do you wish to continue?"
                                            message="This will replace all slides and tutor notes for this learning objective."
                                            confirmLabel="Confirm"
                                            closeLabel="Cancel"
                                            onConfirm={() => {
                                                startProcessing(
                                                    confirmationValues
                                                );
                                                setConfirmationValues(null);
                                            }}
                                            onClose={() =>
                                                setConfirmationValues(null)
                                            }
                                        />
                                    )}
                                </Container>
                            </form>
                        );
                    }}
                />
            )}
        </PageLayout>
    );
};

export default AddLearningContentPage;
