import axios from 'axios';
import { User } from '../types';

export interface RefreshUserResponse {
    user: User;
    token: string;
    success: boolean;
    redirect_to: string;
}

export const refreshUser = (
    usersApiUrl: string
): Promise<RefreshUserResponse> => {
    return axios
        .get(`${usersApiUrl}/refresh`, {
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
};
