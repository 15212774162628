import { FC } from 'react';

import { Tag } from '@thirdspacelearning/library';

import {
    LearningObjective,
    isStandardType,
    isSatsType,
} from '../../../../types';

const sx = {
    tag: {
        maxWidth: '250px',
    },
};

interface Props {
    learningObjective: LearningObjective;
}

const ContentDomainTag: FC<Props> = ({ learningObjective }) => {
    if (
        isStandardType(learningObjective) &&
        !learningObjective.content_domain
    ) {
        return null;
    }

    if (isSatsType(learningObjective) && !learningObjective.strand) {
        return null;
    }

    const label = isStandardType(learningObjective)
        ? ((learningObjective.content_domain &&
              learningObjective.content_domain.reference) as string)
        : isSatsType(learningObjective)
        ? ((learningObjective.strand &&
              learningObjective.strand.title) as string)
        : '';

    return <Tag sx={sx.tag} label={label} type="info" />;
};

export default ContentDomainTag;
