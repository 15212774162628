import { Middleware } from '@reduxjs/toolkit';
import { loginUser, User } from '@tsl-frontend/tsl-auth';
import * as rudderanalytics from 'rudder-sdk-js';
import {
    addGlobalContext,
    customUserAction,
    groupTracking,
    pageChange,
} from '../actions';

interface IWindow extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
}

/**
 * We use semantic versioning for events, starting at 1.0.0.
 * The major version should be increased for breaking changes - renaming, removing etc.
 * The minor version should be used for basically anything else.
 */

const createUserObject = (user: User) => {
    const { platform_user_id } = user;

    return {
        userId: platform_user_id,
    };
};

export interface AppInfo {
    name: string;
    namespace: string;
    version: string;
    appVersion: string; // the version of the app, this should change every time the app changes it's event
}

export const Rudderstack = (
    appInfo: AppInfo,
    trackedEvents: string[],
    extraActionTypes: string[] = [],
    configuration: {
        rudderStackWriteKey: string;
        rudderStackDataPlane: string;
        rudderstackApiUrl: string;
    }
): Middleware => {
    const context: Record<string, any> = {
        app: appInfo,
        contextVersion: '2.0.0', // the version of the context should change every time these rpoperties are adjusted
    };

    if (
        configuration.rudderStackWriteKey &&
        configuration.rudderStackDataPlane &&
        !(
            (window as IWindow & typeof globalThis).Cypress ||
            process.env.JEST_WORKER_ID ||
            process.env.NODE_ENV === 'test'
        )
    ) {
        rudderanalytics.load(
            configuration.rudderStackWriteKey,
            configuration.rudderStackDataPlane,
            {
                configUrl: configuration.rudderstackApiUrl,
            }
        );
    }

    return (storeAPI) => {
        return (next) => {
            return (action) => {
                if (action.type === loginUser.type) {
                    rudderanalytics.reset(true);

                    const user = action.payload.user;
                    rudderanalytics.identify(
                        undefined,
                        createUserObject(user),
                        context,
                        undefined
                    );

                    const userRole =
                        user.memberships[0] && user.memberships[0].m_type;
                    context['userRole'] = userRole;
                }

                if (action.type === pageChange.toString()) {
                    const { path } = action.payload;
                    rudderanalytics.page(path, path, undefined, context);
                }

                if (action.type === addGlobalContext.toString()) {
                    const { key, value } = action.payload;
                    context[key] = value;
                }

                if (action.type === customUserAction.toString()) {
                    const { name, extraInfo } = action.payload;
                    if (trackedEvents.includes(name)) {
                        rudderanalytics.track(name, extraInfo, context);
                    }
                }

                if (action.type === groupTracking.toString()) {
                    const { schoolId } = action.payload;
                    rudderanalytics.group(schoolId, undefined, context);
                }

                if (extraActionTypes.includes(action.type)) {
                    rudderanalytics.track(action.type, action.payload, context);
                }

                return next(action);
            };
        };
    };
};
