import { createSelector } from 'reselect';
import {
    FeedbackState,
    FeedbackActionTypes,
    SHOW_SNACKBOX,
    HIDE_SNACKBOX,
} from './types';
import { RootState } from '../types';

export const feedbackInitialState: FeedbackState = {
    snackboxMessage: null,
    snackboxError: false,
};

const feedback = (
    state = feedbackInitialState,
    action: FeedbackActionTypes
): FeedbackState => {
    switch (action.type) {
        case SHOW_SNACKBOX:
            return {
                ...state,
                snackboxMessage: action.payload.message,
                snackboxError: action.payload.errorType,
            };
        case HIDE_SNACKBOX:
            return {
                ...state,
                snackboxMessage: null,
            };
        default:
            return state;
    }
};

const feedbackSelector = (state: RootState) => state.feedback;

export const selectSnackboxMessage = createSelector(
    feedbackSelector,
    (feedback: FeedbackState) => feedback.snackboxMessage
);

export const selectSnackboxErrorType = createSelector(
    feedbackSelector,
    (feedback: FeedbackState) => feedback.snackboxError
);

export default feedback;
