import React, { FC, useState } from 'react';
import { Button, ConfirmationDialog } from '@thirdspacelearning/library';

interface DeleteConfirmationDialogProps {
    onDelete: () => void;
    message: string;
}

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
    onDelete,
    message,
}) => {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setConfirmDelete(true)}
                color="secondary"
            >
                Delete
            </Button>
            {confirmDelete && (
                <ConfirmationDialog
                    title="Do you wish to continue?"
                    message={message}
                    confirmLabel="Confirm"
                    closeLabel="Cancel"
                    onConfirm={() => {
                        onDelete();
                        setConfirmDelete(false);
                    }}
                    onClose={() => setConfirmDelete(false)}
                    open={confirmDelete}
                />
            )}
        </>
    );
};

export default DeleteConfirmationDialog;
