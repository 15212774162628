import { Action } from 'redux';

import {
    GET_STRANDS,
    GET_STRANDS_SUCCESS,
    GET_STRANDS_FAILURE,
    StrandsState,
    ADD_STRAND,
    ADD_STRAND_SUCCESS,
    ADD_STRAND_FAILURE,
    EDIT_STRAND,
    EDIT_STRAND_SUCCESS,
    EDIT_STRAND_FAILURE,
    GET_SUBSTRANDS_SUCCESS,
    GET_SUBSTRANDS_FAILURE,
} from './types';

import {
    getStrands as getStrandsAPI,
    getSubStrands as getSubStrandsAPI,
    StrandsParams,
} from '../../api/strands';
import { Strand, SubStrand, AppThunkResult } from '../../types';

export const getStrands = (
    params: StrandsParams
): AppThunkResult<Promise<void | StrandsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(startGetStrands());
        return getStrandsAPI(params).then((res) => {
            if ('error' in res) {
                dispatch(getStrandsFailure(res.error));
            } else {
                dispatch(getStrandsSuccess(res));
            }
        });
    };
};

export const getSubStrands = (
    strandId: number
): AppThunkResult<Promise<void | StrandsState | Action<string>>> => {
    return (dispatch) => {
        return getSubStrandsAPI(strandId).then((res) => {
            if ('error' in res) {
                dispatch(getSubStrandsFailure(res.error));
            } else {
                dispatch(getSubStrandsSuccess(res as SubStrand[]));
            }
        });
    };
};

export const getStrandsSuccess = (strand: Strand[]) => ({
    type: GET_STRANDS_SUCCESS,
    payload: strand,
});

export const getStrandsFailure = (error: string) => ({
    type: GET_STRANDS_FAILURE,
    payload: error,
});

export const getSubStrandsSuccess = (substrands: SubStrand[]) => ({
    type: GET_SUBSTRANDS_SUCCESS,
    payload: substrands,
});

export const getSubStrandsFailure = (error: string) => ({
    type: GET_SUBSTRANDS_FAILURE,
    payload: error,
});

export const startGetStrands = () => ({
    type: GET_STRANDS,
});

export const addStrand = () => ({
    type: ADD_STRAND,
});
export const addStrandSuccess = (strand: Strand) => ({
    type: ADD_STRAND_SUCCESS,
    payload: strand,
});
export const addStrandFailure = (error: string) => ({
    type: ADD_STRAND_FAILURE,
    payload: error,
});
export const editStrand = () => ({
    type: EDIT_STRAND,
});
export const editStrandSuccess = (strand: Strand) => ({
    type: EDIT_STRAND_SUCCESS,
    payload: strand,
});
export const editStrandFailure = (error: string) => ({
    type: EDIT_STRAND_FAILURE,
    payload: error,
});
