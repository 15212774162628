import { MembershipType, User } from '../types';

export const determineIsStudent = (user: User | null) =>
    user &&
    user.memberships[0] &&
    user.memberships[0].m_type === MembershipType.STUDENT;

export const determineIsTutor = (user: User | null) =>
    user &&
    user.memberships[0] &&
    user.memberships[0].m_type === MembershipType.TUTOR;

export const hasMembershipRole = (
    user: User | null,
    membershipRole: MembershipType
) =>
    user &&
    user.memberships[0] &&
    user.memberships[0].m_type === membershipRole;

export const getUserRole = (user: User) =>
    user && user.memberships[0] && user.memberships[0].m_type;

export const authLogout = (
    redirect: boolean,
    redirectURL: string = window.location.href,
    platformCookieName: string,
    usersApiUrl: string
) => {
    document.cookie = `${platformCookieName}=; domain=.thirdspacelearning.com;expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/;`;

    if (redirect) {
        window.location.href = `${usersApiUrl}/login?redirect_to=${encodeURIComponent(
            redirectURL
        )}`;
    }
};
