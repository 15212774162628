import { ReactNode } from 'react';

import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import 'react-reflex/styles.css';

interface SlidePreviewLayoutProps {
    left: ReactNode;
    right: ReactNode;
}

const SlideContentLayout = ({ left, right }: SlidePreviewLayoutProps) => {
    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement minSize={400}>
                <ReflexContainer orientation="horizontal">
                    {left}
                </ReflexContainer>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement minSize={500}>{right}</ReflexElement>
        </ReflexContainer>
    );
};

export default SlideContentLayout;
