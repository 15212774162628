import { FC, useState } from 'react';

import { Circle, Delete } from '@mui/icons-material';

import {
    Box,
    Button,
    Chip,
    DataGrid,
    GridColDef,
    GridSortModel,
    IconButton,
    Tooltip,
    TSLLink,
    Typography,
} from '@thirdspacelearning/library';

import { LOsType } from '../../types';
import { yearGroupSortComparator } from '../../utils';

const sx = {
    icon: { width: '10px', height: '10px', mr: 0.5 },
};

type Props = {
    los: LOsType[];
};

const ProgrammeTable: FC<Props> = ({ los }) => {
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'position',
            sort: 'asc',
        },
    ]);

    const columns: GridColDef<LOsType>[] = [
        {
            field: 'programme_position',
            type: 'string',
            headerName: 'Order',
            headerAlign: 'left',
            align: 'left',
            sortingOrder: ['asc', 'desc'],
            sortable: true,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: 'title',
            type: 'string',
            headerName: 'Learning objective title',
            renderCell: ({ row }) => (
                <Tooltip
                    title={row.title}
                    enterDelay={500}
                    disableInteractive
                    arrow
                >
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <TSLLink
                            to={`/learning-content/${row.id}`}
                            variant="body2"
                            data-testid="programme-link"
                        >
                            {row.title}
                        </TSLLink>
                    </Box>
                </Tooltip>
            ),
            sortable: true,
            minWidth: 250,
            flex: 1.5,
        },
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            sortable: true,
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'active',
            type: 'string',
            headerName: 'Status',
            renderCell: ({ row }) =>
                row.active ? (
                    <Typography color="success.main" variant="body2">
                        <Circle color="success" sx={sx.icon} />
                        Active
                    </Typography>
                ) : (
                    <Typography color="warning.main" variant="body2">
                        <Circle color="warning" sx={sx.icon} />
                        Inactive
                    </Typography>
                ),
            sortable: true,
            minWidth: 110,
            flex: 0.5,
        },
        {
            field: 'yearGroup',
            type: 'string',
            headerName: 'Year group',
            sortingOrder: ['asc', 'desc'],
            sortComparator: yearGroupSortComparator,
            renderCell: ({ row }) =>
                row.year_id ? (
                    <Chip
                        label={`Year ${row.year_id}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'slides_counter',
            type: 'string',
            headerName: 'Slide count',
            valueGetter: ({ row }) => `${row.slides_counter} slides`,
            sortable: true,
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'preview',
            headerName: '',
            renderCell: () => (
                <Button variant="outlined" color="primary">
                    Preview
                </Button>
            ),
            sortable: false,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            field: 'remove',
            headerName: '',
            renderCell: () => (
                <Tooltip title="Remove LO" disableInteractive arrow>
                    <Box>
                        <IconButton color="error">
                            <Delete />
                        </IconButton>
                    </Box>
                </Tooltip>
            ),
            sortable: false,
            minWidth: 60,
            maxWidth: 60,
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={los}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            disableColumnMenu
            disableColumnSelector
            disableColumnFilter
            disableDensitySelector
            disableRowSelectionOnClick
            hideFooter
        />
    );
};

export default ProgrammeTable;
