import { FC } from 'react';
import { Field } from 'react-final-form';

import { Autocomplete } from '@thirdspacelearning/library';

import { LearningContents } from '../../../../types';

interface LearningContentsFieldProps {
    learningContents: LearningContents[];
    disabled?: boolean;
}

const LearningContentsField: FC<LearningContentsFieldProps> = ({
    learningContents,
    disabled,
}) => (
    <Field
        name="learning_content.id"
        component={Autocomplete}
        options={learningContents.map((lc) => ({
            value: lc.id,
            label: lc.title,
        }))}
        variant="outlined"
        color="primary"
        disabled={disabled}
        fullWidth
    />
);

export default LearningContentsField;
