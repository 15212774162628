import Axios from 'axios';
import { Store } from 'redux';

import { logoutUser } from './authSlice';

const interceptor = (store: Store) => {
    Axios.interceptors.response.use(
        (next) => {
            return Promise.resolve(next);
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                store.dispatch({
                    type: logoutUser.type,
                });
            }
            return Promise.reject(error);
        }
    );
};
export default interceptor;
