import { FC } from 'react';

import { ActionPanel, Box } from '@thirdspacelearning/library';

import errorSvg from '../../../assets/placeholder.svg';
import { useNavigate } from 'react-router-dom';

const sx = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
};

const PlaceholderActionPanel: FC = () => {
    const navigate = useNavigate();

    const onNavigation = () => {
        navigate('/');
    };

    return (
        <Box sx={sx.actionPanel}>
            <ActionPanel
                buttonAction={onNavigation}
                buttonLabel="Return To Home"
                decoration={<img src={errorSvg} alt="An error occurred" />}
                title="We are working on improving the Curriculum CMS"
                textContent="Come back soon to see the new page!"
            />
        </Box>
    );
};

export default PlaceholderActionPanel;
