import axios from 'axios';
import { Year } from '../types';

import { environment } from '../environments/environment';

export interface YearsParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
}

export interface getYearsError {
    error: string;
}

export const getYears = async (
    params: YearsParams = {}
): Promise<Year[] | getYearsError> => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/years`, {
            params,
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
