import {
    LearningObjective,
    SATsLearningObjective,
    SearchParams,
} from '../../types';

export const GET_LEARNING_OBJECTIVES = 'GET_LEARNING_OBJECTIVES';
export const GET_LEARNING_OBJECTIVES_SUCCESS =
    'GET_LEARNING_OBJECTIVES_SUCCESS';
export const GET_LEARNING_OBJECTIVES_FAILURE =
    'GET_LEARNING_OBJECTIVES_FAILURE';
export const GET_REVISION_LEARNING_OBJECTIVE =
    'GET_REVISION_LEARNING_OBJECTIVE';
export const GET_REVISION_LEARNING_OBJECTIVE_SUCCESS =
    'GET_REVISION_LEARNING_OBJECTIVE_SUCCESS';
export const GET_REVISION_LEARNING_OBJECTIVE_FAILURE =
    'GET_REVISION_LEARNING_OBJECTIVE_FAILURE';
export const SWAP_REVISION_ORDER = 'SWAP_REVISION_ORDER';
export const SWAP_REVISION_ORDER_SUCCESS = 'SWAP_REVISION_ORDER_SUCCESS';
export const SWAP_REVISION_ORDER_FAILURE = 'SWAP_REVISION_ORDER_FAILURE';
export const GET_SINGLE_LEARNING_OBJECTIVE = 'GET_SINGLE_LEARNING_OBJECTIVE';
export const GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS =
    'GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS';
export const GET_SINGLE_LEARNING_OBJECTIVE_FAILURE =
    'GET_SINGLE_LEARNING_OBJECTIVE_FAILURE';
export const ADD_LEARNING_OBJECTIVE = 'ADD_LEARNING_OBJECTIVE';
export const ADD_LEARNING_OBJECTIVE_SUCCESS = 'ADD_LEARNING_OBJECTIVE_SUCCESS';
export const ADD_LEARNING_OBJECTIVE_FAILURE = 'ADD_LEARNING_OBJECTIVE_FAILURE';
export const EDIT_LEARNING_OBJECTIVE = 'EDIT_LEARNING_OBJECTIVE';
export const EDIT_LEARNING_OBJECTIVE_SUCCESS =
    'EDIT_LEARNING_OBJECTIVE_SUCCESS';
export const EDIT_LEARNING_OBJECTIVE_FAILURE =
    'EDIT_LEARNING_OBJECTIVE_FAILURE';
export const RESET_SUBMIT = 'RESET_SUBMIT';

export interface GetLearningObjectivesAction {
    type: typeof GET_LEARNING_OBJECTIVES;
    payload: any;
}

export interface GetLearningObjectivesSuccessAction {
    type: typeof GET_LEARNING_OBJECTIVES_SUCCESS;
    payload: { learningObjectives: LearningObjective[]; totalCount: number };
}

export interface GetLearningObjectivesFailureAction {
    type: typeof GET_LEARNING_OBJECTIVES_FAILURE;
    payload: string;
}

export interface GetSingleLearningObjectiveAction {
    type: typeof GET_SINGLE_LEARNING_OBJECTIVE;
    payload: number;
}

export interface GetSingleLearningObjectiveSuccessAction {
    type: typeof GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS;
    payload: LearningObjective;
}

export interface GetSingleLearningObjectiveFailureAction {
    type: typeof GET_SINGLE_LEARNING_OBJECTIVE_FAILURE;
    payload: string;
}

export interface AddLearningObjectiveAction {
    type: typeof ADD_LEARNING_OBJECTIVE;
    payload: undefined;
}

export interface AddLearningObjectiveSuccessAction {
    type: typeof ADD_LEARNING_OBJECTIVE_SUCCESS;
    payload: LearningObjective;
}

export interface AddLearningObjectiveFailureAction {
    type: typeof ADD_LEARNING_OBJECTIVE_FAILURE;
    payload: string;
}

export interface EditLearningObjectiveAction {
    type: typeof EDIT_LEARNING_OBJECTIVE;
    payload: undefined;
}

export interface EditLearningObjectiveSuccessAction {
    type: typeof EDIT_LEARNING_OBJECTIVE_SUCCESS;
    payload: LearningObjective;
}

export interface EditLearningObjectiveFailureAction {
    type: typeof EDIT_LEARNING_OBJECTIVE_FAILURE;
    payload: string;
}

export interface ResetSubmitAction {
    type: typeof RESET_SUBMIT;
    payload: undefined;
}

export interface GetSATsLearningObjectivesAction {
    type: typeof GET_REVISION_LEARNING_OBJECTIVE;
    payload: undefined;
}

export interface GetSATsLearningObjectiveSuccessAction {
    type: typeof GET_REVISION_LEARNING_OBJECTIVE_SUCCESS;
    payload: SATsLearningObjective[];
}

export interface GetSATsLearningObjectiveFailureAction {
    type: typeof GET_REVISION_LEARNING_OBJECTIVE_FAILURE;
    payload: string;
}

export interface SwapRevisionOrderAction {
    type: typeof SWAP_REVISION_ORDER;
    payload: undefined;
}

export interface SwapRevisionOrderSuccessAction {
    type: typeof SWAP_REVISION_ORDER_SUCCESS;
    payload: { newPosition: number; oldPosition: number };
}

export interface SwapRevisionOrderFailureAction {
    type: typeof SWAP_REVISION_ORDER_FAILURE;
    payload: string;
}

export type LearningObjectivesActionTypes =
    | GetLearningObjectivesAction
    | GetLearningObjectivesSuccessAction
    | GetLearningObjectivesFailureAction
    | GetSingleLearningObjectiveAction
    | GetSingleLearningObjectiveSuccessAction
    | GetSingleLearningObjectiveFailureAction
    | GetSATsLearningObjectivesAction
    | GetSATsLearningObjectiveSuccessAction
    | GetSATsLearningObjectiveFailureAction
    | SwapRevisionOrderAction
    | SwapRevisionOrderSuccessAction
    | SwapRevisionOrderFailureAction
    | AddLearningObjectiveAction
    | AddLearningObjectiveSuccessAction
    | AddLearningObjectiveFailureAction
    | EditLearningObjectiveAction
    | EditLearningObjectiveSuccessAction
    | EditLearningObjectiveFailureAction
    | ResetSubmitAction;

export interface LearningObjectivesState {
    searchedLearningObjectives: LearningObjective[] | null;
    revisionLearningObjectives: SATsLearningObjective[] | null;
    searching: boolean;
    searchParams: SearchParams;
    tableParams: { per_page: number; page: number; totalCount: number };
    error: string | null;
    revisionLearningObjectivesError: string | null;
    allLearningObjectives: { [title: string]: LearningObjective };
    changeSubmit: {
        submitting: boolean;
        success: boolean;
        error: string | null;
    };
}
