import { FC } from 'react';
import { Field } from 'react-final-form';

import { RadioFinalField, RadioGroup } from '@thirdspacelearning/library';

import { stepsToSuccess } from '../../../../utils';

import { LearningObjective, SlideType } from '../../../../types';

interface ConceptPracticeApplicationProps {
    learningObjective: LearningObjective;
}

const ConceptPracticeApplication: FC<ConceptPracticeApplicationProps> = ({
    learningObjective,
}) => (
    <RadioGroup>
        <Field
            name="slide_type"
            label={stepsToSuccess(SlideType.CONCEPT, learningObjective)}
            component={RadioFinalField}
            type="radio"
            value="concept"
            size="small"
            color="primary"
        />
        <Field
            name="slide_type"
            label={stepsToSuccess(SlideType.PRACTICE, learningObjective)}
            component={RadioFinalField}
            type="radio"
            value="practice"
            size="small"
            color="primary"
        />
        <Field
            name="slide_type"
            label={stepsToSuccess(SlideType.APPLICATION, learningObjective)}
            component={RadioFinalField}
            type="radio"
            value="application"
            size="small"
            color="primary"
        />
    </RadioGroup>
);

export default ConceptPracticeApplication;
