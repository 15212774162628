import { FC } from 'react';

import { PageLayout } from '../shared';
import { LearningObjectiveType } from '../../types';

import { RevisionLearningMap } from './components';

interface Props {}

const SatsLearningMapsPage: FC<Props> = () => {
    return (
        <PageLayout title="Programme type: Revision">
            <RevisionLearningMap
                learningObjectiveType={LearningObjectiveType.SATS}
            />
        </PageLayout>
    );
};

export default SatsLearningMapsPage;
