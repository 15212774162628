import { FC, PropsWithChildren } from 'react';
import { Field } from 'react-final-form';

interface Props {
    when: string;
    is: string | string[];
}

const Condition: FC<PropsWithChildren<Props>> = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {Array.isArray(is)
            ? ({ input: { value } }) => (is.includes(value) ? children : null)
            : ({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

export default Condition;
