import axios from 'axios';
import { Strand, SubStrand } from '../types';

import { environment } from '../environments/environment';

export interface StrandsParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
}

export interface getStrandsError {
    error: string;
}

export const getStrands = async (
    params: StrandsParams = {}
): Promise<Strand[] | getStrandsError> => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/strands`, {
            params,
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export interface SubStrandsParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
}

export interface getSubStrandsError {
    error: string;
}

export const getSubStrands = async (
    strandId: number,
    params: SubStrandsParams = {}
): Promise<SubStrand[] | getStrandsError> => {
    return axios
        .get(
            `${environment.curriculumCmsApiUrl}/strands/${strandId}/substrands`,
            {
                params,
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
