import { ReactElement, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { authSelector, loginUser, logoutUser, useAuthFlow } from './';
import { User } from '../types';

interface Props {
    platformCookieName: string;
    usersApiUrl: string;
    children: ReactElement;
}

const AuthLoader = ({ platformCookieName, usersApiUrl, children }: Props) => {
    const dispatch = useDispatch();

    const { user, unauthorized } = useSelector(authSelector);

    const onLogin = useCallback(
        (user: User) => dispatch(loginUser({ user })),
        [dispatch]
    );

    const onLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);

    const [logout] = useAuthFlow(
        onLogin,
        onLogout,
        true,
        window.location.href,
        {
            platformCookieName,
            usersApiUrl,
        }
    );

    useEffect(() => {
        if (unauthorized) {
            logout();
        }
    }, [unauthorized, logout]);

    if (!user) {
        return null;
    }

    return children;
};

export default AuthLoader;
