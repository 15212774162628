import { FC, MouseEvent } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@thirdspacelearning/library';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    contentText: string;
    onCancel: (reason?: 'backdropClick' | 'escapeKeyDown') => void;
    onProceed: () => void;
}
const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    open,
    title,
    contentText,
    onCancel,
    onProceed,
}) => {
    const handleCancel = (
        _event: MouseEvent<HTMLElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => {
        onCancel(reason);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleCancel}
                >
                    No, cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onProceed}
                    autoFocus
                >
                    Yes, proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
