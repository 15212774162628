import { FC, useEffect, useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';

import {
    Theme,
    Box,
    Paper,
    Autocomplete,
    TextFinalField,
    Button,
    Icons,
} from '@thirdspacelearning/library';

import { getContentDomains } from '../../../../../api/content-domains';

import { ContentDomain, LearningObjective } from '../../../../../types';

const sx = {
    strand: (theme: Theme) => ({
        padding: theme.spacing(0, 2),
    }),
    arrayWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: 2,
        marginBottom: 1,
        '& .MuiFormControl-root': {
            marginTop: 1,
            marginBottom: 0,
        },
    },
    buttonPad: {
        marginTop: 1,
    },
    gap: {
        marginRight: 1,
    },
};

interface Props {
    push: (name: string, value: any) => void;
    contentDomains: ContentDomain[];
    setContentDomains: (cd: null | ContentDomain[]) => void;
    learningObjective: LearningObjective | { type: string };
}

const MultipleContentDomainSection: FC<Props> = ({
    push,
    contentDomains,
    setContentDomains,
    learningObjective,
}) => {
    const contentDomainRefs =
        'content_domains' in learningObjective
            ? learningObjective.content_domains.map((cd) => cd.reference)
            : null;

    const strands = contentDomains.map((cd) => cd.strand);
    // @ts-ignore
    const uniqueIds = [...new Set(strands.map((strand) => strand.id))];
    const uniqueStrandOptions = uniqueIds.map((id) => {
        const strand = strands.find((strand) => strand.id === id);
        if (strand) {
            return {
                label: strand.title,
                value: strand.id,
            };
        }

        return {};
    });

    const fetchContentDomain = useCallback(
        async (references: string[]) => {
            return getContentDomains({
                reference: references.join(','),
            }).then((contentDomains: ContentDomain[]) => {
                setContentDomains(contentDomains);
            });
        },
        [setContentDomains]
    );

    useEffect(() => {
        if (contentDomainRefs && contentDomainRefs.length) {
            fetchContentDomain(contentDomainRefs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchContentDomain]);

    return (
        <Paper variant="outlined">
            <Box sx={sx.arrayWrapper}>
                <FieldArray name="content_domains">
                    {({ fields }) => {
                        return fields.map((name, index) => (
                            <Box key={name} sx={sx.gap}>
                                <Field
                                    name={`${name}.reference`}
                                    label={`CDR ${index + 1}`}
                                    variant="outlined"
                                    color="primary"
                                    component={TextFinalField}
                                    multiline
                                    className="noMargin"
                                    key={name}
                                    autoFocus={index + 1 === fields.length}
                                    required
                                />
                            </Box>
                        ));
                    }}
                </FieldArray>
                <OnChange name="content_domains">
                    {(value: { reference: string }[], _previous: string[]) => {
                        fetchContentDomain(value.map((cd) => cd.reference));
                    }}
                </OnChange>
                <Button
                    variant="contained"
                    color="primary"
                    sx={sx.buttonPad}
                    onClick={() => {
                        push('content_domains', {});
                    }}
                >
                    <Icons.Add />
                </Button>
            </Box>
            <Field
                name="strand.id"
                component={Autocomplete}
                options={uniqueStrandOptions}
                variant="outlined"
                label="Strand"
                color="primary"
                className="noMargin"
                sx={sx.strand}
                //   classes={{ root: classes.strand }}
            />
        </Paper>
    );
};

export default MultipleContentDomainSection;
