import axios from 'axios';

import { environment } from '../environments/environment';

export const getProgrammes = async () => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/programmes`, {
            withCredentials: true,
            params: {
                fields: 'id,title,key_stage,year_id,created_at,updated_at,status',
            },
        })
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Something went wrong loading the programmes.');
        });
};

export const getProgramme = async (programmeId: string | undefined) => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/programmes/${programmeId}`, {
            withCredentials: true,
        })
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Something went wrong loading the programme.');
        });
};
