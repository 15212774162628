import axios from 'axios';
import { Slide, SlideType, Errors, LearningContents } from '../types';

import { environment } from '../environments/environment';

export interface SlidesParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
    sort?: string;
}

export interface SlideError {
    error: string;
}

export const getSlides = async (
    learningObjectiveId: number,
    params: SlidesParams = {}
): Promise<Slide[] | Errors> => {
    return axios
        .get(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}/slides`,
            {
                params,
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export interface SlideValues {
    tutor_notes?: string;
    active?: boolean;
    image_path?: { name: string; base64: string };
    components_path?: { name: string; base64: string };
    name?: string;
    slide_type?: SlideType;
    position?: number;
    learning_content?: LearningContents;
}

export const createSlide = async (
    learningObjectiveId: number,
    values: SlideValues
): Promise<Slide | Errors> => {
    const data = {
        slide: {
            tutor_notes: values.tutor_notes,
            active: values.active,
            content: values.image_path && values.image_path.base64,
            slide_type: values.slide_type,
            position: Number(values.position),
            name: values.image_path && values.image_path.name,
            learning_content_id:
                values.learning_content && values.learning_content.id,
            components_file:
                values.components_path && values.components_path.base64,
        },
    };
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}/slides`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            let errors: Errors = { error: errorMessage };

            if (error.response && error.response.status === 422) {
                errors = { ...errors, ...error.response.data };
            }
            return errors;
        });
};

export const editSlide = async (
    id: number,
    learningObjectiveId: number,
    values: SlideValues
): Promise<Slide | Errors> => {
    const data = {
        slide: {
            tutor_notes: values.tutor_notes,
            active: values.active,
            content: values.image_path && values.image_path.base64,
            slide_type: values.slide_type,
            position: Number(values.position),
            name: values.image_path && values.image_path.name,
            learning_content_id:
                values.learning_content && values.learning_content.id,
            components_file:
                values.components_path && values.components_path.base64,
        },
    };

    return axios
        .put(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}/slides/${id}`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            let errors: Errors = { error: errorMessage };

            if (error.response && error.response.status === 422) {
                errors = { ...errors, ...error.response.data };
            }
            return errors;
        });
};

export const deleteSlide = async (
    id: number,
    learningObjectiveId: number
): Promise<Errors | void> => {
    return axios
        .delete(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}/slides/${id}`,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export interface PptxValues {
    pptx?: { name: string; base64: string };
}

export const createPptx = async (
    learningObjectiveId: number,
    values: PptxValues
): Promise<Errors> => {
    const data = {
        pptx: {
            content: values.pptx && values.pptx.base64,
            name: values.pptx && values.pptx.name,
        },
    };
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/learning_objectives/${learningObjectiveId}/pptx`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage =
                "We're sorry, we were unable to upload your file";
            return { error: errorMessage };
        });
};
