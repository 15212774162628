import { FC, ReactNode } from 'react';

import {
    Box,
    Paper,
    Grid,
    Typography,
    Theme,
} from '@thirdspacelearning/library';

const sx = {
    paper: (theme: Theme) => ({
        margin: theme.spacing(0, 2),
        padding: 2,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    }),
    titleWrapper: {
        marginBottom: 1,
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    buttonSpacing: {
        marginRight: 1,
    },
};

interface Props {
    title: string;
    children: ReactNode;
    buttons?: ReactNode[];
}

const PageLayout: FC<Props> = ({ buttons = [], title, children }) => (
    <Paper elevation={0} sx={sx.paper}>
        <Grid container sx={sx.titleWrapper}>
            <Grid item>
                <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item sx={sx.buttonWrapper}>
                {buttons.map((button, index) => (
                    <Box
                        key={index}
                        sx={[index !== buttons.length - 1 && sx.buttonSpacing]}
                    >
                        {button}
                    </Box>
                ))}
            </Grid>
        </Grid>
        {children}
    </Paper>
);

export default PageLayout;
