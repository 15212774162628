export type ProgrammeType = {
    id: number;
    title: string;
    description: string;
    key_stage: number;
    year_id: number;
    created_at: string;
    published_at: string;
    status: ProgrammeStatus;
    learning_objectives: LOsType[];
};

export enum ProgrammeStatus {
    LIVE = 'live',
    ARCHIVED = 'archived',
    // TESTING = 'testing', TODO: temporarily disabled
    DRAFT = 'draft',
}

export type LOsType = {
    id: number;
    title: string;
    programme_position: number;
    year_id: number;
    active: boolean;
    slides_counter: number;
};
