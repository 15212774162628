function save<T = Record<string, unknown>>(key: string, obj: T): boolean {
    if (!localStorage) {
        return false;
    }

    try {
        const serializedObj = JSON.stringify(obj);
        localStorage.setItem(key, serializedObj);
        return true;
    } catch (error) {
        throw new Error(`serialization failed for ${key}`);
    }
}

function load<T = Record<string, unknown>>(key: string): T | undefined {
    if (!localStorage) {
        return;
    }

    try {
        const serializedObj = localStorage.getItem(key);
        if (serializedObj == null) {
            return;
        }
        return JSON.parse(serializedObj);
    } catch (error) {
        throw new Error(`deserialization failed for ${key}`);
    }
}

function remove(key: string) {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(key);
    } catch (error) {
        throw new Error(`removing failed for ${key}`);
    }
}

function clear() {
    if (!localStorage) {
        return;
    }

    localStorage.clear();
}

export default {
    save,
    load,
    remove,
    clear,
};
