import { FC, useState, useEffect } from 'react';

import { Loading } from '@thirdspacelearning/library';

import { ConfirmationDialog } from '../../shared';

import { Slide, LearningObjective } from '../../../types';
import { localStorage } from '../../../utils';

import { SlideView, SlideEdit } from './SlidePreviewContent';
import { ViewType } from '../SlidesPreviewDialog';

interface Props {
    onClose: () => void;
    fullScreen: boolean;
    slides?: Slide[];
    learningObjectiveId?: number;
    viewType: ViewType;
    editable?: boolean;
    learningObjective: LearningObjective;
    currentSlideIndex: number;
    setCurrentSlideIndex: (slideIndex: number) => void;
    setSlideEdited: (edited: boolean) => void;
    enableEdit: () => void;
}

const SlidesPreview: FC<Props> = ({
    onClose,
    fullScreen,
    slides,
    learningObjectiveId,
    viewType,
    editable,
    learningObjective,
    currentSlideIndex,
    enableEdit,
    setSlideEdited,
}) => {
    const [loadingSlide, setLoadingSlide] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [currentSlide, setCurrentSlide] = useState<Slide>();
    const [openUnsavedTutorNotesDialog, setOpenUnsavedTutorNotesDialog] =
        useState(false);

    const closeUnsavedTutorNotesDialog = (
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (reason) {
            return;
        }

        localStorage.remove(`slide-${currentSlide!.id}`);
        setOpenUnsavedTutorNotesDialog(false);
    };

    const changeTutorNotes = (tutorNotes: string | undefined, slide: Slide) => {
        const newSlide = { ...slide, tutor_notes: tutorNotes ?? '' };

        setHasUnsavedChanges(true);
        setCurrentSlide(newSlide);
    };

    const setTutorNotesFromLocalStorage = () => {
        if (!currentSlide) {
            return;
        }

        const tutorNotes = localStorage.load<string>(
            `slide-${currentSlide.id}`
        );

        setOpenUnsavedTutorNotesDialog(false);
        changeTutorNotes(tutorNotes, currentSlide);
    };

    const checkIfSlideHasUnsavedChanges = (slide: Slide) => {
        if (!slide) {
            return;
        }

        const tutorNotes = localStorage.load<string>(`slide-${slide.id}`);
        if (tutorNotes && slide.tutor_notes !== tutorNotes) {
            setOpenUnsavedTutorNotesDialog(true);
        }
    };

    useEffect(() => {
        if (!slides) {
            setLoadingSlide(true);
            return;
        }

        setLoadingSlide(false);
        setCurrentSlide(slides[currentSlideIndex]);
        checkIfSlideHasUnsavedChanges(slides[currentSlideIndex]);
    }, [slides, currentSlideIndex]);

    if (loadingSlide) {
        return <Loading />;
    }

    return (
        <>
            {viewType === ViewType.VIEW && currentSlide ? (
                <SlideView
                    onEdit={enableEdit}
                    currentSlide={currentSlide}
                    editable={!!editable}
                    learningObjective={learningObjective}
                    fullScreen={fullScreen}
                />
            ) : (
                <SlideEdit
                    learningObjectiveId={learningObjectiveId}
                    slide={currentSlide}
                    onClose={onClose}
                    learningObjective={learningObjective}
                    fullScreen={fullScreen}
                    hasUnsavedChanges={hasUnsavedChanges}
                    setSlideEdited={setSlideEdited}
                    changeTutorNotes={changeTutorNotes}
                />
            )}
            <ConfirmationDialog
                open={openUnsavedTutorNotesDialog}
                title="You have unsaved changes"
                contentText="It appears you have unsaved changes to this slide. Do you wish to load them?"
                onCancel={closeUnsavedTutorNotesDialog}
                onProceed={setTutorNotesFromLocalStorage}
            />
        </>
    );
};

export default SlidesPreview;
