import { FC } from 'react';

import { Box, Typography, Theme } from '@thirdspacelearning/library';

import { ImpactType } from '../../../../types';

import { capitalise } from '../../../../utils';

const sx = {
    wrapper: {
        display: 'flex',
        border: 1,
        borderColor: 'primary.dark',
        marginRight: 1,
    },
    impact: (theme: Theme) => ({
        display: 'inline-block',
        backgroundColor: 'primary.dark',
        color: 'common.white',
        padding: theme.spacing(0, 1),
    }),
    level: (theme: Theme) => ({
        display: 'inline-block',
        padding: theme.spacing(0, 1),
        color: 'primary.dark',
    }),
};

interface Props {
    impact: ImpactType;
}

const ImpactTag: FC<Props> = ({ impact }) => (
    <Box sx={sx.wrapper}>
        <Typography sx={sx.impact}>Impact</Typography>
        <Typography sx={sx.level}>{capitalise(impact)}</Typography>
    </Box>
);

export default ImpactTag;
