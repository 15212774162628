import { FC, useCallback, useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import debounce from 'lodash.debounce';

import {
    Box,
    Button,
    CardContent,
    Typography,
} from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../../../state/store';
import { isHTMLWhiteSpace, localStorage } from '../../../../utils';

import { createSlide, editSlide, removeSlide } from '../../../../state/actions';
import {
    AppDispatch,
    determineIfErrors,
    Errors,
    isStandardType,
    LearningObjective,
    Slide,
} from '../../../../types';

import { DeleteConfirmationDialog, QuillAdapter } from '../../../shared';

import Dropzone from '../Dropzone';
import SlideContentLayout from './SlideContentLayout';
import ConceptPracticeApplication from './ConceptPracticeApplication';
import LearningContentsField from './LearningContentsField';
import NotesTemplatePicker from './NotesTemplatePicker';

import { Template } from './templates';
import { selectSearchParams } from './../../../../state/learning-objectives';

const sx = {
    rightPanel: {
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'auto',
        flexGrow: 1,
    },
    formWrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'relative',
        flexGrow: 1,
        '> form': {
            width: '100%',
        },
    },
};

interface Props {
    learningObjectiveId?: number;
    slide?: Slide;
    learningObjective: LearningObjective;
    fullScreen: boolean;
    hasUnsavedChanges: boolean;
    onClose: () => void;
    setSlideEdited: (edited: boolean) => void;
    changeTutorNotes: (tutorNotes: string, slide: Slide) => void;
}

const SlideEdit: FC<Props> = ({
    learningObjectiveId,
    slide = {} as Slide,
    learningObjective,
    fullScreen,
    hasUnsavedChanges,
    onClose,
    setSlideEdited,
    changeTutorNotes,
}) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [unsavedChanges, setUnsavedChanges] = useState(hasUnsavedChanges);
    const searchParams = useAppSelector(selectSearchParams);

    const onSubmit = async (
        values: never
    ): Promise<{ [FORM_ERROR]: string } | void> => {
        if (learningObjectiveId) {
            if (slide && slide.id) {
                setSlideEdited(false);
                setUnsavedChanges(false);
                localStorage.remove(`slide-${slide.id}`);

                const result = await dispatch(
                    editSlide(slide.id, learningObjectiveId, values)
                );

                if (determineIfErrors(result)) {
                    return handleFormErrors(result);
                }

                return;
            }
            const result = await dispatch(
                createSlide(learningObjectiveId, values)
            );

            if (determineIfErrors(result)) {
                return handleFormErrors(result);
            } else {
                onClose();
            }
        }
    };

    const onDelete = async (): Promise<{ [FORM_ERROR]: string } | void> => {
        if (learningObjectiveId && 'id' in slide) {
            const result = await dispatch(
                removeSlide(slide.id, learningObjectiveId)
            );
            if (determineIfErrors(result)) {
                return handleFormErrors(result);
            }
        }
    };

    const handleFormErrors = (errors: Errors) => {
        if (errors.error) {
            return { [FORM_ERROR]: errors.error };
        }

        return;
    };

    const slideTypeField = (
        <Box my={2}>
            <Typography component="div">
                {isStandardType(learningObjective) ? 'Slide Type' : 'Steps'}
            </Typography>
            {isStandardType(learningObjective) ? (
                <ConceptPracticeApplication
                    learningObjective={learningObjective}
                />
            ) : (
                <LearningContentsField
                    learningContents={learningObjective.learning_contents}
                />
            )}
        </Box>
    );

    const onTemplateSelected = (template: Template, values: Slide) => {
        const { tutor_notes } = values;
        if (
            tutor_notes &&
            !(
                isHTMLWhiteSpace(slide.tutor_notes) ||
                isHTMLWhiteSpace(tutor_notes)
            )
        ) {
            const confirmed = window.confirm(
                'You will lose the contents of your tutor notes - are you sure you wish to proceed?'
            );

            if (confirmed) {
                changeTutorNotes(template.content, values);
            }
        } else {
            changeTutorNotes(template.content, values);
        }
    };

    const slideEdited = (dirty: boolean, values: Slide) => {
        const { tutor_notes } = values;
        localStorage.save(`slide-${slide.id}`, tutor_notes);
        setSlideEdited(dirty);
    };

    const debouncedSlideEditedHandler = useCallback(
        debounce(slideEdited, 300),
        []
    );

    useEffect(() => {
        setUnsavedChanges(hasUnsavedChanges);
    }, [hasUnsavedChanges]);

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={slide}
            render={({
                handleSubmit,
                submitting,
                pristine,
                dirty,
                submitError,
                values,
            }) => (
                <Box sx={sx.formWrapper}>
                    <form
                        onSubmit={handleSubmit}
                        onKeyUp={() =>
                            debouncedSlideEditedHandler(dirty, values)
                        }
                    >
                        <SlideContentLayout
                            left={
                                <Box
                                    flexGrow={1}
                                    position="relative"
                                    display="flex"
                                    flexDirection="column"
                                    minWidth="50%"
                                    pl={1}
                                >
                                    <Field
                                        name="image_path"
                                        buttonLabel={
                                            'id' in slide
                                                ? 'Click or drag to replace image (.png)'
                                                : 'Click or drag to add image (.png)'
                                        }
                                        showPreview
                                        validate={(value) =>
                                            value
                                                ? undefined
                                                : 'Please upload an image'
                                        }
                                    >
                                        {(props) => <Dropzone {...props} />}
                                    </Field>
                                    {!!fullScreen && slideTypeField}
                                </Box>
                            }
                            right={
                                <CardContent sx={sx.rightPanel}>
                                    <Box>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            mb={2}
                                        >
                                            <Button
                                                type="submit"
                                                color="primary"
                                                size="small"
                                                disabled={
                                                    submitting ||
                                                    (pristine &&
                                                        !unsavedChanges)
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                        <NotesTemplatePicker
                                            onTemplateSelect={(template) =>
                                                onTemplateSelected(
                                                    template,
                                                    values
                                                )
                                            }
                                            selectedType={searchParams.type.toString()}
                                        />
                                        <Typography component="div">
                                            Tutor Notes
                                        </Typography>
                                        <Field
                                            name="tutor_notes"
                                            component={QuillAdapter}
                                        />
                                        <Field
                                            name="components_path"
                                            buttonLabel={
                                                'id' in slide
                                                    ? 'Click or drag to replace the component file (.zip)'
                                                    : 'Click or drag to add the component file (.zip)'
                                            }
                                            showDetails
                                        >
                                            {(props) => <Dropzone {...props} />}
                                        </Field>
                                        {!fullScreen && slideTypeField}
                                    </Box>
                                    {onDelete && 'id' in slide && (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <DeleteConfirmationDialog
                                                onDelete={onDelete}
                                                message={
                                                    'Are you sure you want to delete this slide?'
                                                }
                                            />
                                        </Box>
                                    )}
                                    {submitError && (
                                        <Typography
                                            variant="subtitle1"
                                            color="error"
                                            align="center"
                                            paragraph
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                </CardContent>
                            }
                        />
                    </form>
                </Box>
            )}
        />
    );
};

export default SlideEdit;
