export const isHTMLWhiteSpace = (html: string): boolean => {
    const container = document.createElement('div');
    container.innerHTML = html;

    if (!container.textContent) {
        return true;
    }

    return !container.textContent.trim().length;
};
