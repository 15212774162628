import { Year } from '../../types';

export const GET_YEARS = 'GET_YEARS';
export const GET_YEARS_SUCCESS = 'GET_YEARS_SUCCESS';
export const GET_YEARS_FAILURE = 'GET_YEARS_FAILURE';
export const ADD_YEAR = 'ADD_YEAR';
export const ADD_YEAR_SUCCESS = 'ADD_YEAR_SUCCESS';
export const ADD_YEAR_FAILURE = 'ADD_YEAR_FAILURE';
export const EDIT_YEAR = 'EDIT_YEAR';
export const EDIT_YEAR_SUCCESS = 'EDIT_YEAR_SUCCESS';
export const EDIT_YEAR_FAILURE = 'EDIT_YEAR_FAILURE';

export interface GetYearsAction {
    type: typeof GET_YEARS;
    payload: undefined;
}

export interface GetYearsSuccessAction {
    type: typeof GET_YEARS_SUCCESS;
    payload: Year[];
}

export interface GetYearsFailureAction {
    type: typeof GET_YEARS_FAILURE;
    payload: string;
}

export interface AddYearAction {
    type: typeof ADD_YEAR;
    payload: undefined;
}

export interface AddYearSuccessAction {
    type: typeof ADD_YEAR_SUCCESS;
    payload: Year;
}

export interface AddYearFailureAction {
    type: typeof ADD_YEAR_FAILURE;
    payload: string;
}

export interface EditYearAction {
    type: typeof EDIT_YEAR;
    payload: undefined;
}

export interface EditYearSuccessAction {
    type: typeof EDIT_YEAR_SUCCESS;
    payload: Year;
}

export interface EditYearFailureAction {
    type: typeof EDIT_YEAR_FAILURE;
    payload: string;
}

export type YearsActionTypes =
    | GetYearsAction
    | GetYearsSuccessAction
    | GetYearsFailureAction
    | AddYearAction
    | AddYearSuccessAction
    | AddYearFailureAction
    | EditYearAction
    | EditYearSuccessAction
    | EditYearFailureAction;

export interface YearsState {
    years: Year[];
    error: string | null;
    fetching: boolean;
}
