import { FC, useEffect } from 'react';
import { Field } from 'react-final-form';

import {
    Box,
    Container,
    DialogContent,
    Typography,
    Button,
    Autocomplete,
    TextFinalField,
} from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../../../state/store';

import { deactivateLearningObjectives } from '../../../../api/learning-objectives';
import {
    editLearningObjective,
    selectSubmittingInformation,
} from '../../../../state/learning-objectives';
import {
    selectYearsAsOptions,
    selectYearsError,
    selectYearsFetching,
    getYears,
} from '../../../../state/years';

import {
    ContentDomain,
    LearningObjective,
    LearningObjectiveType,
} from '../../../../types';

import { DeleteConfirmationDialog } from '../../../shared';

import {
    ConceptPracticeApplication,
    LearningContentsAttributes,
    MultipleContentDomainSection,
    SingleStrandSection,
    StrandSubstrandSection,
} from './Sections';
import Condition from './Condition';

const sx = {
    activateButton: {
        marginRight: 1,
    },
};

interface Props {
    push: (...args: any[]) => any;
    onClose: (
        event?: Event,
        reason?: 'backdropClick' | 'escapeKeyDown' | undefined,
        refetch?: boolean | undefined
    ) => void;
    learningObjective: LearningObjective | { type: string };
    dirtySinceLastSubmit: boolean;
    editing: boolean;
    contentDomains: null | ContentDomain[];
    setContentDomains: (cd: ContentDomain[] | null) => void;
}

const AddEditFormContent: FC<Props> = ({
    push,
    onClose,
    learningObjective,
    dirtySinceLastSubmit,
    editing,
    contentDomains,
    setContentDomains,
}) => {
    const dispatch = useAppDispatch();

    const years = useAppSelector(selectYearsAsOptions);
    const yearsError = useAppSelector(selectYearsError);
    const fetching = useAppSelector(selectYearsFetching);
    const { error } = useAppSelector(selectSubmittingInformation);

    useEffect(() => {
        if (!yearsError && !fetching && !years.length) {
            dispatch(getYears({}));
        }
    }, [dispatch, years, yearsError, fetching]);

    const changeActive = async () => {
        if ('id' in learningObjective) {
            dispatch(
                editLearningObjective(
                    learningObjective.id,
                    {
                        active: !learningObjective.active,
                        type: learningObjective.type,
                    },
                    learningObjective.type
                )
            );
        }
    };

    const deleteLO = async () => {
        if ('id' in learningObjective) {
            deactivateLearningObjectives(learningObjective.id).then(() => {
                onClose(undefined, undefined, true);
            });
        }
    };

    return (
        <Container maxWidth="md">
            <DialogContent>
                <Box mb={3}>
                    <Field
                        name="type"
                        label="Select Objective Type"
                        variant="outlined"
                        color="primary"
                        component={Autocomplete}
                        options={[
                            { value: 'sats', label: 'SATs' },
                            {
                                value: 'standard',
                                label: 'Standard',
                            },
                            { value: 'gcse', label: 'GCSEs' },
                        ]}
                        disabled={'id' in learningObjective}
                        fullWidth
                        required
                    />
                </Box>
                <Field
                    name="title"
                    label="TSL Learning Objective"
                    variant="outlined"
                    color="primary"
                    component={TextFinalField}
                    fullWidth
                    required
                />
                <Condition when="type" is={LearningObjectiveType.STANDARD}>
                    <Field
                        name="year.id"
                        component={Autocomplete}
                        options={years}
                        variant="outlined"
                        label="Year"
                        color="primary"
                        required
                    />
                </Condition>
                <Condition
                    when="type"
                    is={[
                        LearningObjectiveType.SATS,
                        LearningObjectiveType.GCSE,
                    ]}
                >
                    <Field
                        name="impact"
                        component={Autocomplete}
                        options={[
                            { value: 'high', label: 'High' },
                            {
                                value: 'medium',
                                label: 'Medium',
                            },
                            { value: 'low', label: 'Low' },
                        ]}
                        variant="outlined"
                        label="Impact"
                        color="primary"
                        required
                    />
                </Condition>

                <Condition when="type" is={LearningObjectiveType.STANDARD}>
                    <StrandSubstrandSection
                        learningObjective={learningObjective}
                    />
                </Condition>
                <Condition when="type" is={LearningObjectiveType.SATS}>
                    <MultipleContentDomainSection
                        push={push}
                        setContentDomains={setContentDomains}
                        contentDomains={contentDomains || []}
                        learningObjective={learningObjective}
                    />
                </Condition>
                <Condition when="type" is={LearningObjectiveType.GCSE}>
                    <SingleStrandSection
                        setContentDomains={setContentDomains}
                    />
                </Condition>

                <Condition when="type" is={LearningObjectiveType.STANDARD}>
                    <ConceptPracticeApplication />
                </Condition>
                <Condition
                    when="type"
                    is={[
                        LearningObjectiveType.SATS,
                        LearningObjectiveType.GCSE,
                    ]}
                >
                    <LearningContentsAttributes push={push} />
                </Condition>
                <Typography
                    variant="subtitle1"
                    color="error"
                    align="center"
                    paragraph
                >
                    {dirtySinceLastSubmit ? null : error}
                </Typography>
                {editing && (
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Button
                            onClick={changeActive}
                            color="secondary"
                            sx={sx.activateButton}
                        >
                            {'active' in learningObjective &&
                            learningObjective.active
                                ? 'Deactivate'
                                : 'Activate'}
                        </Button>
                        {'active' in learningObjective &&
                            !learningObjective.active && (
                                <DeleteConfirmationDialog
                                    onDelete={deleteLO}
                                    message="Are you sure you want to delete this learning objective?"
                                />
                            )}
                    </Box>
                )}
            </DialogContent>
        </Container>
    );
};

export default AddEditFormContent;
