import { FC } from 'react';

import { Grid, Typography } from '@thirdspacelearning/library';

const sx = {
    wrapper: {
        justifyContent: 'center',
    },
};

interface Props {
    title: string;
}

const PlaceholderPage: FC<Props> = ({ title }) => {
    return (
        <Grid container spacing={3} sx={sx.wrapper}>
            <Grid item xs={5}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{`Placeholder for ${title.toLowerCase()} page`}</Typography>
            </Grid>
        </Grid>
    );
};

export default PlaceholderPage;
