export interface User {
    email: string;
    first_name: string;
    id: number;
    intervention: boolean | null;
    last_name: string;
    memberships: Array<Membership>;
    name: string;
    school_role: string | null;
    tier: UserTier;
    username: string;
    permissions: Array<Permission>;
    platform_user_id: number;
    year_id: number;
}

export interface Permission {
    perm_scope: string;
    perm_type: string;
    perm_value: string;
}

export interface Membership {
    m_current: boolean;
    m_type: MembershipType;
    o_country: string;
    o_name: string;
    o_tier: UserTier;
    o_type: string;
    o_urn: number;
    r_type?: RoleType | null;
}

export enum RoleType {
    PURCHASER = 'Purchaser',
    IT_LEAD = 'ItLead',
    INTERVENTION_LEAD = 'InterventionLead',
}

export enum MembershipType {
    SUPER_ADMIN = 'SuperAdmin',
    ADMIN = 'Admin',
    TEACHER = 'Teacher',
    STUDENT = 'Student',
    IT_LEAD = 'ItLead',
    TUTOR_ADMIN = 'TutorAdmin',
    TUTOR = 'Tutor',
    ACADEMIC_COUNCILLOR = 'AcademicCouncillor',
    KOSMOS_TEST_USER = 'KosmosTestUser',
    UNVERIFIED = 'Unverified',
}

export enum UserTier {
    BRONZE = 'bronze',
    GOLD = 'gold',
    PREMIUM = 'premium',
    SILVER = 'silver',
}

export interface Errors {
    error: string;
    [errorKey: string]: string;
}
