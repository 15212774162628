import axios from 'axios';
import { ContentDomain } from '../types';

import { environment } from '../environments/environment';

export interface ContentDomainParams {
    page?: number;
    per_page?: number;
    trashed?: boolean;
    include_trashed?: boolean;
    reference?: string;
}

export interface getContentDomainError {
    error: string;
}

export const getContentDomains = async (
    params: ContentDomainParams = {}
): Promise<ContentDomain[]> => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/content_domains`, {
            params,
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
