import {
    datadogRum,
    DefaultPrivacyLevel,
    RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';
import { Middleware } from '@reduxjs/toolkit';
import { loginUser } from '@tsl-frontend/tsl-auth';
import { addGlobalContext, customError, customUserAction } from '../actions';

interface IWindow extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
}

export const Datadog = (
    extraActionTypes: string[],
    configuration: {
        rumAppId?: string;
        rumClientToken?: string;
        rumServiceName?: string;
        appEnv: string;
        replaySampleRate?: number;
        privacyLevel?: DefaultPrivacyLevel;
    }
): Middleware => {
    if (
        configuration.rumAppId &&
        configuration.rumClientToken &&
        !(
            (window as IWindow & typeof globalThis).Cypress ||
            process.env.JEST_WORKER_ID ||
            process.env.NODE_ENV === 'test'
        )
    ) {
        datadogRum.init({
            site: 'datadoghq.eu',
            applicationId: `${configuration.rumAppId}`,
            clientToken: `${configuration.rumClientToken}`,
            service: `${configuration.rumServiceName}`,
            env: `${configuration.appEnv}`,
            version: '1.0.0',
            trackUserInteractions: true, // enables the automatic collection of user clicks
            trackSessionAcrossSubdomains: true,
            trackResources: true,
            trackLongTasks: true,
            sessionSampleRate: 100,
            sessionReplaySampleRate: configuration.replaySampleRate ?? 0,
            startSessionReplayRecordingManually: true,
            defaultPrivacyLevel:
                configuration.privacyLevel ?? 'mask-user-input',
            beforeSend: (event, context) => {
                // adding the response to xhr requests for better debugging
                if (
                    event.type === 'resource' &&
                    event.resource?.type === 'xhr'
                ) {
                    event.context = {
                        ...event.context,
                        response: (context as RumXhrResourceEventDomainContext)
                            .xhr.response,
                    };
                }
                return true;
            },
        });
    }

    return (storeAPI) => {
        return (next) => {
            return (action) => {
                if (action.type === loginUser.type) {
                    const user = action.payload.user;

                    const userConfig = { ...user, id: String(user.id) };

                    // set user membership types
                    if (user.memberships[0] && user.memberships[0].m_type) {
                        const membershipType = user.memberships[0].m_type;
                        datadogRum.setGlobalContextProperty(
                            'usr_role',
                            membershipType
                        );
                    }

                    datadogRum.setUser(userConfig);
                    datadogRum.startSessionReplayRecording();
                }

                if (action.type === addGlobalContext.toString()) {
                    const { key, value } = action.payload;
                    datadogRum.setGlobalContextProperty(key, value);
                }

                if (action.type === customError.toString()) {
                    const { error, info } = action.payload;
                    datadogRum.addError(error, info);
                }

                if (action.type === customUserAction.toString()) {
                    const { name, extraInfo } = action.payload;
                    datadogRum.addAction(name, extraInfo);
                }

                if (extraActionTypes.includes(action.type)) {
                    datadogRum.addAction(action.type, action.payload);
                }

                return next(action);
            };
        };
    };
};
