import { FC } from 'react';

import { TableSortLabel } from '@thirdspacelearning/library';

interface Props {
    currentSort: string;
    name: string;
    label: string;
    onSort: (name: string) => void;
}

const SortColumnHeader: FC<Props> = ({ name, label, currentSort, onSort }) => {
    return (
        <TableSortLabel
            direction={currentSort === name ? 'desc' : 'asc'}
            active={currentSort === name || currentSort === `-${name}`}
            hideSortIcon
            onClick={() => onSort(name)}
        >
            {label}
        </TableSortLabel>
    );
};

export default SortColumnHeader;
