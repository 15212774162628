import { useEffect } from 'react';

import { Timer } from '../../utils/timer';

interface TimedEvent {
    time: number /* in milliseconds */;
    callback: () => void;
}

export const useTimedEvent = (timedEvent: TimedEvent) => {
    const { time, callback } = timedEvent;

    useEffect(() => {
        const timer = new Timer(callback, time);
        timer.start();
        return () => {
            timer.stop();
        };
    }, [time, callback]);
};
