import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, LinearProgress } from '@thirdspacelearning/library';

import { useInterval } from '../../../utils/hooks';
import { LearningObjective } from '../../../types';

interface Props {
    learningObjective: LearningObjective;
    getLearningObjective: () => void;
    processing: boolean;
}

const Processing: FC<Props> = ({
    learningObjective,
    getLearningObjective,
    processing,
}) => {
    const navigate = useNavigate();

    useInterval(() => {
        if (processing) {
            getLearningObjective();
        } else {
            navigate(`/learning-content/${learningObjective.id}`);
        }
    }, 3000);

    return (
        <Box mt={3}>
            <LinearProgress />
            <Typography variant="subtitle1" align="center" mt={2}>
                We're processing your slides. This might take a while.
            </Typography>
        </Box>
    );
};

export default Processing;
