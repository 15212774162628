import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';

import {
    CssBaseline,
    ThemeProvider,
    TSLTheme,
} from '@thirdspacelearning/library';

import App from './features/App';
import { queryClient } from './api/services/query-client';
import LaunchDarklyProvider from './hooks/launch-darkly/LaunchDarklyProvider';

import { store } from './state/store';

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LaunchDarklyProvider>
                <ThemeProvider theme={TSLTheme}>
                    <CssBaseline />
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <App />
                        </Router>
                    </QueryClientProvider>
                </ThemeProvider>
            </LaunchDarklyProvider>
        </Provider>
    </React.StrictMode>
);
