import { FC } from 'react';

import { PageLayout } from '../shared';
import { LearningObjectiveType } from '../../types';

import { RevisionLearningMap } from './components';

interface Props {}

const GCSELearningMapsPage: FC<Props> = () => (
    <PageLayout title="Programme type: Revision">
        <RevisionLearningMap
            learningObjectiveType={LearningObjectiveType.GCSE}
        />
    </PageLayout>
);

export default GCSELearningMapsPage;
