export const createFilterObject = (
    allOptions: { [key: string]: any },
    filterOptions: { filterId: string; multiple?: boolean }[]
) => {
    return filterOptions.reduce(
        (accumulator: any, { filterId }) => ({
            ...accumulator,
            [filterId]: allOptions[filterId],
        }),
        {}
    );
};
