/* eslint-disable no-case-declarations */
import { createSelector } from 'reselect';
import {
    GET_LEARNING_OBJECTIVES,
    GET_LEARNING_OBJECTIVES_SUCCESS,
    GET_LEARNING_OBJECTIVES_FAILURE,
    GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS,
    ADD_LEARNING_OBJECTIVE,
    EDIT_LEARNING_OBJECTIVE,
    ADD_LEARNING_OBJECTIVE_SUCCESS,
    EDIT_LEARNING_OBJECTIVE_SUCCESS,
    ADD_LEARNING_OBJECTIVE_FAILURE,
    EDIT_LEARNING_OBJECTIVE_FAILURE,
    RESET_SUBMIT,
    LearningObjectivesState,
    LearningObjectivesActionTypes,
    GET_REVISION_LEARNING_OBJECTIVE_SUCCESS,
    GET_REVISION_LEARNING_OBJECTIVE_FAILURE,
    GET_REVISION_LEARNING_OBJECTIVE,
    SWAP_REVISION_ORDER_SUCCESS,
} from './types';
import { RootState } from '../types';
import { LearningObjectiveType } from '../../types';

const storeArrayById = (array: any[], id: string) => {
    return array.reduce(
        (accumulator: any, item: any) => ({
            [item[id]]: item,
            ...accumulator,
        }),
        {}
    );
};

export const loInitialState: LearningObjectivesState = {
    allLearningObjectives: {},
    searchedLearningObjectives: null,
    revisionLearningObjectives: null,
    revisionLearningObjectivesError: null,
    searching: false,
    searchParams: { type: LearningObjectiveType.STANDARD, title: '' },
    tableParams: { per_page: 25, page: 1, totalCount: 0 },
    error: null,
    changeSubmit: {
        submitting: false,
        success: false,
        error: null,
    },
};

const learningObjectives = (
    state = loInitialState,
    action: LearningObjectivesActionTypes
): LearningObjectivesState => {
    switch (action.type) {
        case GET_LEARNING_OBJECTIVES:
            const tableParams = {
                per_page: action.payload.per_page,
                page: action.payload.page,
            };
            return {
                ...state,
                searchedLearningObjectives: null,
                searching: true,
                searchParams: action.payload,
                tableParams: {
                    ...state.tableParams,
                    ...tableParams,
                },
                changeSubmit: {
                    submitting: false,
                    success: false,
                    error: null,
                },
            };
        case GET_LEARNING_OBJECTIVES_SUCCESS:
            return {
                ...state,
                searchedLearningObjectives: action.payload.learningObjectives,
                searching: false,
                tableParams: {
                    ...state.tableParams,
                    totalCount: action.payload.totalCount,
                },
                allLearningObjectives: storeArrayById(
                    action.payload.learningObjectives,
                    'title'
                ),
            };
        case GET_LEARNING_OBJECTIVES_FAILURE:
            return {
                ...state,
                searchedLearningObjectives: null,
                searching: false,
                error: action.payload,
            };
        case ADD_LEARNING_OBJECTIVE:
        case EDIT_LEARNING_OBJECTIVE:
            return {
                ...state,
                changeSubmit: {
                    submitting: true,
                    success: false,
                    error: null,
                },
            };
        case ADD_LEARNING_OBJECTIVE_SUCCESS:
        case EDIT_LEARNING_OBJECTIVE_SUCCESS:
            return {
                ...state,
                changeSubmit: {
                    submitting: false,
                    success: true,
                    error: null,
                },
                allLearningObjectives: {
                    ...state.allLearningObjectives,
                    [action.payload.title]: action.payload,
                },
            };
        case GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS:
            return {
                ...state,
                allLearningObjectives: {
                    ...state.allLearningObjectives,
                    [action.payload.title]: action.payload,
                },
            };
        case ADD_LEARNING_OBJECTIVE_FAILURE:
        case EDIT_LEARNING_OBJECTIVE_FAILURE:
            return {
                ...state,
                changeSubmit: {
                    submitting: false,
                    success: false,
                    error: action.payload,
                },
            };
        case RESET_SUBMIT:
            return {
                ...state,
                changeSubmit: {
                    submitting: false,
                    success: false,
                    error: null,
                },
            };
        case GET_REVISION_LEARNING_OBJECTIVE:
            return {
                ...state,
                revisionLearningObjectives: null,
                revisionLearningObjectivesError: null,
            };
        case GET_REVISION_LEARNING_OBJECTIVE_SUCCESS:
            return {
                ...state,
                revisionLearningObjectives: action.payload,
            };
        case GET_REVISION_LEARNING_OBJECTIVE_FAILURE:
            return {
                ...state,
                revisionLearningObjectivesError: action.payload,
            };
        case SWAP_REVISION_ORDER_SUCCESS:
            const revisionLearningObjectives = state.revisionLearningObjectives
                ? [...state.revisionLearningObjectives]
                : null;
            const { oldPosition, newPosition } = action.payload;

            if (
                revisionLearningObjectives &&
                state.revisionLearningObjectives
            ) {
                const oldLOIndex = revisionLearningObjectives.findIndex(
                    (lo) => lo.revision_order === oldPosition
                );
                const newLOIndex = revisionLearningObjectives.findIndex(
                    (lo) => lo.revision_order === newPosition
                );

                if (oldLOIndex > -1) {
                    revisionLearningObjectives.splice(oldLOIndex, 1, {
                        ...state.revisionLearningObjectives[oldLOIndex],
                        revision_order: newPosition,
                    });
                }

                if (newLOIndex > -1) {
                    revisionLearningObjectives.splice(newLOIndex, 1, {
                        ...state.revisionLearningObjectives[newLOIndex],
                        revision_order: oldPosition,
                    });
                }

                revisionLearningObjectives.sort(
                    (a, b) => a.revision_order - b.revision_order
                );
            }

            return {
                ...state,
                revisionLearningObjectives,
            };
        default:
            return state;
    }
};

const learningObjectivesSelector = (state: RootState) =>
    state.learningObjectives;

export const selectSearchedLearningObjectives = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.searchedLearningObjectives
);

export const selectSearching = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.searching
);

export const selectSearchParams = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.searchParams
);

export const selectTableParams = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.tableParams
);

export const selectLearningObjectivesError = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) => learningObjectives.error
);

export const selectAllLearningObjectives = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.allLearningObjectives
);

export const selectSubmittingInformation = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.changeSubmit
);

export const selectRevisionLearningObjectives = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.revisionLearningObjectives
);

export const selectRevisionLearningObjectivesError = createSelector(
    learningObjectivesSelector,
    (learningObjectives: LearningObjectivesState) =>
        learningObjectives.revisionLearningObjectivesError
);

export default learningObjectives;
