import { FC, ReactNode, useState } from 'react';

import { Box, Select, SelectChangeEvent } from '@thirdspacelearning/library';

import { Template, templates } from './templates';

const sx = {
    formControl: {
        marginBottom: 2,
        width: '100%',
        '> .MuiInputBase-root': {
            marginTop: 0,
        },
        '> .MuiInputBase-root .MuiInputBase-input': {
            maxWidth: '100%',
        },
    },
};

const selectSxProps = { formControl: sx.formControl };

interface Props {
    onTemplateSelect: (template: Template) => void;
    selectedType: string;
}

const NotesTemplatePicker: FC<Props> = ({ onTemplateSelect, selectedType }) => {
    const [currentTemplateName, setCurrentTemplateName] = useState<string>(
        templates[0].name
    );

    const getTypeFilteredTemplates = (
        type: string,
        templates: Template[],
        templatesToRemove: string[],
        templatesToAdd: string[]
    ): Template[] => {
        if (selectedType === type) {
            return templates.filter((temp) => {
                return temp.name !== templatesToRemove[0];
            });
        } else {
            return templates.filter((temp) => {
                return (
                    temp.name !== templatesToAdd[0] &&
                    temp.name !== templatesToAdd[1] &&
                    temp.name !== templatesToAdd[2]
                );
            });
        }
    };

    const typeFilteredTemplates = getTypeFilteredTemplates(
        'standard',
        templates,
        ['Cover Slide'],
        ['Pre-Assess', 'Prior Learning', 'Classroom Tools']
    );

    const options = typeFilteredTemplates.map((template) => ({
        id: template.name,
        name: template.name,
    }));

    const handleTemplateChange = (
        event: SelectChangeEvent<string | string[]>,
        _child: ReactNode
    ) => {
        const templateName = event.target.value;
        const template = templates.find((t) => t.name === templateName);

        if (!template) {
            return;
        }

        onTemplateSelect(template);
        setCurrentTemplateName(template.name);
    };

    return (
        <Box width="100%">
            <Select
                name="templateName"
                handleChange={handleTemplateChange}
                value={currentTemplateName}
                options={options}
                sx={selectSxProps}
            />
        </Box>
    );
};

export default NotesTemplatePicker;
