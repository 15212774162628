import { FC } from 'react';
import { SvgIcon } from '@thirdspacelearning/library';

const sx = {
    root: { width: '100%', height: '100%' },
};

interface Props {
    className?: string;
}

const JourneyMap: FC<Props> = ({ className }) => (
    <SvgIcon className={className} sx={sx.root} viewBox="0 0 250 161">
        <rect x=".030273" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="17.03" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="43.175" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="60.175" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="43.175" y="84.381" width="34" height="34" fill="#64A0FC" />
        <circle cx="60.175" cy="101.38" r="9.1445" fill="#fff" />
        <rect x="86.319" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="103.32" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="86.319" y="84.381" width="34" height="34" fill="#64A0FC" />
        <circle cx="103.32" cy="101.38" r="9.1445" fill="#fff" />
        <rect x="129.46" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="146.46" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="129.46" y="84.381" width="34" height="34" fill="#64A0FC" />
        <circle cx="146.46" cy="101.38" r="9.1445" fill="#fff" />
        <rect x="129.46" y="42.492" width="34" height="34" fill="#64A0FC" />
        <circle cx="146.46" cy="59.492" r="9.1445" fill="#fff" />
        <rect x="172.61" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="189.61" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="172.61" y="84.381" width="34" height="34" fill="#64A0FC" />
        <circle cx="189.61" cy="101.38" r="9.1445" fill="#fff" />
        <rect x="172.61" y="42.492" width="34" height="34" fill="#64A0FC" />
        <circle cx="189.61" cy="59.492" r="9.1445" fill="#fff" />
        <rect x="172.61" y=".60352" width="34" height="34" fill="#64A0FC" />
        <circle cx="189.61" cy="17.604" r="9.1445" fill="#fff" />
        <rect x="215.75" y="126.27" width="34" height="34" fill="#64A0FC" />
        <circle cx="232.75" cy="143.27" r="9.1445" fill="#fff" />
        <rect x="215.75" y="84.381" width="34" height="34" fill="#64A0FC" />
        <circle cx="232.75" cy="101.38" r="9.1445" fill="#fff" />
        <rect x="215.75" y="42.492" width="34" height="34" fill="#64A0FC" />
        <circle cx="232.75" cy="59.492" r="9.1445" fill="#fff" />
        <rect x="215.75" y=".60352" width="34" height="34" fill="#64A0FC" />
        <circle cx="232.75" cy="17.604" r="9.1445" fill="#fff" />
        <circle cx="60.175" cy="143.27" r="3.493" fill="#2779F5" />
        <circle cx="103.32" cy="143.27" r="3.493" fill="#2779F5" />
        <line x1="60.175" x2="102.42" y1="143.4" y2="143.4" stroke="#2779F5" />
        <circle cx="60.175" cy="101.38" r="3.493" fill="#2779F5" />
        <circle cx="103.32" cy="101.38" r="3.493" fill="#2779F5" />
        <line
            x1="60.175"
            x2="102.42"
            y1="101.51"
            y2="101.51"
            stroke="#2779F5"
        />
        <circle cx="103.32" cy="143.27" r="3.493" fill="#2779F5" />
        <circle cx="146.46" cy="143.27" r="3.493" fill="#2779F5" />
        <line x1="103.32" x2="145.56" y1="143.4" y2="143.4" stroke="#2779F5" />
        <circle
            cx="103.32"
            cy="101.38"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <circle
            cx="146.46"
            cy="101.38"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <line
            x1="103.32"
            x2="145.56"
            y1="101.51"
            y2="101.51"
            stroke="#FF0C3E"
        />
        <circle cx="146.46" cy="143.27" r="3.493" fill="#2779F5" />
        <circle cx="189.61" cy="143.27" r="3.493" fill="#2779F5" />
        <line x1="146.46" x2="188.71" y1="143.4" y2="143.4" stroke="#2779F5" />
        <circle
            cx="146.46"
            cy="101.38"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <circle
            cx="189.61"
            cy="101.38"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <line
            x1="146.46"
            x2="188.71"
            y1="101.51"
            y2="101.51"
            stroke="#FF0C3E"
        />
        <circle cx="146.46" cy="59.492" r="3.493" fill="#2779F5" />
        <circle cx="189.61" cy="59.492" r="3.493" fill="#2779F5" />
        <line
            x1="146.46"
            x2="188.71"
            y1="59.618"
            y2="59.618"
            stroke="#2779F5"
        />
        <circle cx="189.61" cy="143.27" r="3.493" fill="#2779F5" />
        <circle cx="232.75" cy="143.27" r="3.493" fill="#2779F5" />
        <line x1="189.61" x2="231.85" y1="143.4" y2="143.4" stroke="#2779F5" />
        <circle cx="189.61" cy="101.38" r="3.493" fill="#2779F5" />
        <circle cx="232.75" cy="101.38" r="3.493" fill="#2779F5" />
        <line
            x1="189.61"
            x2="231.85"
            y1="101.51"
            y2="101.51"
            stroke="#2779F5"
        />
        <circle cx="189.61" cy="59.492" r="3.493" fill="#2779F5" />
        <circle cx="232.75" cy="59.492" r="3.493" fill="#2779F5" />
        <line
            x1="189.61"
            x2="231.85"
            y1="59.618"
            y2="59.618"
            stroke="#2779F5"
        />
        <circle cx="189.61" cy="17.603" r="3.493" fill="#2779F5" />
        <circle cx="232.75" cy="17.603" r="3.493" fill="#2779F5" />
        <line
            x1="189.61"
            x2="231.85"
            y1="17.729"
            y2="17.729"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 232.75 17.603)"
            cx="232.75"
            cy="17.603"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 232.75 59.492)"
            cx="232.75"
            cy="59.492"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="232.63"
            x2="232.63"
            y1="17.604"
            y2="59.848"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 17.603)"
            cx="189.61"
            cy="17.603"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 59.492)"
            cx="189.61"
            cy="59.492"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="189.48"
            x2="189.48"
            y1="17.604"
            y2="59.848"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 232.75 101.38)"
            cx="232.75"
            cy="101.38"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="232.63"
            x2="232.63"
            y1="59.492"
            y2="101.74"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 59.492)"
            cx="189.61"
            cy="59.492"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 101.38)"
            cx="189.61"
            cy="101.38"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="189.48"
            x2="189.48"
            y1="59.492"
            y2="101.74"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 146.46 59.492)"
            cx="146.46"
            cy="59.492"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 146.46 101.38)"
            cx="146.46"
            cy="101.38"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="146.34"
            x2="146.34"
            y1="59.492"
            y2="101.74"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 60.175 101.56)"
            cx="60.175"
            cy="101.56"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 60.175 143.45)"
            cx="60.175"
            cy="143.45"
            r="3.493"
            fill="#2779F5"
        />
        <line x1="60.05" x2="60.05" y1="101.56" y2="143.8" stroke="#2779F5" />
        <line
            x1="59.896"
            x2="16.324"
            y1="101.74"
            y2="143.68"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 59.275 101.38)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 103.48 101.38)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 147.05 101.38)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 147.05 59.572)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 189.77 17.505)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 189.77 101.38)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            transform="matrix(.72052 .69343 .69343 -.72052 189.77 59.572)"
            x2="60.474"
            y1="-.5"
            y2="-.5"
            stroke="#2779F5"
        />
        <line
            x1="103.43"
            x2="59.858"
            y1="101.74"
            y2="143.68"
            stroke="#FF0C3E"
        />
        <line
            x1="146.96"
            x2="103.39"
            y1="101.74"
            y2="143.68"
            stroke="#2779F5"
        />
        <line
            x1="146.96"
            x2="103.39"
            y1="60.008"
            y2="101.94"
            stroke="#2779F5"
        />
        <line x1="190.5" x2="146.92" y1="101.74" y2="143.68" stroke="#2779F5" />
        <line x1="190.5" x2="146.92" y1="60.008" y2="101.94" stroke="#2779F5" />
        <line x1="190.5" x2="146.92" y1="17.865" y2="59.8" stroke="#2779F5" />
        <line
            x1="234.03"
            x2="190.46"
            y1="101.74"
            y2="143.68"
            stroke="#2779F5"
        />
        <line
            x1="232.74"
            x2="190.13"
            y1="59.176"
            y2="102.09"
            stroke="#FF0C3E"
        />
        <line x1="234.03" x2="190.46" y1="17.865" y2="59.8" stroke="#2779F5" />
        <circle
            transform="rotate(90 232.75 101.38)"
            cx="232.75"
            cy="101.38"
            r="3.493"
            fill="#2779F5"
        />
        <circle
            transform="rotate(90 232.75 143.27)"
            cx="232.75"
            cy="143.27"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="232.63"
            x2="232.63"
            y1="101.38"
            y2="143.63"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 143.27)"
            cx="189.61"
            cy="143.27"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="189.48"
            x2="189.48"
            y1="101.38"
            y2="143.63"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 189.61 101.38)"
            cx="189.61"
            cy="101.38"
            r="3.493"
            fill="#FF0C3E"
        />
        <circle
            transform="rotate(90 146.46 143.27)"
            cx="146.46"
            cy="143.27"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="146.34"
            x2="146.34"
            y1="101.38"
            y2="143.63"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 146.46 101.38)"
            cx="146.46"
            cy="101.38"
            r="3.493"
            fill="#FF0C3E"
        />
        <circle
            transform="rotate(90 103.32 143.27)"
            cx="103.32"
            cy="143.27"
            r="3.493"
            fill="#2779F5"
        />
        <line
            x1="103.19"
            x2="103.19"
            y1="101.38"
            y2="143.63"
            stroke="#2779F5"
        />
        <circle
            transform="rotate(90 103.32 101.38)"
            cx="103.32"
            cy="101.38"
            r="3.493"
            fill="#FF0C3E"
        />
        <circle
            cx="17.03"
            cy="143.27"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <circle
            cx="60.175"
            cy="143.27"
            r="2.993"
            fill="#FF0C3E"
            stroke="#FF0C3E"
        />
        <line x1="17.03" x2="59.275" y1="143.4" y2="143.4" stroke="#FF0C3E" />
        <circle
            transform="rotate(90 232.75 59.439)"
            cx="232.75"
            cy="59.439"
            r="3.493"
            fill="#FF0C3E"
        />
    </SvgIcon>
);

export default JourneyMap;
