import { FC, useEffect, useCallback, useState } from 'react';
import { FiltersBar } from '@thirdspacelearning/library';
import { useAppDispatch, useAppSelector } from '../../state/store';
import {
    selectStrandsAsFilters,
    selectStrandsError,
    selectStrandsFetching,
    getStrands,
    selectLearningAreasAsFilters,
} from '../../state/strands';
import {
    selectYearsAsFilters,
    selectYearsError,
    selectYearsFetching,
    getYears,
} from '../../state/years';
import { getStrandsError, getSubStrands } from '../../api/strands';
import { SubStrand, SearchParams, LearningObjectiveType } from '../../types';
import { createFilterObject } from '../../utils';

type FilterOption = { filterId: string; multiple?: boolean };

interface Props {
    updateSearchParams: (params: SearchParams, clearFilters?: boolean) => void;
    searchParams: SearchParams;
    filterOptions: FilterOption[];
    selectLearningAreas?: boolean;
    filterBarOptions?: {
        searchbar?: boolean;
        clear?: boolean;
    };
}

const CurriculumFilters: FC<Props> = ({
    updateSearchParams,
    searchParams,
    filterOptions,
    selectLearningAreas = false,
    filterBarOptions,
}) => {
    const dispatch = useAppDispatch();

    const years = useAppSelector(selectYearsAsFilters);
    const yearsError = useAppSelector(selectYearsError);
    const fetching = useAppSelector(selectYearsFetching);
    const strands = useAppSelector(
        selectLearningAreas
            ? selectLearningAreasAsFilters
            : selectStrandsAsFilters
    );
    const strandsError = useAppSelector(selectStrandsError);
    const strandsFetching = useAppSelector(selectStrandsFetching);

    const filterIds = filterOptions.map((options) => options.filterId);

    useEffect(() => {
        if (
            !yearsError &&
            !fetching &&
            !years.length &&
            filterIds.includes('year_id')
        ) {
            dispatch(getYears({}));
        }
    }, [dispatch, years, yearsError, fetching, filterIds]);

    useEffect(() => {
        if (
            !strandsError &&
            !strandsFetching &&
            !strands.length &&
            filterIds.includes('strand_id')
        ) {
            dispatch(getStrands({}));
        }
    }, [dispatch, strands, strandsError, strandsFetching, filterIds]);

    const [subStrands, setSubStrands] = useState<
        { id: number | string; name: string }[]
    >([]);

    const fetchSubStrands = useCallback(async (strandIds: number[]) => {
        return Promise.all(
            strandIds.map((strandId) => getSubStrands(strandId))
        ).then((subStrands: (SubStrand[] | getStrandsError)[]) => {
            if ('error' in subStrands) {
                return;
            } else {
                const allSubstrands = subStrands.flat() as SubStrand[];

                setSubStrands(
                    allSubstrands.map((subStrand) => ({
                        id: subStrand.id,
                        name: subStrand.title,
                    }))
                );
            }
        });
    }, []);

    const searchWithFilters = (filters: any) => {
        if ('strand_id' in filters && filterIds.includes('sub_strand_id')) {
            fetchSubStrands(filters.strand_id);
        }
        updateSearchParams(filters, !Object.keys(filters).length);
    };

    const determineMultiple = (filterId: string) => {
        const option = filterOptions.find(
            (option) => option.filterId === filterId
        );

        if (!option) {
            return false;
        }

        return option.multiple;
    };

    const labels = {
        type: 'Type',
        year_id: 'Year',
        strand_id: selectLearningAreas ? 'Learning Area' : 'NC Strand',
        sub_strand_id: 'NC Sub-Strand',
        impact: 'Impact',
    };

    const yearsMap: Record<
        LearningObjectiveType,
        { id: number; name: string }[]
    > = {
        [LearningObjectiveType.SATS]: years.filter((year) => {
            return year.name === 'Year 6';
        }),
        [LearningObjectiveType.GCSE]: years.filter((year) => {
            return ['Year 10', 'Year 11'].includes(year.name);
        }),
        [LearningObjectiveType.STANDARD]: years,
    };

    const filters = {
        type: {
            options: [
                { id: 'standard', name: 'Standard' },
                { id: 'sats', name: 'SATs' },
                { id: 'gcse', name: 'GCSEs' },
            ],
        },
        year_id: {
            options:
                yearsMap[searchParams.type as LearningObjectiveType] || years,
            multiple: determineMultiple('year_id'),
        },
        strand_id: {
            options: strands,
            multiple: determineMultiple('strand_id'),
        },
        sub_strand_id: {
            options: subStrands,
            multiple: determineMultiple('sub_strand_id'),
        },
        impact: {
            options: [
                { id: 'high', name: 'High' },
                { id: 'medium', name: 'Medium' },
                { id: 'low', name: 'Low' },
            ],
            multiple: true,
        },
    };

    if (!years.length || !strands.length) {
        return null;
    }

    return (
        <FiltersBar
            key={searchParams.strand_id?.toString()}
            filters={createFilterObject(filters, filterOptions)}
            searchFilters={searchWithFilters}
            initial={searchParams}
            dropdownLabels={createFilterObject(labels, filterOptions)}
            options={filterBarOptions}
            searchParam="title"
        />
    );
};

export default CurriculumFilters;
