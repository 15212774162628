/* eslint-disable no-case-declarations */
import { createSelector } from 'reselect';
import {
    GET_STRANDS,
    GET_STRANDS_SUCCESS,
    GET_STRANDS_FAILURE,
    GET_SUBSTRANDS_SUCCESS,
    GET_SUBSTRANDS_FAILURE,
    ADD_STRAND_SUCCESS,
    EDIT_STRAND_SUCCESS,
    StrandsState,
    StrandsActionTypes,
} from './types';
import { RootState } from '../types';
import { Strand } from '../../types';

export const strandsInitialState: StrandsState = {
    strands: [],
    subStrands: [],
    fetching: false,
    error: null,
};

const strands = (
    state = strandsInitialState,
    action: StrandsActionTypes
): StrandsState => {
    switch (action.type) {
        case GET_STRANDS:
            return {
                ...state,
                fetching: true,
            };
        case GET_STRANDS_SUCCESS:
            return {
                ...state,
                strands: action.payload,
                fetching: false,
            };
        case GET_STRANDS_FAILURE:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                strands: [],
            };
        case GET_SUBSTRANDS_SUCCESS:
            return {
                ...state,
                subStrands: action.payload,
                fetching: false,
            };
        case GET_SUBSTRANDS_FAILURE:
            return {
                ...state,
                error: action.payload,
                fetching: false,
                subStrands: [],
            };
        case ADD_STRAND_SUCCESS:
            const currentStrands = state.strands || [];
            return {
                ...state,
                strands: [...currentStrands, action.payload],
            };
        case EDIT_STRAND_SUCCESS:
            const removedEditStrand =
                state.strands?.filter(
                    (strand) => strand.id !== action.payload.id
                ) || [];
            return {
                ...state,
                strands: [...removedEditStrand, action.payload],
            };
        default:
            return state;
    }
};

const strandsSelector = (state: RootState) => state.strands;

export const selectStrands = createSelector(
    strandsSelector,
    (strands: StrandsState) => strands.strands
);

const subStrandsMapped = [
    { id: 21 },
    { id: 22 },
    { id: 23 },
    { id: 24 },
    { id: 25 },
    { id: 26 },
    { id: 27 },
    { id: 28 },
    { id: 29 },
    { id: 36 },
    { id: 37 },
    { id: 38 },
    { id: 39 },
    { id: 40 },
    { id: 41 },
];
export const selectStrandsAsOptions = createSelector(
    strandsSelector,
    (strands: StrandsState) => {
        const subStrandsMappedIds = new Set(
            subStrandsMapped.map((subStrand) => subStrand?.id)
        );
        const filteredStrands = strands.strands.filter((strand) =>
            subStrandsMappedIds.has(strand?.id)
        );

        return filteredStrands.reverse().map((strand) => ({
            value: strand.id,
            label: strand.title,
            phase: strand.phase,
        }));
    }
);

export const selectSubStrandsAsOptions = createSelector(
    strandsSelector,
    ({ subStrands }: StrandsState) => {
        if (!subStrands.length) {
            return subStrands;
        }

        return subStrands.map((subStrand) => ({
            value: subStrand.id,
            label: subStrand.title,
        }));
    }
);

// Strands contain both learning areas and strands, this selector is to extract only learning areas
// this is a ** temporary ** solution to a taxonomy and database structure problem that will be solved later
export const selectLearningAreasAsFilters = createSelector(
    (state: RootState) => state,
    (appState: RootState) => {
        const { contentMaps, strands } = appState;
        const { searchParams } = contentMaps;

        if (!strands.strands.length) {
            return [];
        }

        let availableStrands: Strand[] = [];
        if (+searchParams.year_id <= 6) {
            /* these are primary learning areas year group 1 - 6 */
            availableStrands = strands.strands.filter(
                (strand) =>
                    strand.phase === 'primary' &&
                    strand.sub_strands_counter === 0
            );
        } else {
            /* above year 7 and up */
            availableStrands = strands.strands.filter(
                (strand) => !strand.phase
            );
        }

        return availableStrands.map(({ id, title: name, phase }) => ({
            id,
            name,
            phase,
        }));
    }
);

// Strands contain both learning areas and strands, this selector is to extract only strands
// this is a ** temporary ** solution to a taxonomy and database structure problem that will be solved later
export const selectStrandsAsFilters = createSelector(
    (state: RootState) => state,
    (appState: RootState) => {
        const { strands } = appState;

        if (!strands.strands.length) {
            return [];
        }

        const availableStrands: Strand[] = [];
        for (let i = 0, len = strands.strands.length; i < len; ++i) {
            const strand = strands.strands[i];

            if (
                (strand.phase === 'primary' && strand.sub_strands_counter) ||
                strand.phase === 'secondary'
            ) {
                availableStrands.push(strand);
            }
        }

        return availableStrands.map(({ id, title: name, phase }) => ({
            id,
            name,
            phase,
        }));
    }
);

export const selectStrandsFetching = createSelector(
    strandsSelector,
    (strands: StrandsState) => strands.fetching
);

export const selectStrandsError = createSelector(
    strandsSelector,
    (strands: StrandsState) => strands.error
);

export default strands;
