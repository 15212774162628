import { GridSortCellParams } from '@thirdspacelearning/library';

export const yearGroupSortComparator = (
    v1: number,
    v2: number,
    params1: GridSortCellParams,
    params2: GridSortCellParams
) => {
    if (!v1) return 1;
    if (!v2) return -1;

    const row1 = params1.api.getRow(params1.id);
    const row2 = params2.api.getRow(params2.id);

    return row1.year_id - row2.year_id || row1.position - row2.position;
};
