import {
    SlideType,
    LearningObjective,
    ContentMap,
    ContentMapNode,
} from '../types';

/**
 * Return given string with the first character ONLY changed to uppercase
 */
export const capitalise = (s: string | null): string => {
    if (typeof s !== 'string' || !s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * create the learning content type, along with it's label
 */
export const stepsToSuccess = (
    type: SlideType,
    learningObjective: LearningObjective
    // @ts-ignore
): string => `${capitalise(type)} - ${learningObjective[type]}`;

/**
 * return true when the given `contentMap` contains the queried `loId`
 * otherwise returns false
 */
export const includesLO = (contentMap: ContentMap, loId: number): boolean => {
    const nodeIds = contentMap.nodes.reduce(
        (allIds: number[], node: ContentMapNode) => {
            allIds.push(node.learning_objective.id);
            if (node.supports) {
                node.supports.forEach((support) =>
                    allIds.push(support.learning_objective.id)
                );
            }

            return allIds;
        },
        []
    );

    return nodeIds.includes(loId);
};
