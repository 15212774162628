import {
    GET_LEARNING_OBJECTIVES,
    GET_LEARNING_OBJECTIVES_SUCCESS,
    GET_LEARNING_OBJECTIVES_FAILURE,
    GET_SINGLE_LEARNING_OBJECTIVE,
    GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS,
    GET_SINGLE_LEARNING_OBJECTIVE_FAILURE,
    GET_REVISION_LEARNING_OBJECTIVE,
    GET_REVISION_LEARNING_OBJECTIVE_SUCCESS,
    GET_REVISION_LEARNING_OBJECTIVE_FAILURE,
    ADD_LEARNING_OBJECTIVE,
    ADD_LEARNING_OBJECTIVE_SUCCESS,
    ADD_LEARNING_OBJECTIVE_FAILURE,
    EDIT_LEARNING_OBJECTIVE,
    EDIT_LEARNING_OBJECTIVE_SUCCESS,
    EDIT_LEARNING_OBJECTIVE_FAILURE,
    RESET_SUBMIT,
    SWAP_REVISION_ORDER,
    SWAP_REVISION_ORDER_FAILURE,
    SWAP_REVISION_ORDER_SUCCESS,
} from './types';

import {
    getLearningObjectives as getLearningObjectivesAPI,
    getSingleLearningObjective as getSingleLearningObjectiveAPI,
    createLearningObjectives as createLearningObjectivesAPI,
    editLearningObjectives as editLearningObjectivesAPI,
    LearningObjectiveValues,
} from '../../api/learning-objectives';
import { selectLOProcessingPptxId, pptxProcessed } from './learning-content';
import { showSnackbox } from '../feedback';
import {
    LearningObjective,
    ContentDomain,
    determineIfErrors,
    AppThunkResult,
    Errors,
    LearningObjectiveType,
    AppDispatch,
} from '../../types';

export const getLearningObjectives = (params: {
    [key: string]: string[] | string | number[] | number;
}): AppThunkResult<
    Promise<
        | void
        | { learningObjectives: LearningObjective[]; totalCount: number }
        | Errors
    >
> => {
    return (dispatch: AppDispatch) => {
        dispatch(startGetLearningObjectives(params));

        return getLearningObjectivesAPI(params).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(getLearningObjectivesFailure(res.error));
            } else {
                dispatch(
                    getLearningObjectivesSuccess(
                        res.learningObjectives,
                        res.totalCount
                    )
                );
            }
        });
    };
};

export const getSingleLearningObjective = (
    learningObjectiveId: number
): AppThunkResult<Promise<void | LearningObjective | Errors>> => {
    return (dispatch, getState) => {
        dispatch(startGetSingleLearningObjective(learningObjectiveId));

        return getSingleLearningObjectiveAPI(learningObjectiveId).then(
            (res) => {
                if (determineIfErrors(res)) {
                    dispatch(getSingleLearningObjectiveFailure(res.error));
                } else {
                    dispatch(
                        getSingleLearningObjectiveSuccess(
                            res as LearningObjective
                        )
                    );

                    const processingPptxId = selectLOProcessingPptxId(
                        getState()
                    );
                    if (
                        processingPptxId === learningObjectiveId &&
                        !res.ppt_processing
                    ) {
                        dispatch(pptxProcessed());
                    }
                }
            }
        );
    };
};

// TODO rename function for purpose clarity
// example: `learningObjectivesByRevisionOrder`
export const getRevisionLearningObjectives = (
    type: LearningObjectiveType
): AppThunkResult<Promise<void | LearningObjective | Errors>> => {
    return (dispatch) => {
        dispatch(startGetRevisionLearningObjectives());

        return getLearningObjectivesAPI({
            type,
            sort: 'revision_order',
            legacy: type,
        }).then((res) => {
            if (determineIfErrors(res)) {
                dispatch(getRevisionLearningObjectivesFailure(res.error));
            } else {
                dispatch(
                    getRevisionLearningObjectivesSuccess(
                        res.learningObjectives as LearningObjective[]
                    )
                );
            }
        });
    };
};

export const createLearningObjective = (
    values: LearningObjectiveValues,
    type: LearningObjectiveType,
    contentDomain: null | ContentDomain[]
): AppThunkResult<Promise<void | LearningObjective | Errors>> => {
    return async (dispatch): Promise<Errors | undefined | void> => {
        dispatch(addLearningObjectives());
        const res = await createLearningObjectivesAPI(
            values,
            type,
            contentDomain
        );

        if (determineIfErrors(res)) {
            dispatch(addLearningObjectivesFailure(res.error));
            return res;
        } else {
            dispatch(addLearningObjectivesSuccess(res as LearningObjective));
            dispatch(showSnackbox('Learning objective successfully added'));
        }
    };
};

export const editLearningObjective = (
    id: number,
    values: LearningObjectiveValues,
    type: LearningObjectiveType,
    contentDomain?: null | ContentDomain[]
): AppThunkResult<Promise<void | LearningObjective | Errors>> => {
    return async (
        dispatch: AppDispatch
    ): Promise<Errors | undefined | void> => {
        dispatch(editLearningObjectives());
        const res = await editLearningObjectivesAPI(
            id,
            values,
            type,
            contentDomain
        );
        if (determineIfErrors(res)) {
            dispatch(editLearningObjectivesFailure(res.error));
            return res;
        } else {
            dispatch(editLearningObjectivesSuccess(res as LearningObjective));
            dispatch(showSnackbox('Learning objective successfully updated'));
        }
    };
};

export const updateLearningObjectiveRevisionOrder = (
    id: number,
    newPosition: number,
    oldPosition: number,
    type: LearningObjectiveType
): AppThunkResult<Promise<void | LearningObjective | Errors>> => {
    return async (
        dispatch: AppDispatch
    ): Promise<Errors | undefined | void> => {
        dispatch(swapRevisionOrder());

        const res = await editLearningObjectivesAPI(
            id,
            { type, revision_order: newPosition },
            type
        );
        if (determineIfErrors(res)) {
            dispatch(swapRevisionOrderFailure(res.error));
            return res;
        } else {
            dispatch(swapRevisionOrderSuccess(newPosition, oldPosition));
            dispatch(showSnackbox('Learning objective successfully updated'));
        }
    };
};

export const getLearningObjectivesSuccess = (
    learningObjectives: LearningObjective[],
    totalCount: number
) => ({
    type: GET_LEARNING_OBJECTIVES_SUCCESS,
    payload: { learningObjectives, totalCount },
});

export const getLearningObjectivesFailure = (error: string) => ({
    type: GET_LEARNING_OBJECTIVES_FAILURE,
    payload: error,
});

export const startGetLearningObjectives = (params: any) => ({
    type: GET_LEARNING_OBJECTIVES,
    payload: params,
});

export const getSingleLearningObjectiveSuccess = (
    learningObjective: LearningObjective
) => ({
    type: GET_SINGLE_LEARNING_OBJECTIVE_SUCCESS,
    payload: learningObjective,
});

export const getSingleLearningObjectiveFailure = (error: string) => ({
    type: GET_SINGLE_LEARNING_OBJECTIVE_FAILURE,
    payload: error,
});

export const startGetSingleLearningObjective = (id: number) => ({
    type: GET_SINGLE_LEARNING_OBJECTIVE,
    payload: id,
});

export const getRevisionLearningObjectivesSuccess = (
    learningObjectives: LearningObjective[]
) => ({
    type: GET_REVISION_LEARNING_OBJECTIVE_SUCCESS,
    payload: learningObjectives,
});

export const getRevisionLearningObjectivesFailure = (error: string) => ({
    type: GET_REVISION_LEARNING_OBJECTIVE_FAILURE,
    payload: error,
});

export const startGetRevisionLearningObjectives = () => ({
    type: GET_REVISION_LEARNING_OBJECTIVE,
});

export const swapRevisionOrder = () => ({
    type: SWAP_REVISION_ORDER,
});

export const swapRevisionOrderFailure = (error: string) => ({
    type: SWAP_REVISION_ORDER_FAILURE,
    payload: error,
});

export const swapRevisionOrderSuccess = (
    newPosition: number,
    oldPosition: number
) => ({
    type: SWAP_REVISION_ORDER_SUCCESS,
    payload: { newPosition, oldPosition },
});

export const addLearningObjectives = () => ({
    type: ADD_LEARNING_OBJECTIVE,
});
export const addLearningObjectivesSuccess = (
    learningObjective: LearningObjective
) => ({
    type: ADD_LEARNING_OBJECTIVE_SUCCESS,
    payload: learningObjective,
});
export const addLearningObjectivesFailure = (error: string) => ({
    type: ADD_LEARNING_OBJECTIVE_FAILURE,
    payload: error,
});
export const editLearningObjectives = () => ({
    type: EDIT_LEARNING_OBJECTIVE,
});
export const editLearningObjectivesSuccess = (
    learningObjective: LearningObjective
) => ({
    type: EDIT_LEARNING_OBJECTIVE_SUCCESS,
    payload: learningObjective,
});
export const editLearningObjectivesFailure = (error: string) => ({
    type: EDIT_LEARNING_OBJECTIVE_FAILURE,
    payload: error,
});
export const resetSubmit = () => ({
    type: RESET_SUBMIT,
});
