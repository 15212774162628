import { Action } from 'redux';

import {
    GET_CONTENT_MAPS,
    GET_CONTENT_MAPS_SUCCESS,
    GET_CONTENT_MAPS_FAILURE,
    ContentMapsState,
    ADD_CONTENT_MAP,
    ADD_CONTENT_MAP_SUCCESS,
    ADD_CONTENT_MAP_FAILURE,
    ADD_CONTENT_MAP_NODE,
    ADD_CONTENT_MAP_NODE_SUCCESS,
    ADD_CONTENT_MAP_NODE_FAILURE,
    EDIT_CONTENT_MAP_NODE,
    EDIT_CONTENT_MAP_NODE_SUCCESS,
    EDIT_CONTENT_MAP_NODE_FAILURE,
    DELETE_CONTENT_MAP_NODE,
    DELETE_CONTENT_MAP_NODE_SUCCESS,
    DELETE_CONTENT_MAP_NODE_FAILURE,
    SET_SEARCH_PARAMS,
} from './types';

import {
    getContentMaps as getContentMapsAPI,
    createContentMap as createContentMapAPI,
    createNode as createNodeAPI,
    editNode as editNodeAPI,
    deleteNode as deleteNodeAPI,
    createSupportNode as createSupportNodeAPI,
    ContentMapsParams,
    ContentMapValues,
    NodeValues,
} from '../../api/content-maps';
import { showSnackbox } from '../feedback';
import {
    ContentMap,
    ContentMapNode,
    StandardLearningObjective,
    SearchParams,
    AppThunkResult,
} from '../../types';

export const getContentMaps = (
    params: ContentMapsParams
): AppThunkResult<Promise<void | ContentMapsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(startGetContentMaps(params));

        return getContentMapsAPI(params).then((res) => {
            if ('error' in res) {
                dispatch(getContentMapsFailure(res.error));
            } else {
                dispatch(getContentMapsSuccess(res));
            }
        });
    };
};

export const createContentMap = (
    values: ContentMapValues
): AppThunkResult<Promise<void | ContentMapsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(addContentMap());

        return createContentMapAPI(values).then((res) => {
            if ('error' in res) {
                dispatch(addContentMapFailure(res.error));
            } else {
                dispatch(addContentMapSuccess(res as ContentMap));
                dispatch(showSnackbox('Learning map successfully created'));
            }
        });
    };
};

export const createContentMapNode = (
    nodeId: number | null,
    mapId: number,
    values: StandardLearningObjective,
    highestPosition: number
): AppThunkResult<Promise<void | ContentMapsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(addContentMapNode());

        const apiCall = nodeId
            ? createSupportNodeAPI(nodeId, mapId, values)
            : createNodeAPI(mapId, {
                  ...values,
                  position: highestPosition + 1,
              });

        return apiCall.then((res) => {
            if ('error' in res) {
                dispatch(addContentMapNodeFailure(res.error));
            } else {
                dispatch(addContentMapNodeSuccess(res as ContentMap));
                dispatch(showSnackbox('New map node successfully created'));
            }
        });
    };
};

export const editContentMapNode = (
    nodeId: number,
    mapId: number,
    values: NodeValues
): AppThunkResult<Promise<void | ContentMapsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(startEditContentMapNode());

        return editNodeAPI(nodeId, mapId, values).then((res) => {
            if ('error' in res) {
                dispatch(editContentMapNodeFailure(res.error));
                dispatch(showSnackbox('Map node update failed', true));
            } else {
                dispatch(editContentMapNodeSuccess(res as ContentMapNode));
                dispatch(showSnackbox('Map node successfully updated'));
            }
        });
    };
};

export const removeContentMapNode = (
    nodeId: number,
    mapId: number
): AppThunkResult<Promise<void | ContentMapsState | Action<string>>> => {
    return (dispatch) => {
        dispatch(deleteContentMapNode());

        return deleteNodeAPI(nodeId, mapId).then((res) => {
            if (res) {
                dispatch(deleteContentMapNodeFailure(res.error));
                dispatch(showSnackbox('Map node removal failed', true));
            } else {
                dispatch(deleteContentMapNodeSuccess(nodeId));
                dispatch(showSnackbox('Map node successfully removed'));
            }
        });
    };
};

export const getContentMapsSuccess = (contentMaps: ContentMap[]) => ({
    type: GET_CONTENT_MAPS_SUCCESS,
    payload: contentMaps,
});

export const getContentMapsFailure = (error: string) => ({
    type: GET_CONTENT_MAPS_FAILURE,
    payload: error,
});

export const startGetContentMaps = (params: any) => ({
    type: GET_CONTENT_MAPS,
    payload: params,
});

export const setSearchParams = (params: SearchParams) => ({
    type: SET_SEARCH_PARAMS,
    payload: params,
});

export const addContentMap = () => ({
    type: ADD_CONTENT_MAP,
});

export const addContentMapSuccess = (contentMap: ContentMap) => ({
    type: ADD_CONTENT_MAP_SUCCESS,
    payload: contentMap,
});

export const addContentMapFailure = (error: string) => ({
    type: ADD_CONTENT_MAP_FAILURE,
    payload: error,
});

export const addContentMapNode = () => ({
    type: ADD_CONTENT_MAP_NODE,
});

export const addContentMapNodeSuccess = (contentMap: ContentMap) => ({
    type: ADD_CONTENT_MAP_NODE_SUCCESS,
    payload: contentMap,
});

export const addContentMapNodeFailure = (error: string) => ({
    type: ADD_CONTENT_MAP_NODE_FAILURE,
    payload: error,
});

export const startEditContentMapNode = () => ({
    type: EDIT_CONTENT_MAP_NODE,
});

export const editContentMapNodeSuccess = (node: ContentMapNode) => ({
    type: EDIT_CONTENT_MAP_NODE_SUCCESS,
    payload: node,
});

export const editContentMapNodeFailure = (error: string) => ({
    type: EDIT_CONTENT_MAP_NODE_FAILURE,
    payload: error,
});

export const deleteContentMapNode = () => ({
    type: DELETE_CONTENT_MAP_NODE,
});

export const deleteContentMapNodeSuccess = (nodeId: number) => ({
    type: DELETE_CONTENT_MAP_NODE_SUCCESS,
    payload: nodeId,
});

export const deleteContentMapNodeFailure = (error: string) => ({
    type: DELETE_CONTENT_MAP_NODE_FAILURE,
    payload: error,
});
