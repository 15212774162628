/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import { FieldRenderProps } from 'react-final-form';

import 'katex/dist/katex.css';

// Quill dependency
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// custom styling
import './custom.css';

// MathQuill dependency
import './jquery';

// mathquill4quill include
import 'mathquill4quill/mathquill4quill.css';

const mathquill4quill = require('mathquill4quill');
const katex = require('katex');

const enableMathQuillFormulaAuthoring = mathquill4quill({
    Quill,
    katex,
});

const QuillAdapter = ({ input, ...rest }: FieldRenderProps<string>) => {
    const reactQuill = useRef(null);

    const toolbarOptions = [
        ['bold', 'italic', 'underline'], // toggled buttons
        ['link', 'image'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

        //[{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }], // dropdown with defaults from theme

        ['formula'],

        ['clean'], // remove formatting button
    ];

    useEffect(() => {
        if (reactQuill && reactQuill.current) {
            enableMathQuillFormulaAuthoring(
                (reactQuill.current as any).editor,
                toolbarOptions
            );
        }
    }, []);

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <ReactQuill
            ref={reactQuill}
            {...rest}
            {...input}
            modules={{
                toolbar: toolbarOptions,
                formula: true,
                clipboard: {
                    matchVisual: false,
                },
            }}
            theme="snow"
            onChange={(newValue, delta, source) => {
                if (source === 'user') {
                    input.onChange(newValue);
                }
            }}
            className="customQuill"
        />
    );
};

export default QuillAdapter;
