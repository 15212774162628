import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { TopBarLayout, TSLErrorBoundary } from '@thirdspacelearning/library';
import {
    authSelector,
    loginUser,
    logoutUser,
    useAuthFlow,
} from '@tsl-frontend/tsl-auth';
import { localStorage } from '@tsl-frontend/tsl-utilities';

import { NavigationBar } from './navigation';

import { routes } from './Routes';
import { Snackbox } from './feedback';

import { User } from '../types';
import { useTimedEvent } from '../hooks';

import { environment } from '../environments/environment';

function App() {
    const appRoutes = useRoutes(routes);
    const dispatch = useDispatch();
    const { user, unauthorized } = useSelector(authSelector);

    const onLogin = useCallback(
        (user: User) => dispatch(loginUser({ user })),
        [dispatch]
    );
    const onLogout = useCallback(() => dispatch(logoutUser()), [dispatch]);

    const [logout, refreshUserCallback] = useAuthFlow(
        onLogin,
        onLogout,
        true,
        window.location.href,
        {
            platformCookieName: environment.platformCookieName,
            usersApiUrl: environment.usersApiUrl,
        }
    );

    /* Refresh every 30 minutes so users don't get logged out. */
    useTimedEvent({ time: 30 * 60000, callback: refreshUserCallback });

    useEffect(() => {
        if (unauthorized) {
            logout();
            localStorage.clear();
        }
    }, [unauthorized, logout]);

    if (!user) {
        return null;
    }

    return (
        <TopBarLayout header={<NavigationBar user={user} logout={logout} />}>
            <TSLErrorBoundary rollbarAccessToken="32e42a9fe8be422bb6cc04f6ddbf3706">
                {appRoutes}
            </TSLErrorBoundary>
            <Snackbox />
        </TopBarLayout>
    );
}

export default App;
