import axios from 'axios';
import { ContentMap, ContentMapNode, LearningObjective } from '../types';

import { environment } from '../environments/environment';

export interface ContentMapsParams {
    page?: number;
    per_page?: number;
    year_id?: number;
    strand_id?: number;
}

export interface ContentMapsError {
    error: string;
}

export const getContentMaps = async (
    params: ContentMapsParams = {}
): Promise<ContentMap[] | ContentMapsError> => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/content_maps`, {
            params,
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

// TODO resolve compilation errors to allow
// strand_id and year_id to both be `number`
export interface ContentMapValues {
    learningObjective: LearningObjective;
    strand_id: string;
    year_id: string;
}

export const createContentMap = async (
    values: ContentMapValues
): Promise<ContentMap | ContentMapsError> => {
    const data = {
        content_map: {
            key_learning_objective_id: Number(values.learningObjective.id),
            strand_id: values.strand_id,
            year_id: values.year_id,
        },
    };
    return axios
        .post(`${environment.curriculumCmsApiUrl}/content_maps`, data, {
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export interface NodeValues {
    id?: number;
    position?: number | null;
}

export const createNode = async (
    mapId: number,
    values: NodeValues
): Promise<ContentMap | ContentMapsError> => {
    const data = {
        node: {
            learning_objective_id: Number(values.id),
            position: values.position,
        },
    };

    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/content_maps/${mapId}/push`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export const editNode = async (
    nodeId: number,
    mapId: number,
    values: NodeValues
): Promise<ContentMapNode | ContentMapsError> => {
    const data = {
        node: {
            learning_objective_id: values.id && Number(values.id),
            position: values.position,
        },
    };
    return axios
        .put(
            `${environment.curriculumCmsApiUrl}/content_maps/${mapId}/nodes/${nodeId}`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export const deleteNode = async (
    nodeId: number,
    mapId: number
): Promise<ContentMapsError> => {
    return axios
        .delete(
            `${environment.curriculumCmsApiUrl}/content_maps/${mapId}/nodes/${nodeId}`,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};

export const createSupportNode = async (
    nodeId: number,
    mapId: number,
    values: NodeValues
): Promise<ContentMap | ContentMapsError> => {
    const data = {
        node: {
            learning_objective_id: Number(values.id),
        },
    };
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/content_maps/${mapId}/nodes/${nodeId}/push`,
            data,
            {
                withCredentials: true,
            }
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('error = ', error);
            //TODO improve this
            const errorMessage = "We're sorry, something went wrong";
            return { error: errorMessage };
        });
};
