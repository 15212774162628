import { useCallback, useEffect, useState } from 'react';
import { User } from '../types';
import { refreshUser } from './authApi';
import { authLogout } from './authUtils';

const MINUTES_55_IN_MS = 3300000;

export const useAuthFlow = (
    onLogin: (user: User) => void,
    onLogout: () => void,
    redirect: boolean,
    redirectURL: string = window.location.href,
    configuration: {
        platformCookieName: string;
        usersApiUrl: string;
    }
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);

    const { platformCookieName, usersApiUrl } = configuration;

    const logout = useCallback(() => {
        authLogout(redirect, redirectURL, platformCookieName, usersApiUrl);
    }, [platformCookieName, usersApiUrl, redirect, redirectURL]);

    const refreshUserCallback = useCallback(async () => {
        setLoading(true);

        try {
            const res = await refreshUser(usersApiUrl);
            setUser(res.user);
            onLogin(res.user);
        } catch (error) {
            /* all errors during refresh will route back to accounts */
            logout();
            onLogout();
        }
    }, [usersApiUrl, logout, onLogout, onLogin]);

    useEffect(() => {
        refreshUserCallback();

        // the token expires after 1 hour
        const refreshToken = setInterval(() => {
            refreshUser(usersApiUrl);
        }, MINUTES_55_IN_MS);

        return () => {
            clearInterval(refreshToken);
        };
    }, [refreshUserCallback, usersApiUrl]);

    useEffect(() => {
        if (user && loading) {
            setLoading(false);
        }
    }, [user, loading]);

    return [logout, refreshUserCallback];
};
