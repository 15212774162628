import { FC, useEffect, useState, useMemo } from 'react';
import { EmptyObject } from 'redux';
import { Grid } from '@thirdspacelearning/library';
import { useAppDispatch } from '../../../state/store';
import {
    createContentMapNode,
    editContentMapNode,
    removeContentMapNode,
    showSnackbox,
} from '../../../state/actions';
import { ContentMap, ContentMapNode } from '../../../types';
import { ChangePosition } from '../../shared';
import SupportRow from './SupportRow';
import LinkObjectiveFormDialog from './LinkObjectiveFormDialog';
import { ButtonCard } from './MapCards';

interface Props {
    contentMap: ContentMap;
    currentView: 'simple' | 'complex';
}

const sx = {
    nodeWrapper: {
        marginTop: 3,
        marginBottom: 3,
    },
    gridContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'nowrap',
        marginBottom: 2,
    },
    gridItem: {
        flexBasis: '30.5%',
        flexShrink: 1,
        position: 'relative',
        marginLeft: '15px',
    },
    leftArrow: {
        position: 'absolute',
        top: 'calc(50% - 17.5px)',
        right: -17.5,
    },
    downArrow: {
        position: 'absolute',
        right: 'calc(50% - 17.5px)',
        bottom: -17.5,
    },
};

const checkIfNodesSameYearGroup = (nodes: Array<ContentMapNode>) => {
    if (nodes.length === 1) {
        return [];
    }

    const firstNodeYearId = nodes[0].learning_objective.year_id;
    const nodesFromDiffYear = [];
    for (let i = 1; i < nodes.length; ++i) {
        if (nodes[i].learning_objective.year_id !== firstNodeYearId) {
            nodesFromDiffYear.push(nodes[i]);
        }
    }
    return nodesFromDiffYear;
};

const checkIfNodesSameStrand = (nodes: Array<ContentMapNode>) => {
    if (nodes.length === 1) {
        return [];
    }

    const firstNodeStrandId = nodes[0].learning_objective.strand.id;
    const nodesFromDiffYear = [];
    for (let i = 1; i < nodes.length; ++i) {
        if (nodes[i].learning_objective.strand.id !== firstNodeStrandId) {
            nodesFromDiffYear.push(nodes[i]);
        }
    }
    return nodesFromDiffYear;
};

const ContentMapGrid: FC<Props> = ({ contentMap, currentView }) => {
    const dispatch = useAppDispatch();

    const [nodeId, setNodeId] = useState<number | null>(null);
    const [editingNode, setEditingNode] = useState<
        ContentMapNode | { id: null; learning_objective: EmptyObject } | null
    >(null);

    const contentMapId = contentMap.id;

    const [nodesDiffYearGroups, setNodesDiffYearGroups] = useState<
        Array<ContentMapNode>
    >([]);
    const [nodesDiffStrands, setNodesDiffStrands] = useState<
        Array<ContentMapNode>
    >([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addEditNode = (values: any) => {
        if (editingNode && editingNode.id) {
            dispatch(editContentMapNode(editingNode.id, contentMapId, values));
        } else {
            const lastNode = contentMap.nodes[contentMap.nodes.length - 1];
            const highestPosition = lastNode ? lastNode.position : 1;
            dispatch(
                createContentMapNode(
                    nodeId,
                    contentMapId,
                    values,
                    highestPosition
                )
            );
        }

        setEditingNode(null);
        setNodeId(null);
    };

    const removeNode = () => {
        if (editingNode && editingNode.id) {
            dispatch(removeContentMapNode(editingNode.id, contentMapId));
        }

        setEditingNode(null);
        setNodeId(null);
    };

    const changeNodePosition = (node: ContentMapNode, position: number) => {
        dispatch(editContentMapNode(node.id, contentMapId, { position }));
    };

    const isDiffYearGroup = (nodeId: number | null) => {
        if (!nodeId) {
            return false;
        }

        const nodeIndex = nodesDiffYearGroups.findIndex(
            (cmNode) => cmNode.id === nodeId
        );
        if (nodeIndex >= 0) {
            return true;
        }

        return false;
    };

    const isDiffStrand = (nodeId: number | null) => {
        if (!nodeId) {
            return false;
        }

        const nodeIndex = nodesDiffStrands.findIndex(
            (cmNode) => cmNode.id === nodeId
        );
        if (nodeIndex >= 0) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (contentMap.nodes.length) {
            const nodesDiffYearGroups = checkIfNodesSameYearGroup(
                contentMap.nodes
            );
            const nodesDiffStrands = checkIfNodesSameStrand(contentMap.nodes);
            setNodesDiffYearGroups(nodesDiffYearGroups);
            setNodesDiffStrands(nodesDiffStrands);

            if (nodesDiffYearGroups.length || nodesDiffStrands.length) {
                dispatch(
                    showSnackbox(
                        'One or more core learning objectives are in different strands or year groups',
                        true
                    )
                );
            }
        }
    }, [contentMap, dispatch]);

    const nodes = useMemo(
        () => [...contentMap.nodes, { id: null, learning_objective: {} }],
        [contentMap]
    );

    return (
        <>
            {nodes.map((node) => (
                <Grid
                    key={node.id || 'nodeNew'}
                    container
                    sx={sx.gridContainer}
                >
                    {node.id ? (
                        <SupportRow
                            node={node}
                            setEditingNode={setEditingNode}
                            setNodeId={setNodeId}
                            currentView={currentView}
                            isInDfferentStrand={isDiffStrand(node.id)}
                            isInDifferentYearGroup={isDiffYearGroup(node.id)}
                        />
                    ) : (
                        <>
                            <Grid item xs={4} sx={sx.gridItem}>
                                <ButtonCard
                                    label="Link objective"
                                    onClick={() => setEditingNode(node)}
                                    variant="contained"
                                    type="node"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {/* Empty Spacer */}
                            </Grid>
                        </>
                    )}

                    {'position' in node && (
                        <ChangePosition
                            currentPosition={node.position}
                            onChangePosition={(position) =>
                                changeNodePosition(node, position)
                            }
                        />
                    )}
                </Grid>
            ))}

            {editingNode && (
                <LinkObjectiveFormDialog
                    linkLearningObjective={addEditNode}
                    onClose={() => {
                        setEditingNode(null);
                        setNodeId(null);
                    }}
                    removeNode={removeNode}
                    open={editingNode !== null}
                    initialValues={{
                        ...editingNode.learning_objective,
                    }}
                    contentMap={contentMap}
                />
            )}
        </>
    );
};

export default ContentMapGrid;
