import { FC, PropsWithChildren } from 'react';

import { Box, GridFilterPanel } from '@thirdspacelearning/library';

const sx = {
    deleteIcon: {
        order: 999, // suggested way to move icon by MUI
        '& .MuiIconButton-root': { marginLeft: 1 },
    },
};

const filterFormProps = {
    deleteIconProps: { sx: sx.deleteIcon },
};

const DataGridFilterPanel: FC<PropsWithChildren> = ({ children, ...props }) => {
    return (
        <Box p={2}>
            <GridFilterPanel filterFormProps={filterFormProps} {...props} />
            {children}
        </Box>
    );
};

export default DataGridFilterPanel;
