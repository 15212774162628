import i18next, { t } from 'i18next';

/**
 * Can be used to translate language key within constants.
 *
 * @param { string } key key to translate
 * @param { number } count pluralise key `@default 1`
 *
 */
export const transLangKey = (key: string, count: number = 1) =>
    t(key, { count });

/**
 * Can be used to translate and capitalize language key within constants.
 *
 * @param { string } key key to translate
 * @param { number } count pluralise key `@default 1`
 *
 */
export const capLangKey = (key: string, count: number = 1) =>
    i18next.format(transLangKey(key, count), 'capitalize');
