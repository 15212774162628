import { FC } from 'react';
import { Grid, Typography } from '@thirdspacelearning/library';

const sx = {
    grid: {
        justifyContent: 'center',
    },
};

interface Props {}

const YearsPage: FC<Props> = () => {
    return (
        <Grid sx={sx.grid} container spacing={3}>
            <Grid item xs={5}>
                <Typography variant="h2">Years</Typography>
                <Typography>Placeholder for years page</Typography>
            </Grid>
        </Grid>
    );
};

export default YearsPage;
