import { FC, ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LDProvider } from 'launchdarkly-react-client-sdk';
import { LDContext } from 'launchdarkly-js-client-sdk';

import { environment } from '../../environments/environment';

import { authSelector } from '@tsl-frontend/tsl-auth';

interface LaunchDarklyProviderProps {
    children?: ReactNode;
}

const clientSideID = environment.launchDarklyKey;
export const LaunchDarklyProvider: FC<LaunchDarklyProviderProps> = ({
    children,
}) => {
    const { user } = useSelector(authSelector);

    const [context, setContext] = useState<LDContext>();

    useEffect(() => {
        if (!user) {
            return;
        }

        setContext({
            kind: 'multi',
            organisation: {
                key: user.memberships[0]?.o_urn,
            },
            user: {
                key: user.id,
            },
        });
    }, [user]);

    return (
        <LDProvider
            clientSideID={clientSideID}
            deferInitialization={true}
            context={context}
        >
            {children}
        </LDProvider>
    );
};

export default LaunchDarklyProvider;
