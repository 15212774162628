import React, { FC, ChangeEvent, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
    Theme,
    Box,
    IconButton,
    TSLLink,
    TableHead,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Typography,
    Grid,
    Icons,
} from '@thirdspacelearning/library';

import {
    LearningObjective,
    LearningObjectiveType,
    SearchParams,
    ContentDomain,
} from '../../../../types';
import { capitalise } from '../../../../utils';
import { environment } from '../../../../environments/environment';

import { ContentDomainInfo, StrandInfo } from '../../../shared';

import SortColumnHeader from './SortColumnHeader';

const sx = {
    wrapper: {
        marginBottom: 3,
    },
    table: (theme: Theme) => ({
        borderCollapse: 'separate',
        borderSpacing: theme.spacing(0, 3),
    }),
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'background.default',
        },
        '& > * + *': {
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '20%',
                left: 0,
                borderLeft: 1,
                borderColor: 'divider',
                height: '60%',
            },
        },
    },
    tableCell: {
        '& > *': {
            marginRight: 1,
        },
    },
    check: {
        margin: 'auto',
        color: 'success.main',
        display: 'flex',
    },
    strandGrid: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

interface Props {
    learningObjectives: LearningObjective[];
    onEditObjective?: (lo: LearningObjective) => void;
    updateSearchParams: (params: SearchParams) => void;
    searchParams: SearchParams;
    updateTableParams: (params: { [key: string]: number }) => void;
    tableParams: { per_page: number; page: number; totalCount: number };
    toggleField?: (lo: LearningObjective) => ReactNode;
}

const LearningObjectivesTable: FC<Props> = ({
    learningObjectives,
    onEditObjective,
    updateSearchParams,
    searchParams,
    updateTableParams,
    tableParams,
    toggleField,
}) => {
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        updateTableParams({ page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        updateTableParams({ per_page: Number(event.currentTarget.value) });
    };

    const handleSort = (column: string) => {
        const sort = searchParams.sort === column ? `-${column}` : column;
        updateSearchParams({ sort });
    };

    if (!learningObjectives.length) {
        return (
            <Box py={3}>
                <Typography>
                    We're sorry, we couldn't find any learning objectives with
                    those filters
                </Typography>
            </Box>
        );
    }

    const CDRefContent = (lo: LearningObjective) => {
        const contentDomains =
            lo.type === LearningObjectiveType.STANDARD
                ? [lo.content_domain]
                : lo.type === LearningObjectiveType.SATS
                ? lo.content_domains
                : [];

        const references = contentDomains
            .map((cd: ContentDomain | undefined) => (cd ? cd.reference : ''))
            .join(', ');
        return (
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    {references}
                </Grid>
                {contentDomains[0] && (
                    <ContentDomainInfo learningObjective={lo} />
                )}
            </Grid>
        );
    };

    const StrandContent = (lo: LearningObjective) => {
        const references = lo.strand.title;
        return (
            <Grid container sx={sx.strandGrid}>
                <Grid item xs={8}>
                    {references}
                </Grid>
                {<StrandInfo learningObjective={lo} />}
            </Grid>
        );
    };

    const LearningContent = (lo: LearningObjective) => (
        <Grid container alignItems="center">
            <Grid item xs={7}>
                {`(${lo.slides_counter}) Slides`}
            </Grid>
            <TSLLink
                component={RouterLink}
                to={`/learning-content/${lo.id}`}
                color="primary"
            >
                {lo.slides_counter ? 'Edit' : 'Add'}
            </TSLLink>
        </Grid>
    );

    const currentSort = searchParams.sort as string;

    return (
        <TableContainer sx={sx.wrapper}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={sx.tableRow}>
                        {toggleField && <TableCell></TableCell>}
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="ID"
                                name="id"
                                onSort={handleSort}
                            />
                        </TableCell>
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="TSL Learning Objective"
                                name="title"
                                onSort={handleSort}
                            />
                        </TableCell>
                        {searchParams.type !==
                            LearningObjectiveType.STANDARD && (
                            <TableCell>
                                <SortColumnHeader
                                    currentSort={currentSort}
                                    label="Impact"
                                    name="impact"
                                    onSort={handleSort}
                                />
                            </TableCell>
                        )}
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="Active"
                                name="active"
                                onSort={handleSort}
                            />
                        </TableCell>
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="Year Group"
                                name="year_id"
                                onSort={handleSort}
                            />
                        </TableCell>
                        <TableCell>
                            {searchParams.type ===
                            LearningObjectiveType.GCSE ? (
                                <SortColumnHeader
                                    currentSort={currentSort}
                                    label="NC Strand"
                                    name="strand_ref"
                                    onSort={handleSort}
                                />
                            ) : (
                                <SortColumnHeader
                                    currentSort={currentSort}
                                    label="CD Ref"
                                    name="content_domain_ref"
                                    onSort={handleSort}
                                />
                            )}
                        </TableCell>
                        <TableCell>Learning Content</TableCell>
                        <TableCell>Diagnostic Questions</TableCell>
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="Created date"
                                name="created_at"
                                onSort={handleSort}
                            />
                        </TableCell>
                        <TableCell>
                            <SortColumnHeader
                                currentSort={currentSort}
                                label="Last modified date"
                                name="updated_at"
                                onSort={handleSort}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {learningObjectives.map((lo) => (
                        <TableRow sx={sx.tableRow} key={lo.id}>
                            {toggleField && (
                                <TableCell>{toggleField(lo)}</TableCell>
                            )}
                            <TableCell sx={sx.tableCell}>{lo.id}</TableCell>
                            <TableCell sx={sx.tableCell}>
                                {onEditObjective && (
                                    <IconButton
                                        aria-label="edit"
                                        size="small"
                                        onClick={() => onEditObjective(lo)}
                                    >
                                        <Icons.Edit color="primary" />
                                    </IconButton>
                                )}
                                {lo.title}
                            </TableCell>
                            {searchParams.type !==
                                LearningObjectiveType.STANDARD && (
                                <TableCell sx={sx.tableCell}>
                                    {'impact' in lo && capitalise(lo.impact)}
                                </TableCell>
                            )}
                            <TableCell sx={sx.tableCell}>
                                {lo.active ? (
                                    <Icons.Check sx={sx.check} />
                                ) : null}
                            </TableCell>
                            <TableCell sx={sx.tableCell}>
                                {lo.year_id}
                            </TableCell>
                            {searchParams.type ===
                            LearningObjectiveType.GCSE ? (
                                <TableCell sx={sx.tableCell}>
                                    {StrandContent(lo)}
                                </TableCell>
                            ) : (
                                <TableCell sx={sx.tableCell}>
                                    {CDRefContent(lo)}
                                </TableCell>
                            )}
                            <TableCell sx={sx.tableCell}>
                                {LearningContent(lo)}
                            </TableCell>
                            <TableCell sx={sx.tableCell}>
                                <TSLLink
                                    color="primary"
                                    href={`${environment.mainAppBaseUrl}/questions?lesson_id=${lo.id}&from=cms`}
                                    target="_blank"
                                >
                                    Questions
                                </TSLLink>
                            </TableCell>
                            <TableCell sx={sx.tableCell}>
                                <Typography variant="caption">
                                    {lo.created_at &&
                                        format(
                                            new Date(lo.created_at),
                                            'dd/MM/yy kk:mm'
                                        )}
                                </Typography>
                            </TableCell>
                            <TableCell sx={sx.tableCell}>
                                <Typography variant="caption">
                                    {lo.updated_at &&
                                        format(
                                            new Date(lo.updated_at),
                                            'dd/MM/yy kk:mm'
                                        )}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            count={tableParams.totalCount}
                            rowsPerPage={tableParams.per_page}
                            page={tableParams.page - 1}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default LearningObjectivesTable;
