import { FC, useCallback, useEffect, useState } from 'react';

import { Dialog, Card } from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../state/store';
import {
    getSlides,
    selectSlidesByLoId,
} from '../../state/learning-objectives/learning-content';

import { localStorage } from '../../utils';
import { LearningObjective, RootState } from '../../types';

import { SlidesPreview, SlideControl } from './components';

const sx = {
    dialog: {
        '& .MuiDialog-container > .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    previewCard: (fullScreen: boolean) => ({
        display: 'flex',
        borderBottomLeftRadius: 0,
        flexGrow: 1,
        height: fullScreen ? '' : '650px',
    }),
};

export enum ViewType {
    VIEW = 'view',
    EDIT = 'edit',
    ADD = 'add',
}

interface Props {
    onClose: () => void;
    open: boolean;
    startingSlide: number;
    initialViewType: ViewType;
    editable?: boolean;
    learningObjective: LearningObjective;
}

const SlidesPreviewDialog: FC<Props> = ({
    onClose,
    open,
    startingSlide,
    initialViewType,
    editable,
    learningObjective,
}) => {
    const dispatch = useAppDispatch();
    const [currentSlideIndex, setCurrentSlideIndex] =
        useState<number>(startingSlide);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [viewType, setViewType] = useState<ViewType>(initialViewType);
    const [slideEdited, setSlideEdited] = useState<boolean>(false);

    const slides = useAppSelector((state: RootState) =>
        selectSlidesByLoId(state, learningObjective.id)
    );

    const checkIfCanExecute = (): boolean => {
        if (slideEdited) {
            const confirmed = window.confirm(
                'You have unsaved changes - are you sure you wish to proceed?'
            );

            return confirmed;
        }

        return true;
    };
    const checkIfCanExecuteCallback = useCallback(checkIfCanExecute, [
        slideEdited,
    ]);

    const changeSlide = (slideIndex: number) => {
        if (checkIfCanExecuteCallback()) {
            setSlideEdited(false);
            setViewType(ViewType.VIEW);
            setCurrentSlideIndex(slideIndex);
            localStorage.remove(`slide-${slides[slideIndex].id}`);
        }
    };

    const closeSlidePreview = () => {
        if (checkIfCanExecuteCallback()) {
            onClose();
            localStorage.remove(`slide-${slides[currentSlideIndex].id}`);
        }
    };

    const handleClose = (
        _e: Event,
        reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    useEffect(() => {
        if (!slides) {
            dispatch(getSlides(learningObjective.id, { sort: 'position' }));
        }
    }, [learningObjective, dispatch, slides]);

    const onEnableEdit = () => {
        setViewType(ViewType.EDIT);
    };

    return (
        <Dialog
            sx={sx.dialog}
            open={open}
            onClose={handleClose}
            aria-labelledby="slidesPreview"
            maxWidth="lg"
            fullScreen={fullScreen}
            fullWidth
        >
            <Card sx={sx.previewCard(fullScreen)}>
                <SlidesPreview
                    onClose={onClose}
                    fullScreen={fullScreen}
                    slides={slides}
                    learningObjectiveId={learningObjective.id}
                    viewType={viewType}
                    enableEdit={onEnableEdit}
                    editable={editable}
                    learningObjective={learningObjective}
                    setSlideEdited={setSlideEdited}
                    currentSlideIndex={currentSlideIndex}
                    setCurrentSlideIndex={setCurrentSlideIndex}
                />
            </Card>

            {initialViewType !== ViewType.ADD && (
                <SlideControl
                    currentIndex={currentSlideIndex}
                    onClose={closeSlidePreview}
                    onFullScreen={setFullScreen}
                    onChangeIndex={changeSlide}
                    totalViews={slides ? slides.length : 0}
                    fullScreen={fullScreen}
                />
            )}
        </Dialog>
    );
};

export default SlidesPreviewDialog;
