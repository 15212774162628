import { FC } from 'react';
import { Button, Card, CardContent } from '@thirdspacelearning/library';

const sx = {
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

interface ButtonCardProps {
    label: string;
    onClick: () => void;
    variant?: 'text' | 'outlined' | 'contained';
    type?: string;
}

const ButtonCard: FC<ButtonCardProps> = ({ label, onClick, variant, type }) => (
    <Card data-testid={`buttonCard-${type}`}>
        <CardContent sx={sx.content}>
            <Button color="primary" onClick={onClick} variant={variant}>
                {label}
            </Button>
        </CardContent>
    </Card>
);

export default ButtonCard;
