import { FC, useEffect, ReactNode } from 'react';

import { Loading, Typography } from '@thirdspacelearning/library';

import {
    selectSearchedLearningObjectives,
    selectLearningObjectivesError,
    getLearningObjectives,
    selectSearchParams,
    selectTableParams,
    selectSearching,
} from '../../state/learning-objectives';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { CurriculumFilters } from '../shared';
import {
    LearningObjective,
    LearningObjectiveType,
    FilterOption,
    SearchParams,
} from '../../types';

import { LearningObjectivesTable } from './components';

const sx = {
    wrapper: {
        marginBottom: 3,
    },
};

interface Props {
    onEditObjective?: (learningObjective: LearningObjective) => void;
    toggleField?: (lo: LearningObjective) => ReactNode;
}

const LearningObjectivesContent: FC<Props> = ({
    onEditObjective,
    toggleField,
}) => {
    const dispatch = useAppDispatch();

    const learningObjectives = useAppSelector(selectSearchedLearningObjectives);
    const error = useAppSelector(selectLearningObjectivesError);
    const searching = useAppSelector(selectSearching);
    const searchParams = useAppSelector(selectSearchParams);
    const tableParams = useAppSelector(selectTableParams);

    useEffect(() => {
        if (!error && !learningObjectives && !searching) {
            dispatch(
                getLearningObjectives({
                    ...searchParams,
                    per_page: tableParams.per_page,
                    page: tableParams.page,
                })
            );
        }
    }, [
        dispatch,
        learningObjectives,
        error,
        searchParams,
        tableParams,
        searching,
    ]);

    const updateSearchParams = (
        params: SearchParams,
        clearFilters?: boolean
    ) => {
        const defaultFilters = {
            page: 1,
            per_page: tableParams.per_page,
            type: params.type || searchParams.type,
            title: '',
        };
        const typeChanged = params.type && params.type !== searchParams.type;
        const titleChanged =
            params.title && params.title !== searchParams.title;

        let newSearchParams;
        if (clearFilters) {
            newSearchParams = defaultFilters;
        } else if (typeChanged || titleChanged) {
            newSearchParams = {
                ...params,
                page: 1,
                per_page: tableParams.per_page,
            };
        } else {
            newSearchParams = { ...searchParams, ...params };
        }

        dispatch(getLearningObjectives(newSearchParams));
    };

    const updateTableParams = (params: { [key: string]: number }) => {
        dispatch(
            getLearningObjectives({
                ...searchParams,
                ...params,
            })
        );
    };

    const filterMap: Record<LearningObjectiveType, FilterOption[]> = {
        [LearningObjectiveType.STANDARD]: [
            { filterId: 'type' },
            { filterId: 'year_id', multiple: true },
            { filterId: 'strand_id', multiple: true },
            { filterId: 'sub_strand_id', multiple: true },
        ],
        [LearningObjectiveType.SATS]: [
            { filterId: 'type' },
            { filterId: 'year_id', multiple: true },
            { filterId: 'impact', multiple: true },
        ],
        [LearningObjectiveType.GCSE]: [
            { filterId: 'type' },
            { filterId: 'year_id', multiple: true },
            { filterId: 'strand_id', multiple: true },
            { filterId: 'impact', multiple: true },
        ],
    };

    return (
        <>
            <CurriculumFilters
                updateSearchParams={updateSearchParams}
                searchParams={searchParams}
                filterOptions={
                    filterMap[searchParams.type as LearningObjectiveType]
                }
            />

            {!learningObjectives ? (
                error ? (
                    <Typography sx={sx.wrapper} color="error">
                        {error}
                    </Typography>
                ) : (
                    <Loading />
                )
            ) : (
                <LearningObjectivesTable
                    learningObjectives={learningObjectives}
                    onEditObjective={onEditObjective}
                    updateSearchParams={updateSearchParams}
                    searchParams={searchParams}
                    updateTableParams={updateTableParams}
                    tableParams={tableParams}
                    toggleField={toggleField}
                />
            )}
        </>
    );
};

export default LearningObjectivesContent;
