import qs from 'qs';
import { generatePath } from 'react-router-dom';

/**
 * React router doesn't have query params internally so we use the help of qs to create and handle these for us. Example usage:
 *
 *   history.replace(
 *     generateLocation(
 *       RoutePaths.CLASSROOM,
 *       undefined,
 *       generateQueryParams(
 *         { objective_id: learningObjective.id },
 *         location.search
 *       )
 *     )
 *   );
 *
 * and to retriieve params:
 *
 *   const params = qs.parse(location.search);
 *   const objectiveId = params.objective_id;
 *
 */

export const generateQueryParams = (
    paramsToAdd: { [paramName: string]: string | number | boolean | undefined },
    currentSearch?: string
) => {
    const currentSeachParams = currentSearch
        ? (qs.parse(currentSearch, { ignoreQueryPrefix: true }) as {
              [paramName: string]: string | number | boolean | undefined;
          })
        : {};

    return {
        ...currentSeachParams,
        ...paramsToAdd,
    };
};

export const generateLocation = (
    pattern: string,
    params?: { [paramName: string]: string | number | boolean | undefined },
    queryParams?: { [paramName: string]: string | number | boolean | undefined }
) => {
    return {
        pathname: generatePath(pattern, params),
        search: queryParams ? qs.stringify(queryParams) : '',
    };
};
