import { useRef, useState } from 'react';

import { DeleteOutline, MoreVert } from '@mui/icons-material';

import { DataGridProps } from '@thirdspacelearning/library';

import DataGridToolbar from './DataGridToolbar';
import DataGridFilterPanel from './DataGridFilterPanel';

const useDataGridToolbar = () => {
    const filterPanelEl = useRef(null);
    const [filterButtonEl, setFilterButtonEl] =
        useState<HTMLButtonElement | null>(null);

    const slots: Partial<DataGridProps['slots']> = {
        toolbar: DataGridToolbar,
        filterPanel: DataGridFilterPanel,
        filterPanelDeleteIcon: () => <DeleteOutline />,
        moreActionsIcon: () => <MoreVert />,
    };

    const slotProps: Partial<DataGridProps['slotProps']> = {
        toolbar: {
            filterPanelEl,
            setFilterButtonEl,
        },
        panel: {
            anchorEl: filterButtonEl,
            placement: 'bottom',
        },
        baseSelect: { native: false },
    };

    return { filterPanelEl, slots, slotProps };
};

export default useDataGridToolbar;
