import { QueryClient } from 'react-query';
export type { QueryStatus } from 'react-query';

const queryClient = new QueryClient();

export { queryClient };

export enum QUERY_STATUS {
    error = 'error',
    idle = 'idle',
    loading = 'loading',
    success = 'success',
}
